import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'
import { kebabcase, map } from 'lodash'
import { getResponseAsFile } from '@/helpers/download'

export const INTERNAL_GENERIC_CONTEXT_ID = 'is_generic'

const splashScreensSizes = ['640x1136', '750x1334', '1242x2208', '1125x2436', '828x1792', '1242x2688', '1536x2048', '1668x2224', '1668x2388', '2048x2732']
const touchIcons = ['192x192', '512x512']
function arrayToObject (sizes, prefix) {
  const obj = {}
  sizes.forEach(value => {
    obj[prefix + value] = null
  })
  return obj
}
const splashScreensSizesAsObject = arrayToObject(splashScreensSizes, 'splashScreenId')
const touchIconsAsObject = arrayToObject(touchIcons, 'touchIconId')
export const pattern = {
  alias: '',
  name: '',
  description: '',
  hosts: {
    api: '',
    backoffice: '',
    certification: '',
    frontoffice: '',
    lrs: '',
    lrsBasicAuth: '',
  },
  manifest: {
    backoffice: {},
    frontoffice: {},
  },
  settings: {
    mainWebsite: '',
    allowAccessToRegisterFeature: false,
    allowAccessToBuyFeature: false,
    allowAccessToInvitationCodeFeature: false,
    allowUserToSetFirstname: true,
    allowUserToSetLastname: true,
    allowUserToSetMail: true,
    allowUserToSetBirthday: true,
    allowUserToChangeUsername: true,
    useInternalAuthentification: true,
    simplePasswordForInvitationCode: false,
    anonymousPdfForInvitationCode: false,
    allowAccessToLoginAsFeature: false,
    displayRequiredDatasOnStartup: true,
    colors: {
      secondaryColor: '',
      primaryColor: '',
    },
    rgpd: {
      anonymize: false,
      period: 'annual',
      date: null,
      sendMail: false,
      delay: null,
    },
    allowAccessToCertificationFeature: false,
    allowEditGroupFeature: true,
    certificationType: 'certification',
    legal: {
      cgu: {
        requiredByFront: true,
        version: '',
        date: '',
        content: '',
      },
      rgpd: {
        requiredByFront: false,
        version: '',
        date: '',
        content: '',
      },
      emargement_certification: {
        version: '',
        date: '',
        content: '',
      },
    },
  },
  competencies: {
    genericCompetencies: {
      shared: true,
      reportingLevel: null,
    },
  },
  assets: {
    logoId: null, // 512px x 512px (juste en info)
    smallLogoId: null, // 100px x 100px  (juste en info)
    authScreenBackgroundId: null,
    boFaviconId: null,
    foFaviconId: null,
    foLogoId: null,
    foLogoBadgeId: null,
    mailFooterId: null,
    mailBannerLogoId: null,
    ...splashScreensSizesAsObject,
    ...touchIconsAsObject,
  },
  metas: {
    translations: [],
    boPrimaryColor: '', // > aperçu d'un text blanc sur le bg primary Color
    boPrimaryTextColor: '', // > aperçu d'un text sur bg blanc
    boRelancePrimaryColor: '#5949FA',
    boRelanceSecondaryColor: '#6CCAC6',
    boRelanceQuaternaryColor: '#fab62a',
    boRelanceUseIllustration: true,
    boSecondaryColor: '', // > aperçu d'un text blanc sur le bg secondaryColor
    boSecondaryTextColor: '', // > aperçu d'un text sur bg blanc
    boShowContributionBox: true,
    boContributionBoxHtml: '',
    boContributionBoxSubjects: [
      'obtenir une information',
      'proposer une amélioration d\'ergonomie',
      'proposer une nouvelle fonctionnalité',
      'suggérer un nouvel exercice',
      'suggérer un nouveau module',
      'proposer mes services',
      'autre',
    ],
    boHelp: [],
    boMessages: [],
    // # loginBackgroundId: null
    creditLabel: '',
    licenseLabel: '',
    scoreLabel: '',
    levelLabel: '',
    gaugeLabel: '',
    // couleurs fo
    foPrimaryColor: '',
    foPrimaryVariantColor: '',
    foPrimaryTextColor: '',
    foSecondaryColor: '',
    foSecondaryVariantColor: '',
    foSecondaryTextColor: '',
    foTertiaryColor: '',
    foQuaternaryColor: '',
    foIconColor: '',
    foStyleUseBorder: false,
    foShowLateralBar: false,
    externalFacebookLink: '',
    externalDemonstrationVideoLink: '',
    externalReferenceWebSiteLink: '',
    externalSignUpWebSiteLink: '',
    keys: {
      froala: '',
      froala_v4: '',
    },
  },
}

const ContextSchema = new Schema({
  title: 'Context',
  description: 'Schema for Contexts.',
  type: 'object',
  properties: {
    alias: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    manifest: {
      type: 'object',
      properties: {
        frontoffice: {
          type: 'object',
          additionalProperties: true,
        },
        backoffice: {
          type: 'object',
          additionalProperties: true,
        },
      },
    },
    hosts: {
      type: 'object',
      additionalProperties: true,
      properties: {
        api: {
          type: 'string',
        },
        backoffice: {
          type: 'string',
        },
        certification: {
          type: 'string',
        },
        frontoffice: {
          type: 'string',
        },
      },
    },
    assets: {
      type: 'object',
      properties: {
        logoId: {
          type: ['null', 'string'],
        },
        smallLogoId: {
          type: ['null', 'string'],
        },
        authScreenBackgroundId: {
          type: ['null', 'string'],
        },
        boFaviconId: {
          type: ['null', 'string'],
        },
        foFaviconId: {
          type: ['null', 'string'],
        },
        foLogoId: {
          type: ['null', 'string'],
        },
        // foTouchIconId: {
        //   type: ['null', 'string']
        // },
        foLogoBadgeId: {
          type: ['null', 'string'],
        },
        mailFooterId: {
          type: ['null', 'string'],
        },
        mailBannerLogoId: {
          type: ['null', 'string'],
        },
        ...map(splashScreensSizesAsObject, () => {
          return {
            type: ['null', 'string'],
          }
        }),
        ...map(touchIconsAsObject, () => {
          return {
            type: ['null', 'string'],
          }
        }),
      },
      additionalProperties: true,
    },
    metas: {
      type: 'object',
      properties: {
        translations: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              path: {
                type: 'string',
              },
              translation: {
                type: 'string',
              },
            },
          },
        },
        boContributionBoxSubjects: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        boMessages: {
          type: 'array',
          items: {
            type: 'object',
            additionalProperties: true,
            properties: {
              title: {
                type: ['null', 'string'],
              },
              subtitle: {
                type: ['null', 'string'],
              },
              icon: {
                type: ['null', 'string'],
              },
              html: {
                type: ['null', 'string'],
              },
              type: {
                type: ['null', 'string'],
              },
            },
          },
        },
        boPrimaryColor: {
          type: 'string',
        },
        boPrimaryTextColor: {
          type: 'string',
        },

        boRelancePrimaryColor: {
          type: 'string',
        },
        boRelanceSecondaryColor: {
          type: 'string',
        },
        boRelanceQuaternaryColor: {
          type: 'string',
        },
        boRelanceUseIllustration: {
          type: 'boolean',
        },

        boSecondaryColor: {
          type: 'string',
        },
        boSecondaryTextColor: {
          type: 'string',
        },
        logoId: {
          type: ['null', 'string'],
        },
        smallLogoId: {
          type: ['null', 'string'],
        },
        creditLabel: {
          type: 'string',
        },
        licenseLabel: {
          type: 'string',
        },
        scoreLabel: {
          type: 'string',
        },
        levelLabel: {
          type: 'string',
        },
        gaugeLabel: {
          type: 'string',
        },
        foPrimaryColor: {
          type: 'string',
        },
        foPrimaryVariantColor: {
          type: 'string',
        },
        foPrimaryTextColor: {
          type: 'string',
        },
        foSecondaryColor: {
          type: 'string',
        },
        foSecondaryVariantColor: {
          type: 'string',
        },
        foSecondaryTextColor: {
          type: 'string',
        },
        foTertiaryColor: {
          type: 'string',
        },
        foQuaternaryColor: {
          type: 'string',
        },
        foIconColor: {
          type: 'string',
        },
        foStyleUseBorder: {
          type: 'boolean',
        },
        foShowLateralBar: {
          type: 'boolean',
        },
        externalFacebookLink: {
          type: 'string',
        },
        externalDemonstrationVideoLink: {
          type: 'string',
        },
        externalReferenceWebSiteLink: {
          type: 'string',
        },
        externalSignUpWebSiteLink: {
          type: 'string',
        },
        boHelp: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: {
                type: 'string',
              },
              children: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    name: {
                      type: 'string',
                    },
                    description: {
                      type: 'string',
                    },
                    url: {
                      type: 'string',
                    },
                    html: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            additionalProperties: true,
          },
        },
        keys: {
          type: 'object',
          properties: {
            froala: {
              type: 'string',
            },
            froala_v4: {
              type: 'string',
            },
          },
          additionalProperties: true,
        },
      },
      additionalProperties: true,
    },
    settings: {
      type: 'object',
      properties: {
        mainWebsite: {
          type: 'string',
        },
        colors: {
          type: 'object',
          properties: {
            secondaryColor: {
              type: 'string',
            },
            primaryColor: {
              type: 'string',
            },
          },
          additionalProperties: true,
        },
        legal: {
          type: 'object',
          properties: {
            cgu: {
              type: 'object',
              additionalProperties: true,
              properties: {
                version: {
                  type: 'string',
                },
                date: {
                  type: 'string',
                },
                content: {
                  type: 'string',
                },
              },
            },
          },
          additionalProperties: true,
        },
        mail: {
          type: 'string',
        },
        allowAccessToRegisterFeature: {
          type: 'boolean',
        },
        allowAccessToInvitationCodeFeature: {
          type: 'boolean',
        },
        allowAccessToLoginAsFeature: {
          type: 'boolean',
        },
        allowAccessToBuyFeature: {
          type: 'boolean',
        },
        allowAccessToCertificationFeature: {
          type: 'boolean',
        },
        allowEditGroupFeature: {
          type: 'boolean',
        },
        displayRequiredDatasOnStartup: {
          type: ['boolean', 'null'],
        },
        certificationType: {
          type: 'string',
          enum: ['certification', 'attestation'],
          additionalItems: true,
        },
        rgpd: {
          type: 'object',
          additionalProperties: true,
          properties: {
            anonymize: {
              type: 'boolean',
            },
            period: {
              type: 'string',
              enum: ['annual', 'delay'],
            },
            date: {
              type: ['null', 'string'],
            },
            sendMail: {
              type: 'boolean',
            },
            delay: {
              type: ['null', 'string'],
            },
          },
        },
      },
      additionalProperties: true,
    },
    competencies: {
      type: 'object',
      genericCompetencies: {
        type: 'object',
        shared: {
          type: 'boolean',
        },
        reportingLevel: {
          type: 'number',
        },
        additionalProperties: true,
      },
      additionalProperties: true,
    },
  },
  userCountByRole: {
    type: 'object',
    additionalProperties: true,
  },
  additionalProperties: true,
  required: ['alias', 'name'],
})

export class ContextFactories {
  static get boMessagesItem () {
    return {
      title: null,
      subtitle: null,
      html: '',
      icon: null,
      type: null,
    }
  }

  static get boContributionBoxSubjectsDefault () {
    return [
      'obtenir une information',
      'proposer une amélioration d\'ergonomie',
      'proposer une nouvelle fonctionnalité',
      'suggérer un nouvel exercice',
      'suggérer un nouveau module',
      'proposer mes services',
      'autre',
    ]
  }
}

class ContextRecord extends Record {
  exportUserList () {
    return this.constructor.mapper.exportUserList(this.id)
      .then(response => {
        getResponseAsFile(response, {
          filename: `${kebabcase(this.alias)}-user-list.csv`,
        })
      }).catch(() => {
        alert(this.$t('errors.default.title'))
      })
  }

  setAsContextContributor ({ id }) {
    return this.constructor.mapper.setAsContextContributor(null, { data: { userId: id } })
  }

  revoqueAsContextContributor ({ id }) {
    return this.constructor.mapper.revoqueAsContextContributor(null, { data: { userId: id } })
  }

  setAsContextAdmin ({ id }) {
    return this.constructor.mapper.setAsContextAdmin(null, { data: { userId: id } })
  }

  revoqueAsContextAdmin ({ id }) {
    return this.constructor.mapper.revoqueAsContextAdmin(null, { data: { userId: id } })
  }
}
export const Context = store.defineMapper('Context', {
  schema: ContextSchema,
  endpoint: 'contexts',
  paths: {
    list: 'ContextList',
    edit: 'ContextEdit',
  },
  relations: {
  },
  recordClass: ContextRecord,
  pattern,
  splashScreensSizes,
  touchIcons,
})

addActions({
  exportUserList: {
    pathname: 'csv/users',
    method: 'GET',
  },
  setAsContextContributor: {
    pathname: 'current/contributors',
    method: 'POST',
  },
  revoqueAsContextContributor: {
    pathname: 'current/contributors',
    method: 'DELETE',
    request: function (request) {
      request.url = `${request.url}/${request.data.userId}`
      return request
    },
  },
  setAsContextAdmin: {
    pathname: 'current/admin',
    method: 'POST',
  },
  revoqueAsContextAdmin: {
    pathname: 'current/admin',
    method: 'DELETE',
    request: function (request) {
      request.url = `${request.url}/${request.data.userId}`
      return request
    },
  },
})(Context)
