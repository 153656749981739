const ChangePassword = () => import('@/components/Authentification/changePassword/changePassword.vue')
const PiAuthentification = () => import('@/components/Authentification/Authentification.vue')
const PiRegister = () => import('@/components/Authentification/register.vue')

const routes = [
  {
    path: '/reset/:token',
    name: 'ResetToken',
    meta: {
      breadcrumb: ['{{ contextName }}', 'Réinitialisation du mot de passe'],
      permission: 'notLogged',
    },
    component: ChangePassword,
    props: true,
  }, {
    path: '/reset',
    name: 'Reset',
    meta: {
      breadcrumb: ['{{ contextName }}', 'Réinitialisation du mot de passe'],
      permission: 'notLogged',
    },
    component: ChangePassword,
  }, {
    path: '/login',
    name: 'Login',
    meta: {
      breadcrumb: ['{{ contextName }}', 'Authentification'],
      permission: 'notLogged',
      fail: '/',
    },
    component: PiAuthentification,
  },
]

routes.push({
  path: '/register',
  name: 'Register',
  meta: {
    breadcrumb: ['{{ contextName }}', 'Création de compte entreprise'],
    permission: 'notLogged',
  },
  component: PiRegister,
})

export default routes
