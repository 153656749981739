export default {
  attributes: {
    certification: {
      title: 'Site des attestations | Sites des attestations',
      defined: 'le site des attestations | les sites des attestations',
      description: 'URL du site public. Ex : https://sous-domaine.domaine.ext',
    },
    certificate: {
      title: 'Attestation | Attestations',
      hint: 'Si activé, le participant obtiendra un certificat une fois sa Séquence terminée. Cette option se défini à la création et ne peux être changée.',
      score: 'Score Attestation | Scores Attestations',
      undefined: 'une attestation | des attestations',
    },
  },
  models: {
    Certification: {
      title: 'Attestation | Attestations',
      defined: 'la attestation | les attestations',
      undefined: 'une attestation | des attestations',
      to: 'de cette attestation | des attestations',
      this: 'de cette attestation | de ces attestations',
      alias: 'Attestation | Attestations',
      all: 'Toute l\'attestation | Toutes les attestations',
    },
  },
  routes: {
    CertifierList: 'Examinateurs',
    ClassCertificationList: 'Parcours attestation',
    ProgramCertificationGrid: 'Modules attestation',
  },
  roles: {
    CERTIFIER: {
      icon: 'el-icon-pi-certificateurs',
      title: 'Examinateur | Examinateurs',
      this: 'cet examinateur | ces examinateurs',
      undefined: 'un examinateur | des examinateurs',
      defined: 'l\'examinateur | les examinateurs',
      none: 'aucun examinateur',
      detail: 'Peut encadrer des attestations au nom d\'une entreprise',
    },
  },
}
