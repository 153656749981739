import { isArray, isString } from 'lodash'
const alive = {
  store: {},
  options: {},
  install (Vue, options) {
    this.options = Object.assign(this.options, options || {})
    this.store = new Vue({
      data () {
        return {
          public: {
            offline: !navigator.onLine,
            maintenance: false,
            custom: '',
          },
        }
      },
    })
    this.installOffline()
    this.installMaintenance()
    // window.alive = this
    Vue.prototype.$aliveMaintenanceUrlsToSurveil = (datas) => {
      if (isArray(datas)) {
        this.options.maintenance = datas
      } else if (isString(datas)) {
        this.options.maintenance = [datas]
      } else {
        this.options.maintenance = []
      }
    }
    Vue.prototype.$alive = this.store.public
    Vue.prototype.$aliveClear = () => {
      this.store.public.maintenance = false
    }
    Vue.prototype.$aliveSetCustom = (value) => {
      this.store.public.custom = value
    }
  },
  installOffline () {
    window.addEventListener('online', () => {
      this.store.public.offline = false
    })
    window.addEventListener('offline', () => {
      this.store.public.offline = true
    })
  },
  installMaintenance () {
    setXMLRequestCallback((xhr) => {
      if (!xhr.responseURL) { return }
      if ((xhr.status === 503 || xhr.status === 504 || xhr.status === 502) && xhr.readyState >= XMLHttpRequest.DONE) {
        for (let urlIndex = 0; urlIndex < this.options.maintenance.length; urlIndex++) {
          const url = this.options.maintenance[urlIndex]
          if (xhr.responseURL.indexOf(url) >= 0) {
            this.addMaintenanceError(url)
            break
          }
        }
      }
    })
  },
  addMaintenanceError (url) {
    this.store.public.maintenance = url
  },
}

function setXMLRequestCallback (callback) {
  let oldSend
  if (XMLHttpRequest.callbacks) {
    XMLHttpRequest.callbacks.push(callback)
  } else {
    XMLHttpRequest.callbacks = [callback]
    oldSend = XMLHttpRequest.prototype.send
    XMLHttpRequest.prototype.send = function () {
      this._onreadystatechange = this.onreadystatechange
      const xhr = this
      this.onreadystatechange = () => {
        try {
          XMLHttpRequest.callbacks.forEach(cb => cb.apply(this, [xhr]))
        } catch (error) {
          console.error(error)
        }
        if (this._onreadystatechange) {
          this._onreadystatechange.apply(this, arguments)
        }
      }
      oldSend.apply(this, arguments)
    }
  }
}
export default alive
