import frLocale from 'element-ui/lib/locale/lang/fr'
import './moment/fr.js'
import certification from './certification/fr'
import { EXERCICES } from '@/api/enums/exercices.js'
import { CONTENTS } from '@/api/enums/contents.js'
import { WIDGETS } from '@/api/enums/widgets.js'

/* REGLES :
    - Bien rechercher si un mot ou une partie de l'expression n'existe pas déjà (voire les 'something')
    - Utiliser le pipe (|) le plus souvent possible pour garder un maximum de possibilité
    - Si le pluriel ne sert à rien pour un mot, le mettre au féminin, exemple : 'Aucun | Aucune'
    - Garder un maximum de cohérence dans les noms des clés
    - Eviter les string comme clé sauf si la clé sera renseignée dynamiquement, exemple : "rows.['answer']"
*/

export default {
  ...frLocale,
  buy: {
    errors: {
      requires_source: 'La validation du paiement à échoué',
      TRANSACTION_IN_PROGRESS: 'La validation de votre commande prend plus de temps que prévu, nous invitons à recharger cette page d\'ici quelques minutes',
      TRANSACTION_CANCELED: 'Votre transaction a été annulée',
      ORDER_MISSING: 'Votre transaction est introuvable',
      WALLET_MISSING: 'Votre moyen de paiement n\'est pas disponible',
      ORDER_NOT_SUCCEEDED: 'Votre transaction n\'est pas encore validée',
      ORDER_IN_ERROR: 'Une erreur est survenue lors de votre transaction',
      card_declined: 'Carte invalide',
      yourCardWasDeclined: 'Votre carte a été refusée.',
    },
    packs: {
      credit: 'pack de crédits | packs de crédits',
      license: 'pack de licences | packs de licences',
    },
  },
  jsData: {
    errors: {
      invalid: 'Donnée invalide',
      oneOfX: '{enum} nécéssaire',
      lengthNoLessThanX: 'minimum {number} caractères',
    },
    enum: {
      string: 'Chaîne de caractère',
    },
    separator: {
      or: ' ou ',
    },
  },
  rows: {
    table: {
      title: 'Tableau interactif',
    },
    [CONTENTS.SEND_MEDIAS]: {
      title: 'Envoi de ressources par mails',
      detail: 'Liste de document que les apprenants peuvent consulter avant de se l\'envoyer par mail',
    },
    [EXERCICES.QCM]: {
      title: 'QCM',
      detail: 'Question à choix multiple (QCM)',
    },
    [EXERCICES.SELECT]: {
      title: 'QCU',
      detail: 'Question à réponse simple',
    },
    [EXERCICES.INPUT]: {
      title: 'Saisie libre',
      detail: 'Question attendant une réponse avec une saisie libre',
    },
    [EXERCICES.DND]: {
      title: 'Glisser/déposer',
      detail: 'Elements à déposer dans la bonne zone',
    },
    answer: {
      title: 'DD réponse',
    },
    question: {
      title: 'DD question',
    },
    [EXERCICES.PICK_WORDS]: {
      title: 'Mots cliquables',
      detail: 'Réponses à sélectionner dans l\'énoncé',
    },
    [EXERCICES.IMAGE_CLICKABLE]: {
      title: 'Image Cliquable',
      detail: 'Réponses à sélectionner dans l\'image',
    },
    [CONTENTS.VIDEO_URL]: {
      title: 'Vidéo externe',
      detail: 'Intégrer une vidéo Youtube ou Vimeo',
    },
    [CONTENTS.INTERNAL_FILE]: {
      title: 'Média interne',
      detail: 'Intégrer une image, un fichier PDF ou un son',
    },
    [CONTENTS.TINCAN_URL]: {
      title: 'Tincan',
      detail: 'Intégrer un tincan',
    },
    [CONTENTS.WYSIWYG]: {
      title: 'Traitement de texte',
      detail: 'Intégrer du texte',
    },
    [WIDGETS.COURSE_TITLE]: {
      title: 'Titre de la Séquence',
    },
    [WIDGETS.SECTION_TITLE]: {
      title: 'Titre de la section',
      detail: 'Insérer le nom de le section dans laquelle se trouve ce Grain',
    },
    [WIDGETS.COURSE_ICON]: {
      title: 'Icône thème de la Séquence',
      detail: 'Insérer l\'icône du thème rattachée à la Séquence',
    },
    [WIDGETS.COURSE_COMPETENCY_LIST]: {
      title: 'Compétences mathématiques (NE PAS UTILISER)',
      detail: 'Intégrer des compétences mathématiques',
    },
    [WIDGETS.XP_EARNED]: {
      title: 'Expérience gagnée',
    },
    [WIDGETS.PI_XP_EARNED]: {
      title: 'Expérience gagnée et score Séquence',
      detail: 'Afficher l\'expérience gagnée et/ou le score du participant sur la Séquence',
    },
    [WIDGETS.JOB_XP_EARNED]: {
      title: 'Expérience thème gagnée',
      detail: 'Insérer le niveau thème de la Séquence',
    },
    [WIDGETS.COMPETENCY_XP_EARNED]: {
      title: 'Expérience gagnée (détail)',
      detail: 'Afficher la progression pour chaque compétence qui compose l\'indicateur Pi',
    },
    exercices: {
      stats: {
        title: 'Statistique | Statistiques',
      },
      exercice: {
        title: 'Exercice | Exercices',
        undefined: 'un exercice | des exercices',
        of: 'd\'Exercices | des Exercices',
        defined: 'l\'exercice | les exercices',
      },
      content: {
        title: 'Contenu | Contenus',
        undefined: 'un contenu | des contenus',
        defined: 'le contenu | les contenus',
      },
      widget: {
        title: 'Widget | Widgets',
        undefined: 'un widget | des widgets',
        defined: 'le widget | les widgets',
      },
      question: {
        title: 'Question | Questions',
        undefined: 'une question | des questions',
        defined: 'la question | les questions',
      },
      answer: {
        title: 'Réponse | Réponses',
        undefined: 'une réponse | des réponses',
        valid: 'Réponse valide | Réponses valides',
        to: 'de réponse | des réponses',
        give: 'Réponse donnée | Réponses données',
        expected: 'Réponse attendue | Réponses attendues',
      },
      file: {
        title: 'Fichier | Fichiers',
        undefined: 'un fichier | des fichiers',
      },
      chrono: {
        title: 'Chronomètre | Chronomètres',
        undefined: 'un chronomètre | des chronomètres',
      },
      hint: {
        title: 'Indice | Indices',
        undefined: 'un indice | des indices',
      },
      feedback: {
        title: 'Feedback | Feedbacks',
        undefined: 'un feedback | des feedbacks',
      },
      setting: {
        title: 'Paramètre | Paramètres',
        undefined: 'un paramètre | des paramètres',
      },
      order: {
        title: 'Consigne | Consignes',
        undefined: 'une consigne | des consignes',
        defined: 'la consigne | les consignes',
      },
      option: {
        title: 'Option | Options',
        undefined: 'une option | des options',
        defined: 'l\'option | les options',
      },
      options: {
        accents: {
          title: 'Accents',
          description: 'Autoriser les erreurs d\'accents',
        },
        case: {
          title: 'Casse',
          description: 'Autoriser les erreurs de casse',
        },
        includes: {
          title: 'Contient les mots',
          description: 'Autoriser les réponses incomplètes',
        },
        space: {
          title: 'Espaces',
          description: 'Ne pas tenir compte des espaces',
        },
        decimalSeparator: {
          title: 'Séparateur décimal',
          description: 'Autoriser les points et les virgules pour les nombres décimaux',
        },
      },
    },
  },
  export: {
    csv: {
      what: {
        title: 'Exporter {something}',
      },
      icon: 'el-icon-fa-file-text-o',
      description: 'Cet espace est dédié à l\'export de données sous forme de tableur. Vous pouvez choisir parmi les critères listés ci-dessous pour extraire les données qui vous intéressent.',
      from: {
        title: 'Du',
        description: 'Début de la période pour laquelle vous souhaitez des données. Llaissez vide si vous voulez toutes les données.',
      },
      to: {
        title: 'Au',
        description: 'Date de fin de la période pour laquelle vous souhaitez des données. Laissez vide pour avoir toute les données jusqu\'à aujourd\'hui.',
      },
      companies: {
        title: 'Entreprise',
        description: '',
      },
      contextId: {
        title: 'Contexte',
        description: '',
      },
      sessionId: {
        title: 'Parcours',
        description: 'Choisissez un parcours spécifique pour avoir les données de ses participants',
      },
      teacherId: {
        title: 'Animateur',
        description: 'En choisissant un animateur vous aurez tous les participants à ses parcours de formation',
      },
      groupId: {
        description: 'En choisissant un groupe vous aurez tous les participants de ce groupe',
      },
      aFromDate: {
        title: 'une date de début',
        description: '',
      },
      aToDate: {
        title: 'une date de fin',
        description: '',
      },
    },
  },
  appFiles: {
    library: {
      title: 'Librairie | Librairies',
      undefined: 'une librairie | des librairies',
      defined: 'la librairie | les librairies',
    },
    owner: {
      title: 'Propriétaire | Propriétaires',
      defined: 'le/la propriétaire | les propriétaires',
      undefined: 'un(e) propriétaire | des propriétaires',
    },
  },
  context: {
    credit: {
      title: 'crédit singular | crédits plural',
      addMany: 'Ajouter des crédits',
      myPlural: 'Mes crédits',
      undefined: 'un crédit | des crédits',
    },
    license: {
      title: 'licence singular | licences plural',
      addMany: 'Ajouter des licences',
      myPlural: 'Mes licences',
      undefined: 'une licence | des licences',
    },
    experience: {
      title: 'expérience',
    },
  },
  attributes: {
    theme: {
      title: 'Thème visuel de l\'application',
    },
    comment: {
      title: 'Commentaire',
    },
    ratio: {
      title: 'Note',
    },
    defaultCurrency: {
      title: 'Monnaie par défaut',
    },
    referralResource: {
      title: 'Support de formation | Supports de formation',
      undefined: 'un support de formation | des supports de formation',
      defined: 'le support de formation | les supports de formation',
    },
    subject: {
      title: 'Objet | Objets',
      undefined: 'un objet | des objets',
    },
    requiredByFront: {
      title: 'Requis par les utilisateurs du frontoffice',
    },
    pricing: {
      title: 'Tarif',
    },
    certificationType: {
      title: 'Type de validation',
      certification: {
        label: 'Certification',
      },
      attestation: {
        label: 'Attestation',
      },
    },
    usage: {
      count: 'Accompli',
    },
    mailPrimaryColor: {
      title: 'Couleur principale des mails | Couleurs principales des mails',
      defined: 'la couleur principale des mails | les couleurs principales des mails',
    },
    mailSecondaryColor: {
      title: 'Couleur secondaire des mails | Couleurs secondaires des mails',
      defined: 'la couleur secondaire des mails | les couleurs secondaires des mails',
    },
    boPrimaryColor: {
      title: 'Couleur principale | Couleurs principales',
      defined: 'la couleur principale | les couleurs principales',
    },

    boRelancePrimaryColor: {
      title: 'Couleur 1 de relance',
      defined: 'la couleur utilisée pour les grands boutons',
    },

    boRelanceSecondaryColor: {
      title: 'Couleur 2 de relance',
      defined: 'la couleur utilisée pour les grands boutons',
    },
    boRelanceQuaternaryColor: {
      title: 'Couleur 3 de relance',
      defined: 'la couleur utilisée pour les grands boutons',
    },
    boRelanceUseIllustration: {
      title: 'Utiliser les personnages',
      defined: 'l\'ajout des personnages dans les boutons.',
    },
    foPrimaryColor: {
      title: 'Couleur principale | Couleurs principales',
      defined: 'la couleur principale | les couleurs principales',
    },
    foPrimaryVariantColor: {
      title: 'Couleur principale d\'arrivée | Couleurs principales d\'arrivée',
      defined: 'la couleur principale d\'arrivée | les couleurs principales d\'arrivée',
    },
    boPrimaryTextColor: {
      title: 'Couleur principale des textes | Couleurs principales des textes',
      defined: 'la couleur principale des textes | les couleurs principales des textes',
    },
    foStyleUseBorder: {
      title: 'Interface utilisant des bordures | Interfaces utilisant des bordures',
      defined: 'L\'usage des bordures | Les usages des bordures',
    },
    foPrimaryTextColor: {
      title: 'Couleur principale des textes | Couleurs principales des textes',
      defined: 'la couleur principale des textes | les couleurs principales des textes',
    },
    boShowContributionBox: {
      title: 'Afficher la contribution depuis le dashboard',
    },
    boContributionBoxContent: {
      title: 'Message',
      description: 'Laissez vide ou supprimez tout le contenu pour afficher le texte par défaut',
    },
    boSecondaryColor: {
      title: 'Couleur secondaire | Couleurs secondaires',
      defined: 'la couleur secondaire | les couleurs secondaires',
    },
    foSecondaryColor: {
      title: 'Couleur secondaire | Couleurs secondaires',
      defined: 'la couleur secondaire | les couleurs secondaires',
    },
    foSecondaryVariantColor: {
      title: 'Couleur secondaire d\'arrivée | Couleurs secondaires d\'arrivée',
      defined: 'la couleur secondaire d\'arrivée | les couleurs secondaires d\'arrivée',
    },
    boSecondaryTextColor: {
      title: 'Couleur secondaire des textes | Couleurs secondaires des textes',
      defined: 'la couleur secondaire des textes | les couleurs secondaires des textes',
    },
    foSecondaryTextColor: {
      title: 'Couleur secondaire des textes | Couleurs secondaires des textes',
      defined: 'la couleur secondaire des textes | les couleurs secondaires des textes',
    },
    foTertiaryColor: {
      title: 'Couleur tertiaire | Couleurs tertiaires',
      defined: 'la couleur tertiaire | les couleurs tertiaires',
    },
    foQuaternaryColor: {
      title: 'Couleur quaternaire | Couleurs quaternaires',
      defined: 'la couleur quaternaire | les couleurs quaternaires',
    },
    foShowLateralBar: {
      title: 'Interface affichant la colonne latérale',
    },
    mail: {
      title: 'Adresse email de l\'expéditeur des emails',
      defined: 'l\'adresse',
      description: 'Champs "from"',
    },
    allowAccessToRegisterFeature: {
      title: 'Permettre aux utilisateurs de créer leur compte et leur entreprise',
    },
    allowAccessToInvitationCodeFeature: {
      title: 'Permettre la création de compte par invitation sans mail',
    },
    isAppointmentFeatureEnabled: {
      title: 'Permettre la création et la gestion de rendez-vous',
    },
    isWorkFeatureEnabled: {
      title: 'Permettre la création et la gestion de travaux',
    },
    allowAccessToBuyFeature: {
      title: 'Permettre l\'accès aux achats de crédit',
    },
    allowAccessToCertificationFeature: {
      title: 'Permettre l\'accès à la certification',
    },
    allowEditGroupFeature: {
      title: 'Autoriser la suppression et édition des groupes',
    },
    allowSendMailOnClassOpeningFeature: {
      title: 'Envoyer des emails à l\'ouverture programmé d\'une classe',
    },
    allowSendMailOnClassClosingFeature: {
      title: 'Envoyer des emails 1 semaines avant la cloture d\'une classe',
    },
    allowUserToSet: {
      title: 'Permettre la définition :',
    },
    useInternalAuthentification: {
      title: 'Les utilisateurs utilisent la connexion interne à la plateforme :',
      help: 'par défaut à oui, sinon cela masquera les écrans d\'authentification, et affichera un message spécifique à la déconnexion',
    },
    simplePasswordForInvitationCode: {
      title: 'Autoriser les mot de passe "faibles" pour les comptes créés avec une invitation',
    },
    anonymousPdfForInvitationCode: {
      title: 'Fournir un PDF de première connexion avec les données anonymisées (sans le passage avec les données obligatoires)',
    },
    logoId: {
      title: 'Logo principal',
    },
    foFaviconId: {
      title: 'Favicon du frontoffice',
    },
    foLogoId: {
      title: 'Logo du frontoffice',
    },
    // foTouchIconId: {
    //   title: 'Logo pour les webapp'
    // },
    foIconColor: {
      title: 'Couleur des icones Thèmes sur la couleur tertiaire',
    },
    boFaviconId: {
      title: 'Favicon du Backoffice',
    },
    foLogoBadgeId: {
      title: 'Logo affiché sur les badges',
    },
    mailFooterId: {
      title: 'Footer de l\'email',
      description: '600px de large',
    },
    mailBannerLogoId: {
      title: 'Logo pour la bannière de l\'email',
      description: '600px de large',
    },
    authScreenBackgroundId: {
      title: 'Arrière-plan de l\'écran de connexion',
    },
    smallLogoId: {
      title: 'Logo en petite taille',
    },
    creditLabel: {
      title: 'Crédit | Crédits',
      defined: 'le label | les labels',
      description: 'Ex : crédit PI | crédits PI',
    },
    licenseLabel: {
      title: 'Licence | Licences',
      defined: 'le label | les labels',
      description: 'Ex : licence PI | licences PI',
    },
    scoreLabel: {
      title: 'Score',
      defined: 'le label | les labels',
      description: 'Ex : score PI | scores PI',
    },
    levelLabel: {
      title: 'Niveau',
      defined: 'le label | les labels',
      description: 'Ex : niveau PI | niveaux PI',
    },
    experienceLabel: {
      title: 'Expérience',
      defined: 'l\'expérience | les expériences',
      description: 'Ex : expérience PI | expériences PI',
    },
    gaugeLabel: {
      title: 'Indicateur',
      defined: 'le label | les labels',
      description: 'Ex : indicateur PI | indicateurs PI',
    },
    sharedCompetencyLabel: {
      title: 'Partager l\'arborescence générique (partagée par défaut)',
    },
    reportingLevelLabel: {
      title: 'Afficher les compétences à partir du niveau',
    },
    mainWebsite: {
      title: 'Site principal ou commercial',
      defined: 'Le site principal ou commercial',
      description: 'Site qui contient les mentions légales, ou un moyen de contact. Ex : https://www.domaine.ext',
    },
    backoffice: {
      title: 'Espace d\'administration | Espaces d\'administration',
      defined: 'l\'espace d\'administration | les espaces d\'administration',
      description: 'URL de l\'administration. Ex : https://sous-domaine.domaine.ext',
    },
    application: {
      title: 'Espace d\'évaluation | Espaces d\'évaluation',
      defined: 'l\'espace d\'évaluation | les espaces d\'évaluation',
      description: 'URL du site public (apprenant et formateur). Ex : https://sous-domaine.domaine.ext',
    },
    player: {
      title: 'Player legacy | Players legacy',
      defined: 'player legacy | players legacy',
      description: 'URL du player legacy pour le passage d\'exercice. Ex : https://sous-domaine.domaine.ext',
    },
    certification: certification.attributes.certification,
    api: {
      title: 'Api | Api',
      defined: 'l\'Api | les Api',
      description: 'URL de l\'Api sans https://. Ex : sous-domaine.domaine.ext',
    },
    lrs: {
      title: 'Lrs | Lrs',
      defined: 'le Lrs | les Lrs',
      description: 'URL du Lrs avec https://. Ex : https://sous-domaine.domaine.ext',
    },
    lrsBasicAuth: {
      title: 'Authentification basique du Lrs | Authentifications basique du Lrs',
      defined: 'l\'Authentification basique du Lrs | les Authentifications basique sdu Lrs',
    },
    alias: {
      title: 'Identifiant | Identifiants',
      defined: 'l\'identifiant | les identifiants',
      undefined: 'un identifiant | des identifiants',
    },
    externalFacebookLink: {
      title: 'Facebook',
      defined: 'le lien',
    },
    externalDemonstrationVideoLink: {
      title: 'Vidéo de démonstration',
      defined: 'le lien',
    },
    externalReferenceWebSiteLink: {
      title: 'Dossier contenant les guides d\'interface',
      defined: 'le lien',
    },
    externalSignUpWebSiteLink: {
      title: 'Url externe proposée pour l\'inscription',
      defined: 'le lien',
    },
    legalDate: {
      title: 'Date de la version',
    },
    legalVersion: {
      title: 'Numéro de version',
    },
    legalContent: {
      title: 'Contenu',
    },
    froalakey: {
      title: 'Clé de licence Froala',
      defined: 'la clé',
    },
    successStats: {
      title: 'Réussite | Réussites',
    },
    sound: {
      title: 'Son | Sons',
    },
    status: {
      title: 'État | États',
    },
    tincan: {
      title: 'Tincan | Tincans',
    },
    banner: {
      title: 'Bandeau | Bandeaux',
    },
    metas: {
      title: 'Caractéristiques',
    },
    isPack: {
      title: 'Est-ce un pack ?',
    },
    isMobile: {
      question: 'Est-ce compatible mobile ?',
      title: 'Périphérique',
    },
    allowResetByStudent: {
      title: 'Permettre aux apprenants de remettre à zéro leur module',
    },
    showOnlyAvailableLesson: {
      title: 'Masquer les séquences non ouvertes pour les apprenants ?',
    },
    publicUpdatedAt: {
      title: 'Dernière mise à jour',
    },
    group: {
      title: 'Groupe | Groupes',
      undefined: 'un groupe | des groupes',
    },
    estimatedDifficulty: {
      title: 'Difficulté estimée',
    },
    visibility: {
      title: 'Visibilité',
    },
    estimatedTime: {
      title: 'Temps estimé',
    },
    backgroundImage: {
      title: 'Image d\'arrière plan | Images d\'arrière plan',
    },
    hints: {
      title: 'Indice | Indices',
      hint: "L'activation des indices permettra au participant de les consulter lorsqu'il y en a. La désactivation fera comme si les Grains n'avaient pas d'indices.",
    },
    feedbacks: {
      title: 'Feedback | Feedbacks',
      hint: "L'activation des feedbacks permettra au participant de les voir après chaque soumission de réponse. La désactivation empêchera tout affichage de ces retours visuels.",
    },
    retry: {
      title: 'Réessai possible | Réessais possibles',
      hint: 'Si activé, le participant pourra refaire les Grains de sa Séquence',
    },
    competencies: {
      title: 'Score Indicateur Pi | Scores Indicateurs Pi',
      hint: "Si activé, impacte l'indicateur PI et les niveaux compétences du participant",
    },
    order: {
      title: 'Ordre',
    },
    categories: {
      title: 'Score de la Séquence | Scores des Séquences',
      hint: 'Si activé, impacte les niveaux thèmes du participant',
    },
    certificate: certification.attributes.certificate,
    exercice: {
      title: 'Exercice | Exercices',
    },
    siret: {
      title: 'SIRET | SIRET',
      undefined: 'un SIRET | des SIRET',
    },
    word: {
      title: 'Mot | Mots',
      undefined: 'un mot | des mots',
    },
    definition: {
      title: 'Définition | Définitions',
      undefined: 'une définition | des définitions',
    },
    icon: {
      title: 'Icône | Icônes',
      undefined: 'une icône | des icônes',
    },
    title: {
      title: 'Titre',
      defined: 'le titre | les titres',
      undefined: 'un titre | des titres',
      something: 'Titre de {something}',
    },
    type: {
      title: 'Type | Types',
      mime: 'Type MIME',
      something: 'Type de {something}',
      undefined: 'un type | des types',
    },
    link: {
      title: 'URL externe',
    },
    createdThe: {
      title: 'Créé le',
    },
    startAt: {
      title: 'Date de début',
      shortTitle: 'Début',
    },
    expireAt: {
      title: 'Date de fin',
      shortTitle: 'Fin',
    },
    flag: {
      title: 'Flag | Flags',
      undefined: 'un flag |  des flags',
    },
    tag: {
      title: 'Tag | Tags',
    },
    category: {
      title: 'Thème | Thèmes',
    },
    competency: {
      title: 'Compétence | Compétences',
    },
    modifiedThe: {
      title: 'Modifié',
    },
    operation: {
      title: 'Opération | Opérations',
    },
    reference: {
      title: 'Référence | Références',
      defined: 'la référence | les références',
      short: 'Réf.',
    },
    path: {
      title: 'Arborescence',
    },
    code: {
      title: 'Code',
    },
    job: {
      title: 'Thème | Thèmes',
    },
    prerequisites: {
      title: 'Prérequis | Prérequis',
    },
    description: {
      title: 'Description | Descriptions',
      undefined: 'une description | des descriptions',
      defined: 'la description | les descriptions',
      short: {
        title: 'Résumé | Résumés',
        undefined: 'un résumé | des résumés',
      },
    },
    time: {
      title: 'Temps',
    },
    timeInSec: {
      title: 'Temps (en secondes)',
    },
    credit: {
      title: 'Crédit | Crédits',
      nocaptitle: 'crédit | crédits',
    },
    license: {
      title: 'Licence | Licences',
      nocaptitle: 'licence | licences',
      until: 'Licencié jusqu\'au',
    },
    difficulty: {
      title: 'Difficulté',
    },
    // keyWord: {
    //   title: 'Mot clé | Mots clés'
    // },
    image: {
      title: 'Image | Images',
      undefined: 'une image | des images',
      short: 'Img',
      my: 'mon image | mes images',
    },
    preview: {
      title: 'Aperçu | Aperçus',
    },
    illustration: {
      title: 'Illustration | Illustrations',
    },
    text: {
      title: 'Texte | Textes',
      undefined: 'un texte | des textes',
      ofIt: 'du texte | des textes',
      short: 'Txt',
      writeIt: 'Entrez votre texte',
    },
    media: {
      title: 'Média | Médias',
      undefined: 'un média | des médias',
    },
    variable: {
      title: 'Variable | Variables',
      short: 'Var.',
    },
    expression: {
      title: 'Expression | Expressions',
    },
    position: {
      title: 'Position | Positions',
    },
    message: {
      title: 'Message | Messages',
      undefined: 'un message | des messages',
      defined: 'le message | les messages',
      this: 'ce message | ces messages',
      short: 'Msg',
    },
    action: {
      title: 'Répercussion | Répercussions',
      undefined: 'une répercussion | des répercussions',
    },
    id: {
      title: 'ID',
    },
    key: {
      title: 'Clé | Clés',
    },
    size: {
      title: 'Taille | Tailles',
    },
    name: {
      title: 'Nom | Noms',
      undefined: 'un nom | des noms',
      defined: 'le nom | les noms',
    },
    address: {
      title: 'Adresse | Adresses',
      undefined: 'une adresse | des adresses',
    },
    city: {
      title: 'Ville | Villes',
      undefined: 'une ville | des villes',
    },
    postalNumber: {
      title: 'Code postal',
      undefined: 'un code postal | des codes postaux',
    },
    username: {
      title: 'Pseudo',
      undefined: 'un pseudo | des pseudos',
    },
    usernameOrCode: {
      title: 'Code / Identifiant',
      undefined: 'un code / identifiant | des codes / identifiants',
    },
    firstname: {
      title: 'Prénom | Prénoms',
      undefined: 'un prénom | des prénoms',
    },
    birthday: {
      title: 'Date de naissance',
      undefined: 'une date de naissance',
    },
    email: {
      title: 'Adresse e-mail | Adresses e-mail',
      undefined: 'une adresse e-mail | des adresses e-mail',
      ask: 'Merci d\'entrer votre adresse e-mail',
    },
    function: {
      title: 'Fonction',
      undefined: 'une fonction | des fonctions',
    },
    phone: {
      title: 'Numéro de téléphone',
      undefined: 'un numéro de téléphone | des numéros de téléphone',
    },
    password: {
      new: 'Saisissez votre nouveau mot de passe',
      title: 'Mot de passe',
      defined: 'le mot de passe | les mots de passe',
      undefined: 'un mot de passe | des mots de passe',
      ask: 'Veuillez saisir votre mot de passe',
    },
    avatar: {
      title: 'Avatar',
      undefined: 'un avatar | des avatars',
    },
    role: {
      title: 'Rôle | Rôles',
      undefined: 'un rôle | des rôles',
      defined: 'le rôle | les rôles',
    },
    url: {
      title: 'URL',
      undefined: 'une URL | des URL',
    },
    comportment: {
      title: 'Comportement | Comportements',
      defined: 'le comportement | les comportements',
      undefined: 'un comportement | des comportements',
    },
    state: {
      title: 'État | États',
      defined: 'l\'état | les états',
      undefined: 'un état | des états',
    },
    parent: {
      title: 'Parent | Parents',
    },
    object: {
      title: 'Objet | Objets',
    },
    color: {
      title: 'Couleur | Couleurs',
    },
    amount: {
      title: 'Montant | Montants',
    },
    date: {
      title: 'Date | Dates',
    },
    debitsAndCredits: {
      title: 'Débit / Crédit | Débits / Crédits',
    },
    quantity: {
      title: 'Quantité | Quantités',
      something: 'Quantité de {something}',
    },
    price: {
      title: 'Prix | Prix',
      'unit-duty-free': 'Prix unitaire HT | Prix unitaires HT',
      'total-duty-free': 'Prix HT total | Prix HT total',
      'echelon-duty-free': 'Prix HT à l\'échelon | Prix HT à l\'échelon',
      'per-payment': 'Prix à chaque paiement',
    },
    payment: {
      echeloned: 'Échelonné',
      single: 'Unique',
      type: 'Type de paiement',
    },
    invoicingLabel: {
      title: 'Nom Facturation',
      defined: 'le nom pour la facturation',
    },
    tva: {
      title: 'TVA',
      defined: 'la TVA',
    },
    planInterval: {
      title: 'Période inter-échelon',
      count: 'Valeur de la période',
      type: 'Unité de la période',
    },
    duration: {
      title: 'Durée totale',
      count: 'Valeur de la durée',
      type: 'Unité de la durée',
    },
    CompanyUserGroup: {
      title: 'Groupe',
    },
    anonymisation: {
      title: 'Anonymisation des utilisateur',
    },
    period: {
      title: 'Type d\'anonymisation',
      annual: 'Annuel',
      delay: 'Délai d\'inactivité',
    },
    sendMail: {
      title: 'Prévenir par mail',
    },
    delay: {
      title: 'Nombre de jours d\'inactivité',
    },
    annualDate: {
      title: 'Date d\'anonymisation (ne pas prendre en compte l\'année)',
    },
  },
  settings: {
    [EXERCICES.PICK_WORDS]: {
      questionIsHidden: 'Masquer la question et la consigne',
    },
    [EXERCICES.QCM]: {
      columns: 'Nombre de colonnes',
      showDescriptionIfImage: 'Affichage des descriptions',
      returnToLineAfterImage: 'Retour à la ligne',
      keepOrder: 'Conserver l\'ordre des réponses pour tout le monde',
    },
    hideMainTitle: {
      label: 'Masquer le titre principal',
    },
    backToDashBoard: {
      label: 'Afficher le bouton de retour au dashboard',
    },
    hideNextButton: {
      label: 'masquer le bouton "Suivant" qui termine le Grain',
    },
    hideCloseButton: {
      label: 'masquer le bouton fermant le Grain',
    },
    hideTitle: 'Masquer le titre',
    hideDescription: 'Masquer la description',
    autoplay: 'Lancer automatiquement la lecture',
    beforeStatement: 'Placer avant l\'énoncé',
    showPi: 'Afficher indicateur Pi',
    showScore: 'Afficher score',
    constrain: 'contraindre le pdf à la zone visible',
  },
  actions: {
    noStudentRTP: 'Pas d\'apprenant dans cette classe. Vos étudiants doivent se connecter une première fois pour être automatiquement ajoutés à la classe. Vous pouvez tout de même commencer un parcours avec une classe vide. Les étudiants seront ajoutés automatiquement dès leur première connexion.',
    apply: {
      action: 'Appliquer',
      something: 'Appliquer {something}',
    },
    actor: {
      title: 'Changer de rôle utilisateur',
      description: 'Cet écran permet aux administrateurs d\'avoir l\'interface pour une entreprise et un ou des rôles choisis.',
    },
    clear: {
      title: 'Vider',
    },
    recover: {
      title: 'Renouvellement de mot de passe',
    },
    reorder: {
      action: 'Réorganiser',
      something: 'Réorganiser {something}',
    },
    export: {
      action: 'Exporter',
      something: 'Exporter {something}',
    },
    convert: {
      convertTo: 'Convertir en',
      text: 'Texte',
      html: 'HTML',
      toText: 'Convertir en Texte',
      toHtml: 'Convertir en HTML',
    },
    delete: {
      action: 'Supprimer',
      something: 'Supprimer  {something}',
      title: 'Suppression',
      confirmModel: 'Confirmez-vous la suppression {model}',
      confirmWord: 'SUPPRIMER',
    },
    deleteDefinitely: {
      action: 'Supprimer définitivement',
      something: 'Supprimer définitivement  {something}',
      confirmModel: 'Confirmez-vous la suppression définitive {model}',
    },
    remove: {
      action: 'Retirer',
    },
    edit: {
      action: 'Éditer',
      title: 'Édition',
      something: 'l\'édition {something}',
    },
    exportUsers: {
      action: 'Export Utilisateurs',
      title: 'Exportation de la liste des utilisateurs',
    },
    see: {
      action: 'Voir',
      details: 'Voir les détails',
    },
    create: {
      action: 'Créer',
      title: 'Création',
      something: 'Créer {something}',
    },
    save: {
      action: 'Sauvegarder',
      title: 'Enregistrement',
      done: 'Enregistrement effectué',
    },
    close: {
      action: 'Fermer',
    },
    cancel: {
      action: 'Annuler',
      title: 'Abandon',
    },
    duplicate: {
      action: 'Dupliquer',
      title: 'Duplication',
      titleSomething: 'Duplication de : {something}',
      confirm: 'Confirmez-vous la duplication du contenu suivant : | Confirmez-vous la duplication des contenus suivants : ',
      toSomewhere: 'Dupliquer vers {somewhere}',
      thisWill: 'dupliquera',
    },
    duplicateToContext: {
      action: 'Dupliquer vers un autre environnement | Dupliquer vers d\'autres environnements',
      title: 'Duplication vers l\'environnement choisi',
      confirm: 'Confirmez-vous la duplication du contenu suivant : | Confirmez-vous la duplication des contenus suivants : ',
    },
    select: {
      action: 'Associer',
      something: 'Sélectionnez {something}',
    },
    selectToAccessFunction: {
      action: 'Sélectionner pour accéder à cette fonction',
      something: 'Sélectionnez {something} pour accéder à cette fonction',
    },
    share: {
      action: 'Partager',
      something: 'Partagez {something}',
      thisWill: 'partagera',
    },
    shareToContext: {
      action: 'Partager avec un autre environnement | Partager vers d\'autres environnements',
      title: 'Partager vers l\'environnement choisi',
      confirm: 'Confirmez-vous le partage du contenu suivant : | Confirmez-vous la partage des contenus suivants : ',
      success: 'Le partage a été effectué avec succès',
    },
    confirm: {
      action: 'Confirmer',
      byInput: 'Confirmez en écrivant',
      title: 'Confirmation',
      sure: 'Cette action est irréversible. Êtes-vous sûr ? ',
    },
    unlock: {
      action: 'Déverrouiller',
      title: 'Déverrouiller',
      confirmWord: 'Déverrouiller',
    },
    hide: {
      action: 'Changer',
    },
    add: {
      action: 'Ajouter',
      title: 'Ajout',
      something: 'Ajouter {something}',
      manySomething: 'Ajouter des {something}',
    },
    addOrRemove: {
      action: 'Ajouter / retirer',
      something: 'Ajouter / retirer {something}',
      manySomething: 'Ajouter / retirer des {something}',
    },
    print: {
      action: 'Imprimer',
      title: 'Impression',
      something: 'Imprimer {something}',
    },
    render: {
      action: 'Aperçu',
      title: 'Aperçu',
    },
    load: {
      action: 'Charger',
      title: 'Chargement',
    },
    continue: {
      action: 'Continuer',
      title: 'Continuer',
    },
    choose: {
      action: 'Choisir',
      title: 'Choix',
      something: 'Choisir {something}',
      youSomething: 'Choisissez {something}',
    },
    chooseOrCreate: {
      action: 'Choisir ou créer',
      title: 'Choix ou Création',
      something: 'Choisir ou créer {something}',
      youSomething: 'Choisissez ou créez {something}',
    },
    resetPassword: {
      title: 'Réinitialisation du mot de passe',
      action: 'Générer un lien pour reinitialiser le mot de passe',
    },
    reset: {
      action: 'Réinitialiser',
      title: 'Réinitialisation',
      something: 'Réinitialiser {something}',
    },
    resetMyRole: {
      action: 'Reprendre mon rôle',
    },
    contact: {
      action: 'Contacter',
      title: 'Contact',
      something: 'Contacter {something}',
    },
    enter: {
      action: 'Entrer',
      something: 'Entrer {something}',
    },
    write: {
      action: 'Ecrire',
      title: 'Saisie',
      something: 'Saisissez {something}',
    },
    change: {
      action: 'Modifier',
      title: 'Modification',
      something: 'Modifier {something}',
    },
    switch: {
      action: 'Remplacer',
      title: 'Remplacement',
      something: 'Remplacer {something}',
    },
    saveAndDuplicate: {
      action: 'Sauvegarder et dupliquer',
    },
    send: {
      action: 'Envoyer',
      email: 'Envoyer un email',
      title: 'Envoi',
      toSomeone: 'Envoyer à {someone} | Envoyer aux {someone}',
    },
    credit: {
      company: 'Créditer l\'entreprise',
    },
    license: {
      company: 'Ajouter des licences à l\'entreprise',
    },
    back: {
      toSomewhere: 'Retour à {someone}',
      somewhere: 'Retourner {somewhere}',
      title: 'Retour',
    },
    accept: {
      action: 'Accepter',
    },
    connect: {
      action: 'Connecter',
      title: 'Connexion',
    },
    logIn: {
      action: 'Je me connecte',
    },
    open: {
      action: 'Ouvrir',
    },
    activate: {
      action: 'Déverrouiller',
    },
    active: {
      action: 'Activer',
    },
    desactivate: {
      action: 'Verrouiller',
    },
    seeStudentMessage: {
      on: 'Voir seulement les apprenants du groupe',
    },
    seeReferrersMessage: {
      on: 'Voir seulement les formateurs du groupe',
    },
    seeInvitationMessage: {
      on: 'Voir seulement les codes d\'invitation du groupe',
    },
    lessons: {
      openAll: 'Ouvrir toutes les séquences individuelles',
      closeAll: 'Fermer toutes les séquences individuelles',
    },
  },
  models: {
    AppFile: {
      title: 'Fichier | Fichiers',
    },
    LogBook: {
      title: 'Journal de bord | Journaux de bord',
      defined: 'le journal de bord | les journaux de bord',
      undefined: 'un journal de bord | des journaux de bord',
    },
    LogBookRecord: {
      parent: 'Journal de bord | Journaux de bord',
      title: 'Note | Notes',
      defined: 'la note | les notes',
      undefined: 'une note | des notes',
    },
    Parcours: {
      opening: {
        title: 'Ouverture | Ouvertures',
      },
      OPENING_MODES: {
        COMPLETE: {
          title: 'Ouverture par complétion',
          description: 'Ce type d\'ouverture permet l\'ouverture d\'un module lorsque le précédent est accompli par l\'apprenant.',
        },
        DATE: {
          title: 'Ouverture par date',
          description: `L'ouverture par date permet de définir une date d'ouverture et de fermeture sur chaque module.
          Si vous ne la définissez pas, la fermeture se fera automatiquement 1 an après`,
        },
        NONE: {
          title: 'Ouverture sans condition',
          description: 'L\'ouverture sans condition permet d\'ouvrir tous les modules de votre parcours maintenant, sans condition.',
        },
      },
    },
    CompanyInvitationCode: {
      title: 'Code d\'invitation | Codes d\'invitation',
      defined: 'le code d\'invitation | les codes d\'invitation',
      undefined: 'un code d\'invitation | des codes d\'invitation',
      to: 'de ce code d\'invitation | des codes d\'invitation',
      this: 'de ce code d\'invitation | de ces codes d\'invitation',
      alias: 'Code d\'invitation | Codes d\'invitation',
      description: 'Dans cet espace vous pouvez générer des codes d\'invitation que vous pourrez communiquer à vos utilisateurs pour qu\'ils puissent s\'enregistrer sur votre plateforme.',
    },
    Context: {
      title: 'Environnement | Environnements',
      undefined: 'un environnement | des environnements',
      defined: 'l\'environnement | les environnements',
      generic: {
        title: 'Générique',
        adj: 'génerique | génériques',
      },
    },
    library: {
      title: 'Bibliothèque',
      defined: 'la bibliothèque | les bibliothèques',
    },
    Job: {
      title: 'Tâche | Tâches',
      defined: 'la tâche | les tâches',
      undefined: 'une tâche | des tâches',
      to: 'de cette tâche | des tâches',
      this: 'de cette tâche | de ces tâches',
      types: {
        duplicateProgramsForContext: {
          title: 'Duplication de modules vers des environnements',
          icon: 'el-icon-fa-clipboard',
        },
      },
    },
    MultiBlock: {
      title: 'Grain aléatoire | Grains aléatoires',
      undefined: 'un grain aléatoire | des grains aléatoires',
      defined: 'le grain aléatoire | les grains aléatoires',
      this: 'ce grain aléatoire | ces grains aléatoires',
    },
    Certification: certification.models.Certification,
    CourseTerm: {
      title: 'Glossaire | Glossaires',
      defined: 'le glossaire | les glossaire',
      undefined: 'un mot | des mots',
      to: 'du glossaire | des glossaires',
      this: 'de ce glossaire | de ces glossaires',
      description: 'Le glossaire est le recueil des termes/mots que vous voulez expliciter dans vos Grains. Cela permet aux apprenants de retrouver les informations pour mener à bien leur Séquence.',
    },
    Course: {
      deleted: 'Séquence supprimée',
      title: 'Séquence | Séquences',
      defined: 'la Séquence | les Séquences',
      undefined: 'une Séquence | des Séquences',
      to: 'de la Séquence | des Séquences',
      this: 'de cette Séquence | de ces Séquences',
      thisOne: 'de la Séquence',
      description: 'Les séquences se composent d’un enchaînement de Grains (exercices, slides,&nbsp;ressources…). Elles&nbsp;sont le cœur des outils de suivi pédagogique.',
      duplicateToContext: {
        prompt: 'La duplication d\'une Séquence peut prendre beaucoup de temps, et surtout va entrainer la duplication de toutes les images et Grains ! Veuillez saisir le mot DUPLIQUER pour confirmer votre demande.',
        duplicating: 'Le temps nécessaire pour dupliquer une Séquence dans son intégrité, peut s\'avérer extrèmement long. Nous avons lancé le processus : il ne peut plus être arreté.',
        success: 'Séquence copiée',
      },
    },
    CourseBlock: {
      title: 'Grain | Grains',
      defined: 'le Grain | les grains',
      undefined: 'un Grain | des Grains | de Grains',
      to: 'du Grain | des Grains',
      this: 'de ce Grain | de ces Grains',
      description: 'Les Grains sont les plus petits éléments qui composent vos Séquences. Ils peuvent être des exercices, des informations, des Tincans ... Ils peuvent être associées en tant qu\'étape d\'une Séquence, mais aussi en tant que ressource documentaire.',
      duplicateToContext: {
        prompt: 'La duplication d\'un Grain peut prendre un peu de temps, et surtout va entrainer la duplication de toutes les images ! Veuillez saisir le mot DUPLIQUER pour confirmer votre demande.',
        duplicating: 'Le temps nécessaire pour dupliquer un Grain dans son intégrité, peut prendre un peu de temps. Nous avons lancé le processus : il ne peut plus être arreté.',
        success: 'Grain copié',
      },
      Types: {
        slide: 'Slide',
        exercice: 'Exercice',
        tincan: 'Tincan',
      },
    },
    CourseCompetency: {
      title: 'Compétence | Compétences',
      defined: 'la compétence | les compétences',
      undefined: 'une compétence | des compétences',
      to: 'de la compétence | des compétences',
      this: 'de cette compétence | de ces compétences',
      none: 'Aucune compétence',
      description: 'Les compétences permettent de catégoriser des savoirs qui seront transverses à vos Séquences et thèmes.',
    },
    CourseCategory: {
      title: 'Thème | Thèmes',
      defined: 'le thème | les Thèmes',
      undefined: 'un thème | des Thèmes',
      to: 'du thème | des Thèmes',
      this: 'de ce thème | de ces Thèmes',
      none: 'Aucun thème',
      description: 'Les thèmes vous permettront de catégoriser vos Séquences, et vos Grains.',
    },
    Product: {
      title: 'Offre | Offres',
      defined: 'l\'offre | les Offres',
      undefined: 'une offre | des Offres',
      to: 'de l\'offre | des Offres',
      this: 'de cette offre | de ces Offres',
      none: 'Aucune offre',
    },
    CourseType: {
      title: 'Type de Séquence | Types de Séquence',
      defined: 'le type | les types',
      undefined: 'un type | des types',
      to: 'du type | des types',
      this: 'de ce type | de ces types',
    },
    AppUser: {
      title: 'Utilisateur | Utilisateurs',
      defined: 'l\'utilisateur | les utilisateurs',
      undefined: 'un utilisateur | des utilisateurs',
      to: 'de l\'utilisateur | des utilisateurs',
      this: 'de cet utilisateur | de ces utilisateurs',
    },
    Company: {
      title: 'Entreprise | Entreprises',
      defined: 'l\'entreprise | les entreprises',
      undefined: 'une entreprise | des entreprises',
      to: 'de l\'entreprise | des entreprises',
      this: 'de cette entreprise | de ces entreprises',
    },
    CompanyUser: {
      title: 'Lien | Liens',
      defined: 'le lien | les liens',
      undefined: 'un lien | des liens',
      to: 'de ce lien | des liens',
      this: 'de ce lien | de ces liens',
    },
    CompanyUserGroup: {
      title: 'Groupe | Groupes',
      defined: 'le groupe | les groupes',
      at: 'au groupe | aux groupes',
      my: 'mon groupe | mes groupes',
      undefined: 'un groupe | des groupes',
      to: 'de ce groupe | des groupes',
      this: 'de ce groupe | de ces groupes',
      description: 'Les groupes vous permettront de faciliter la gestion de vos apprenants. Vous pouvez créer vos parcours directement à partir d\'un groupe, ou contacter l\'ensemble de celui-ci.',
    },
    Class: {
      title: 'Parcours | Parcours',
      defined: 'le parcours | les parcours',
      undefined: 'un parcours | des parcours',
      to: 'de ce parcours | des parcours',
      this: 'de ce parcours | de ces parcours',
      inThis: 'dans ce parcours | dans ces parcours',
      addThisProgram: 'Ajouter le module "{name}" au parcours',
      removeThisProgram: 'Retirer "{name}" du parcours',
      all: 'Tout le parcours | Tous les parcours',
      draft: 'brouillon | brouillons',
      states: {
        DRAFT: {
          label: 'Brouillon',
          type: 'primary',
        },
        EXPIRED: {
          label: 'Expirée',
          type: 'danger',
        },
        OPEN: {
          label: 'Ouverte',
          type: 'success',
        },
        PENDING: {
          label: 'En attente',
          type: 'warning',
        },
      },
    },
    Program: {
      title: 'Module | Modules',
      defined: 'le module | les modules',
      undefined: 'un module | des modules',
      to: 'de ce module | des modules',
      this: 'de ce module | de ces modules',
      thisOne: 'du Module',
      alias: 'Module | Modules',
      description: 'Les Modules sont des scénarios se composant de plusieurs Séquences. Il vous sera possible de définir des règles pour permettre aux utilisateurs de suivre des cheminement conditionnés aux scores ou par des questions.',
      duplicateToContext: {
        prompt: 'La duplication d\'un module peut prendre beaucoup de temps, et surtout va entrainer la duplication de toutes les images, grains et séquences ! Veuillez saisir le mot DUPLIQUER pour confirmer votre demande.',
        duplicating: 'Le temps nécessaire pour dupliquer une Séquence dans son intégrité, peut s\'avérer extrèmement long. Nous avons lancé le processus : il ne peut plus être arreté.',
        success: 'Module copié',
        warning: 'Le processus de duplication nécessite beaucoup de temps. Il sera lancée en arrière plan, vous pourrez voir la progression en haut à droite de votre écran.',
      },
      shareToContext: {
        warning: 'Cette action rendra les modules privés et ajoutera les environnements choisis à leur liste de partage.',
      },
      shareToContexts: {
        success: 'Modules partagés',
      },
    },
    ProgramCourse: {
      errors: {
        seemsDeteled: 'La séquence associée est supprimée',
      },
    },
    Review: {
      title: 'Commentaire | Commentaires',
      defined: 'le commentaire | les commentaires',
      undefined: 'un commentaire | des commentaires',
      to: 'de ce commentaire | des commentaires',
      this: 'de ce commentaire | de ces commentaires',
      thisOne: 'du Commentaire',
      alias: 'Commentaire | Commentaires',
    },
  },
  helpers: {
    AppUser: {
      donePageCount: 'Exercices réalisés',
    },
    CourseBlock: {
      flag: 'Sélectionnez un ou plusieurs tags informant de l\'état du Grain',
      type: 'Détermine à quoi servira ce Grain. Après sélection, cliquer sur Déverrouiller pour modifier',
      render: 'Affiche un aperçu du Grain',
    },
    wysiwygTable: {
      title: 'Tableau',
      undefined: 'un tableau | des tableaux',
    },
    [EXERCICES.QCM]: {
      reponse: 'Sélectionnez les réponses justes',
      option: 'Options d\'affichage',
      columns: 'Choix du nombre de colonnes pour l\'affichage des questions',
      showDescriptionIfImage: 'Afficher les descriptions après les images',
      returnToLineAfterImage: 'Retour à la ligne entre l\'image et la description',
      keepOrder: 'Conserver l\'ordre des réponses pour tout le monde',
    },
    [EXERCICES.SELECT]: {
      reponse: 'Sélectionnez la réponse juste',
    },
    [EXERCICES.INPUT]: {
      option: 'Sélectionnez les filtres à appliquer sur les saisies du participant',
    },
    [EXERCICES.DND]: {
      question: 'Sélectionnez l\'élément qui sera à déposer dans cette zone',
    },
    [EXERCICES.PICK_WORDS]: {
      reponse: 'Ensemble des réponses sélectionnées dans l\'énoncé',
      option: 'Options d\'affichage',
      questionIsHidden: 'Cochez la case pour masquer l\'énoncé et la consigne',
    },
    Course: {
      add: 'Sélectionnez le type d élément que vous souhaitez ajouter',
    },
    Class: {
      typeEmail: 'Saississez une adresse mail',
      userClass: 'Afficher uniquement mes parcours de formation',
      addedToClass: "L'utilisateur a bien été ajouté au parcours",
      companySelectionIsRequired: 'Vous devez sélectionner une entreprise pour continuer.',
      programSelectionIsRequired: 'Vous devez ajouter au moins un module à ce parcours.',
      studentSelectionIsRequired: 'Vous devez sélectionner des apprenants pour effectuer cette action.',
      resetUser: 'Réinitialiser tout le parcours pour cet apprenant',
      resetUsers: 'Réinitialiser tout le parcours pour tous les apprenants',
    },
    ProgramClass: {
      resetUser: 'Réinitialisez le module pour cet apprenant',
      resetUsers: 'Réinitialisez le module pour tous les apprenants',
    },
    Program: {
      switchToHierarchicalDisplay: 'Affichage espacé',
      switchToCompressedDisplay: 'Affichage compressé',
      displayAsGrid: 'Affichage en grille',
      displayAsList: 'Affichage complet en liste',
      orderByLeftRight: 'Ordonner les séquences de gauche à droite (puis de haut en bas)',
      orderByTopBottom: 'Ordonner les séquences de haut en bas (puis de gauche à droite)',
    },
    Lesson: {
      openOnlyByCondition: 'Cette séquence s\'ouvre automatiquement en fonction des résultats obtenus dans une autre séquence',
      openOnlyByAnotherProgramClass: 'Des modules doivent être préalablement terminés pour ouvrir cette séquence',
      soon: 'La date de début n\'est pas atteinte',
    },
    hideTitle: 'Si la case est cochée, le titre ne sera pas affiché',
    hideDescription: 'Si la case est cochée, la description ne sera pas affichée',
    autoplay: 'Si la case est cochée, la lecture du fichier se lancera automatiquement',
    beforeStatement: 'Si la case est cochée, cet élément se retrouvera au-dessus de l\'énoncé',
    showPi: 'Si la case est cochée, le widget affichera l\'indicateur de niveau Pi',
    showScore: 'Si la case est cochée, le widget affichera le score de la Séquence',
    constrain: 'Si la case est cochée, le PDF cherchera à être entièrement visible',
  },
  words: {
    emargementSheet: {
      title: 'Feuille d\'émargement | Feuilles d\'émargement',
      undefined: 'une feuille d\'émargement | des feuilles d\'émargement',
      none: 'aucune feuille d\'émargement',
      defined: 'la feuille d\'émargement | les  feuilles d\'émargement',
    },
    all: {
      title: 'Tous',
    },
    document: {
      title: 'Document | Documents',
      undefined: 'un document | des documents',
      my: 'mon document | mes documents',
      none: 'aucun document',
    },
    resource: {
      title: 'Ressource | Ressources',
      undefined: 'une ressource | des ressources',
      more: 'ressources supplémentaires',
    },
    section: {
      title: 'Section | Sections',
      defined: 'la section | les sections',
    },
    calculator: {
      title: 'Calculatrice | Calculatrices',
    },
    spreadsheet: {
      title: 'Tableur | Tableurs',
    },
    state: {
      title: 'État | États',
    },
    none: {
      title: 'Aucun | Aucune',
    },
    minute: {
      title: 'Minute | Minutes',
      short: 'min',
    },
    point: {
      title: 'Point | Points',
      short: 'pts',
    },
    detail: {
      title: 'Détail | Détails',
    },
    free: 'gratuit',
    notFree: 'payant',
    table: {
      title: 'Tableau | Tableaux',
    },
    tree: {
      title: 'Arbre | Arbres',
    },
    level: {
      title: 'Niveau | Niveaux',
      global: 'Global',
    },
    progress: {
      title: 'Progression | Progressions',
    },
    statistics: {
      title: 'Statistique | Statistiques',
    },
    activity: {
      title: 'Activité | Activités',
    },
    cgu: {
      title: 'Conditions générales d\'utilisation',
    },
    cgv: {
      title: 'Conditions générales de vente',
    },
    score: {
      title: 'Score | Scores',
      average: 'Score moyen | Scores moyens',
      maximal: 'Score maximal | Scores maximaux',
    },
    time: {
      title: 'Temps',
      second: 'seconde | secondes',
      minute: 'minute | minutes',
      hour: 'heure | heures',
      day: 'jour | jours',
      week: 'semaine | semaines',
      month: 'mois | mois',
      year: 'an | ans',
    },
    duration_recurcivity: {
      year: 'annuel | tous les {count} ans',
      month: 'mensuel | tous les {count} mois',
      week: 'hebdomadaire | tous les {count} semaines',
      day: 'quotidien | tous les {count} jours',
    },
    payment: {
      label: 'paiement',
      during: 'pendant',
      immediate: 'En une fois',
    },
  },
  routes: {
    Dashboard: 'Tableau de bord',

    Account: 'Mon profil',
    UserList: 'Utilisateurs',
    AdminList: 'Administrateurs',
    CertifierList: certification.routes.CertifierList,
    TeacherList: 'Animateurs',
    StudentList: 'Participants',
    UserEdit: 'Utilisateurs - Édition',
    UserCreate: 'Utilisateurs - Création',

    ClassList: 'Parcours',
    ClassCertificationList: certification.routes.ClassCertificationList,
    ClassEdit: 'Parcours - Édition',
    ClassCreate: 'Parcours - Création',
    CreateClass: 'Parcours - Création',
    CreateCertification: 'Parcours - Création',

    CompanyList: 'Entreprises',
    MyCompany: 'Mon entreprise',
    CompanyEdit: 'Entreprises - Édition',
    CompanyCreate: 'Entreprises - Création',

    CompetencyList: 'Compétences mathématiques',
    CompetencyEdit: 'Compétences mathématiques - Édition',
    CompetencyCreate: 'Compétences mathématiques - Création',

    CourseBlockList: 'Grains',
    CourseBlockEdit: 'Grains - Édition',
    CourseBlockCreate: 'Grains - Création',

    CourseList: 'Séquences',
    CourseEdit: 'Séquence - Édition',
    CourseCreate: 'Séquence - Création',

    TermList: 'Glossaire',
    TermEdit: 'Glossaire - Édition',
    TermCreate: 'Glossaire - Création',

    TypeList: 'Types de Séquence',
    TypeEdit: 'Types de Séquence - Édition',
    TypeCreate: 'Types de Séquence - Création',

    JobList: 'Thèmes',
    JobEdit: 'Thèmes - Édition',
    JobCreate: 'Thèmes - Création',

    ProgramGrid: 'Modules',
    ProgramCertificationGrid: certification.routes.ProgramCertificationGrid,
    ProgramList: 'Modules',
    ProgramEdit: 'Module - Édition',
    ProgramCreate: 'Module - Création',
    ProgramDetails: 'Fiche Module',

    Help: 'Aide',

    Consumption: 'Ma consommation',
  },
  roles: {
    TEACHER: {
      icon: 'el-icon-pi-animateurs',
      title: 'Animateur | Animateurs',
      undefined: 'un animateur | des animateurs',
      defined: 'l\'animateur | les animateurs',
      none: 'aucun animateur',
      this: 'cet animateur | ces animateurs',
      detail: 'Peut encadrer des parcours de formation au nom d\'une entreprise',
      introduction: 'Les animateurs créent des parcours de formation en choisissant les modules, ajoutent et affectent les participants, pilotent l\'ouverture des modules et suivent les résultats détaillés à travers leurs tableaux de bord.',
    },
    SUPERVISOR: {
      icon: 'el-icon-pi-animateurs',
      title: 'Observateur | Observateurs',
      undefined: 'un observateur | des observateurs',
      defined: 'l\'observateur | les observateurs',
      none: 'aucun observateur',
      this: 'cet observateur | ces observateurs',
      detail: 'Peut superviser des parcours de formation au sein d\'une entreprise',
      introduction: 'Les observateurs peuvent consulter les parcours de formation et les listes des apprenants et des formateurs.',
    },
    STUDENT: {
      icon: 'el-icon-pi-participants',
      title: 'Participant | Participants',
      undefined: 'un participant | des participants',
      defined: 'le participant | les participants',
      none: 'aucun participant',
      this: 'ce participant | ces participants',
      detail: 'Peut, ou a déjà participé à des parcours de formation pour cette entreprise',
      introduction: 'Les participants ont accès aux parcours de formation dans lesquels ils sont inscrits par les animateurs. Ils peuvent réaliser des modules, voir leurs scores, leurs résultats et corrigés. Ils peuvent télécharger ou s\'envoyer par mail l\'ensemble des ressources mises à leur disposition.',
    },
    OWNER: {
      icon: 'el-icon-pi-administrateurs',
      title: 'Propriétaire | Propriétaires',
      defined: 'le propriétaire | les propriétaires',
      this: 'ce propriétaire | ces propriétaires',
      detail: 'Propriétaire du compte de la société, il est le référent de l\'entreprise',
    },
    ADMIN: {
      undefined: 'un administrateur | des administrateurs',
      defined: 'l\'administrateur | les administrateurs',
      icon: 'el-icon-pi-administrateurs',
      title: 'Administrateur | Administrateurs',
      this: 'cet administrateur | ces administrateurs',
      detail: 'Possède les mêmes pouvoirs que le propiétaire du compte de l\'entreprise.',
      introduction: 'Les administrateurs créent ou suppriment des profils animateurs et participants, ils ont accès à la gestion des crédits pour leur organisation.',
    },
    CERTIFIER: certification.roles.CERTIFIER,
  },
  contactGroups: {
    SUPPORT: {
      title: 'Équipe de support',
    },
    CONTRIBUTION: {
      title: 'Contribution',
    },
  },
  flags: {
    progress: {
      NONE: {
        color: 'danger',
        message: 'Pas commencé', // (NONE)
      },
      COMPLETED: {
        color: 'success',
        message: 'Terminé', // (COMPLETED)
      },
      ONGOING: {
        color: 'warning',
        message: 'Commencé', // (ONGOING)
      },
    },

    codeStatus: {
      AVAILABLE: {
        color: 'success',
        message: 'Disponible',
      },
      USED: {
        color: 'primary',
        message: 'Activé',
      },
      FAILED: {
        color: 'danger',
        message: 'Échec',
      },
      EXPORTED: {
        color: 'info',
        message: 'Imprimé',
      },
    },

    state: {
      HIDDEN: {
        color: 'grey',
        message: 'Pas encore ouvert', // (HIDDEN)
      },
      LOCKED: {
        color: 'grey',
        message: 'Verrouillé', // (LOCKED)
      },
      SKIPPED: {
        color: 'grey',
        message: 'Temps écoulé', // (SKIPPED)
      },
      AVAILABLE: {
        color: 'primary',
        message: 'Disponible', // (AVAILABLE)
      },
      BOTH: {
        color: 'grey',
        message: 'Partiellement activé',
      },
      DRAFT: {
        color: 'warning',
        message: 'Brouillon',
      },
      PUBLISHED: {
        color: 'success',
        message: 'Publié',
      },
      CLOSED: {
        color: 'danger',
        message: 'Fermé',
      },
    },
  },
  errors: {
    UNIQUE_USERNAME_OR_EMAIL: {
      title: 'Utilisateur déjà enregistré',
      message: 'L\'adresse utilisée ou l\'identifiant est déjà enregistré sur ce site',
    },
    STORAGE_ERROR: {
      title: 'Enregistrement impossible',
      message: 'Une erreur est survenue lors du stockage de données',
    },
    USER_NOT_FOUND: {
      title: 'Utilisateur non trouvé',
      message: 'Aucun utilisateur avec le role demandé à été trouvé',
    },
    COMPANY_ID_MANDATORY: {
      title: 'Société requise',
      message: 'Pour mener à bien votre demande, une société est requise.',
    },
    FORBIDDEN: {
      title: 'Accès non autorisé',
      message: 'Vous n\'avez pas les permissions nécessaires pour accéder à ce contenu',
    },
    UNDEFINED: {
      title: 'Une erreur est survenue',
      message: 'Nous n\'avons pas pu identifier l\'erreur que vous rencontrez.',
    },
    ERROR_NODE_DUPLICATION: {
      title: 'Erreur lors de la duplication',
      message: 'Un ou plusieurs grains de la Séquence n\'ont pas pu être dupliqués, bloquant ainsi votre demande de duplication de Séquence.',
    },
    ERROR_COURSE_DUPLICATION: {
      title: 'Erreur lors de la duplication',
      message: 'Un ou plusieurs grains de la Séquence n\'ont pas pu être dupliqués, bloquant ainsi votre demande de duplication de Séquence.',
    },
    ERROR_PROGRAM_DUPLICATION: {
      title: 'Erreur lors de la duplication',
      message: 'Une ou plusieurs Séquences n\'ont pas pu être dupliquées, bloquant ainsi votre demande de duplication du module. Il se peut que des séquences ou grains soient manquants.',
    },
    UNAUTHORIZED_PARAMETER: 'Certaines données ne doivent pas être modifiées et n\'ont pas pu être enregistrées',
    404: {
      message: 'Ressource non trouvée',
    },
    default: {
      title: 'Une erreur est survenue',
    },
    uniqueness: 'Erreur de doublon.',
    PayloadTooLargeError: {
      title: 'Taille totale des fichiers trop importante',
    },
    ALREADY_INVITED: 'Utilisateur déjà invité',
    ALREADY_REGISTED: {
      title: 'Utilisateur déjà enregistré',
      message: 'L\'adresse utilisée est déjà enregistré sur ce site',
    },
    CONDITIONAL_SECTION_UNLOCK: {
      title: 'Ouverture impossible',
      message: 'La Séquence est conçue pour que les sections s\'ouvrent automatiquement en fonction des résultats de l\'apprenant',
    },
    CONDITIONAL_LESSON_STATE: {
      title: 'Ouverture impossible',
      message: 'Cette Séquence s\'activera et se déverrouillera automatiquement en fonction du score de l\'apprenant sur une autre Séquence',
    },
    AUTHORIZATION_REQUIRED: {
      title: 'Accès interdit',
      message: 'Cette action ne vous est pas permise pour des raisons de sécurité',
    },
    NO_WALLET_AVAILABLE: {
      title: 'Les crédits sont insuffisants.',
      message: 'Aucun portefeuille n\'est affecté à ce compte.',
    },
    BAD_REQUEST: {
      title: 'Les données envoyées sont incorrectes.',
    },
    UNSUFFICIENT_CREDIT_BALANCE: {
      title: 'Les crédits sont insuffisants.',
      message: 'Il est nécessaire de réapprovisionner vos crédits pour ouvrir ce parcours. Nous vous invitons à contacter la personne responsable du compte de votre société.',
    },
    error: 'Erreur',
    AlreadyInvited: {
      title: 'Déjà invité',
      message: 'Cet utilisateur existe déjà',
    },
    userAlreadyExists: 'Un utilisateur utilise déjà ce pseudonyme',
    emailAlreadyExists: 'Un utilisateur utilise déjà cette adresse email',
    custom: {
      email: 'Adresse e-mail invalide',
    },
    NO_USED_CURRENCY: {
      message: 'Aucun mode d\'achat (Crédit / License) n\'a été sélectionné',
      failed: 'Il n\'y avait pas assez de licence ou crédit pour déverrouiller le parcours lors de l\'ajout de cet utilisateur. Une fois votre compte réapprovisionné, cliquez sur le détail du parcours de cet utilisateur.',
    },
    UNSUFFICIENT_LICENSE_BALANCE: {
      title: 'Les licences sont insuffisantes.',
      message: 'Il est nécessaire de réapprovisionner vos licences pour ouvrir ce parcours. Nous vous invitons à contacter la personne responsable du compte de votre société.',
    },
    PRESENCE: {
      title: 'Des données sont manquantes.',
      message: 'Des champs ou données sont manquantes afin de pouvoir executer votre demande.',
    },
    TYPE_ERROR: {
      title: 'Une erreur technique a interrompu cette opération',
    },
  },
  transaction: {
    ACTION_AFTER_PURCHASE_OF_CREDIT: 'Achat de crédits',
    ACTIVATION_LESSON_CLASS: 'Activation d\'une Séquence pour un participant',
    state: {
      initial: 'Créée',
      pending: 'En cours',
      applied: 'Appliquée',
      done: 'Réussie',
      canceling: 'En cours d\'annulation',
      canceled: 'Annulée',
    },
    type: {
      manual: 'Transaction de l\'administrateur',
      paid: 'Achat dans l\'administration',
      refund: 'Remboursement dans l\'administration',
      consumed: 'Parcours',
    },
  },
  navigation: {
    StudentAndGroup: 'Participant et groupe | Participants et groupes',
  },
}
