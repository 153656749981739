<script>
import PiContactUser from '@/components/Contact/User.vue'
import api from '@/api'

export default {
  name: 'pi-contact-company',
  extends: PiContactUser,
  data () {
    return {
      availableRoles: api.getSchema('CompanyUser').properties.role.enum,
      rolesRule: {
        required: true,
        message: this.$t('actions.choose.youSomething',
          { something: this.$tc('attributes.role.undefined', 1) },
        ),
        validator: (rule, value, callback) => {
          if (!(value && value.length > 0)) {
            return callback(new Error(''))
          }
          callback()
        },
        trigger: 'change',
      },
    }
  },
  computed: {
    rules () {
      return {
        message: this.messageRule,
        roles: this.rolesRule,
      }
    },
    company () {
      return api.get('Company', this.companyId)
    },
    submitLabel () {
      if (!this.isVisible) {
        return ''
      }
      if (this.form.roles.length === 0) {
        return this.$t('actions.send.action')
      }
      const someone = this.form.roles
        .map(role => this.$tc(`roles.${role}.title`, 2))
        .join(', ')
        .toLocaleLowerCase()
      return this.$tc('actions.send.toSomeone', 2, { someone })
    },
    title () {
      if (!this.isVisible) {
        return ''
      }
      const something = this.form.roles
        .map(role => this.$tc(`roles.${role}.defined`, 2))
        .join(', ')
        .toLocaleLowerCase()
      const msg = this.$t('actions.contact.something', { something })
      return `${this.company.name} : ${msg}`
    },
    isVisible: {
      get () {
        const isVisible = Boolean(this.companyId)
        if (isVisible) {
          this.resetDatas()
        }
        return isVisible
      },
      set (value) {
        if (!value) {
          this.$set(this.form, 'roles', [])
          this.$store.commit('CONTACTFORM_HIDE')
        }
      },
    },
    companyId () {
      return this.$store.getters.contactCompanyId
    },
  },
  methods: {
    submit () {
      this.resetAlerts()
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          this.isSending = true
          this.company.contact(this.form.message, this.form.receiveCopy, this.object, this.form.roles, this.attachments)
            .then(data => {
              this.channelContactUsers_add({
                title: 'Message envoyé',
                type: 'success',
              })
            })
            .catch(err => {
              const errors = this._get(err, 'response.data.errors', [])
              for (const i in errors) {
                const code = errors[i].code || errors[i].title
                this.channelContactUsers_add({
                  title: this.$t(`errors.${code}.title`),
                  type: 'error',
                })
              }
            }).then(() => {
              this.isSending = false
            })
        } else {
          this.channelContactUsers_add({
            title: 'Des données sont manquantes pour satisfaire votre requète',
            type: 'error',
          })
        }
      })
    },
  },
}
</script>
