import { get, isEmpty, map, uniq } from 'lodash'
export default {
  data: () => ({}),
  filters: {},
  methods: {
    getPiScoreRatio (datas, on = null) {
      if (isEmpty(this.competencies)) return 0
      const ids = uniq(map(this.competencies, 'id'))
      const values = map(ids, id => {
        const stats = get(datas, ['competencies', id])
        const out = this.getRatio(stats, on)
        return out
      })
      const ratio = this._mean(values)
      return ratio > 0 ? ratio : 0
    },
    getMedalAccomplished (ratio) {
      if (ratio === 0) {
        return ''
      } else if (ratio < 0.50) {
        return (ratio - 0.0) * (1 / (0.5 - 0.0))
      } else if (ratio < 0.75) {
        return (ratio - 0.5) * (1 / (0.75 - 0.5))
      }
      return (ratio - 0.75) * (1 / (1 - 0.75))
    },
    getMedal (ratio) {
      if (ratio === 0) {
        return ''
      } else if (ratio < 0.50) {
        return 'bronze'
      } else if (ratio < 0.75) {
        return 'argent'
      }
      return 'or'
    },
    getLessonAverage (scores) {
      if (isEmpty(scores.global) || !get(scores, 'global.max')) return 0
      const global = get(scores, 'global')
      return global.actual / global.max
    },
  },
}
