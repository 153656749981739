import { CURRENCIES } from '@/api/models/Wallet'

const Credits = () => import('./Credits.vue')
const CreditsConsommation = () => import('./Consommation/Consommation.vue')
const CreditsInvoices = () => import('./Invoices/Invoices.vue')

const Buy = () => import('./Buy/Buy.vue')
const BuyContact = () => import('./Buy/Contact/Contact.vue')
const BuyCatalog = () => import('./Buy/Catalog/Catalog.vue')
const BuyPayment = () => import('./Buy/Payment/Payment.vue')

const TransactionVerify = () => import('./Transactions/Verify.vue')

const routes = [
  {
    path: '/credits',
    name: 'Credits',
    meta: { breadcrumb: 'routes.Consumption', fail: '/', permission: 'client_ADMIN|client_OWNER|grains' },
    redirect: '/credits/consommation',
    component: Credits,
    children: [
      {
        path: 'consommation',
        name: 'CreditsConsommation',
        meta: { breadcrumb: 'routes.Consumption', fail: '/', permission: 'client_ADMIN|client_OWNER|grains' },
        component: CreditsConsommation,
      }, {
        path: 'invoices',
        name: 'CreditsInvoices',
        meta: { breadcrumb: 'Mes factures', fail: '/', permission: 'client|grains' },
        component: CreditsInvoices,
      },
    ],
  },
]
routes.unshift({
  path: '/transaction/:id/verify',
  name: 'TransactionVerify',
  props: true,
  meta: { breadcrumb: '', fail: '/', permission: 'client|grains' },
  component: TransactionVerify,
})

routes.unshift({
  path: '/buy',
  name: 'Buy',
  meta: { breadcrumb: (route) => `context.${route.query.currency || CURRENCIES.CREDIT}.addMany`, fail: '/', permission: 'client|grains' },
  redirect: '/buy/catalog',
  component: Buy,
  props: (route) => ({ currency: route.query.currency }),
  children: [
    {
      path: 'catalog',
      name: 'BuyCatalog',
      meta: { breadcrumb: (route) => `context.${route.query.currency || CURRENCIES.CREDIT}.addMany`, fail: '/', permission: 'client|grains' },
      component: BuyCatalog,
    }, {
      path: 'contact',
      name: 'BuyContact',
      meta: { breadcrumb: (route) => `context.${route.query.currency || CURRENCIES.CREDIT}.addMany`, fail: '/', permission: 'client|grains' },
      component: BuyContact,
    }, {
      path: 'payment',
      name: 'BuyPayment',
      meta: { breadcrumb: (route) => `context.${route.query.currency || CURRENCIES.CREDIT}.addMany`, fail: '/', permission: 'client|grains' },
      component: BuyPayment,
    },
  ],
})

export default routes
