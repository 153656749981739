import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import plugins from './plugins'
import navigation from './modules/navigation'
import notificationManager from './modules/notificationManager'
import authentification from './modules/authentification'
import contact from './modules/contact'
import session from './modules/session'
import credits from './modules/credits'
import view from './modules/view'
import optionsInterface from './modules/optionsInterface'
import seeCorrectionLessonPage from './modules/seeCorrectionLessonPage'
import requiredDatas from './modules/requiredDatas'
import intro from './modules/intro'
import alertsManager from './modules/alertsManager'
import cart from './modules/cart'
import context from './modules/context'
import actor from './modules/actor'

Vue.use(Vuex)
const store = new Vuex.Store({
  actions,
  plugins,
  modules: {
    navigation,
    notificationManager,
    authentification,
    contact,
    session,
    view,
    credits,
    optionsInterface,
    seeCorrectionLessonPage,
    requiredDatas,
    intro,
    alertsManager,
    cart,
    context,
    actor,
  },
  strict: false,
})

export default store
