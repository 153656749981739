import Vue from 'vue'
import { forOwn, get, isEmpty, isUndefined } from 'lodash'
import axios from 'axios'
const state = () => ({
  intro: null,
  hints: {},
})

// getters
const getters = {
  intro: (state) => {
    return state.intro()
  },
  hints: state => context => {
    return state.hints[context]
  },
  hasHints: state => context => {
    return !isEmpty(state.hints[context])
  },
}
// actions
const actions = {
  load ({ commit, dispatch, state, rootState }, key) {
    const contextId = get(rootState, ['context', 'id'])
    if (!isUndefined(state.hints[key])) {
      return state.hints[key]
    }
    return axios.get(`${get(rootState, ['DS', 'Context', contextId, 'metas', 'externalReferenceWebSiteLink']) + key}.json`).then(({ data }) => {
      commit('SET_HINTS', { hints: data, context: key })
      return state.hints[key]
    }).catch(e => {
      // silent
      e
    })
  },
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  INSTALL_INTRO_JS (state, obj) {
    state.intro = obj
  },
  SET_HINTS (state, { hints, context }) {
    Vue.set(state.hints, context, hints)
  },
  REMOVE_HINTS (state) {
    state.hints = {}
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
