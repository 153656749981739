<template lang="pug">
  em.lesson-no-score
    small Non évalué

</template>
<script>
export default {
  name: 'lesson-no-score',
  data: () => ({}),
}
</script>
