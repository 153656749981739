export function getQueryStringAsObject () {
  let b
  let cv
  let e
  let k
  let sk
  let v
  const r = {}
  const d = function (v) {
    return decodeURIComponent(v).replace(/\+/g, ' ')
  }
  const q = window.location.search.substring(1)
  const s = /([^&;=]+)=?([^&;]*)/g
  const ma = function (v) {
    if (typeof v !== 'object') {
      cv = v
      v = {}
      v.length = 0
      if (cv) {
        Array.prototype.push.call(v, cv)
      }
    }
    return v
  }
  while (e = s.exec(q)) { // eslint-disable-line no-cond-assign
    b = e[1].indexOf('[')
    v = d(e[2])
    if (b < 0) {
      k = d(e[1])
      if (r[k]) {
        r[k] = ma(r[k])
        Array.prototype.push.call(r[k], v)
      } else {
        r[k] = v
      }
    } else {
      k = d(e[1].slice(0, b))
      sk = d(e[1].slice(b + 1, e[1].indexOf(']', b)))
      r[k] = ma(r[k])
      if (sk) {
        r[k][sk] = v
      } else {
        Array.prototype.push.call(r[k], v)
      }
    }
  }
  return r
}
