<template lang="pug">
  component.multiline.large-button(
    :is="componentType"
    :href="href",
    :rel="rel"
    :target="target"
    :type="type"
    :plain="plain"
    @click='clickHandler'
    :style="visuelStyle"
    )
    .display-inline-flex.flex-direction-row.align-items-center
      i.ts-1-5.medium-margin-right(v-if="icon", :class="icon")
      .text-align-left
        slot

</template>
<script>
export default {
  name: 'large-button',
  props: {
    type: {
      type: String,
      default: 'secondary',
    },
    plain: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    image: {
      type: String,
    },
    href: {
      type: String,
    },
  },
  data: () => ({}),
  computed: {
    rel () {
      return this.href ? 'noopener' : undefined
    },
    target () {
      return this.href ? '_blank' : undefined
    },
    componentType () {
      return 'el-button'
      // return !this.href ? 'div' : 'a'
    },
    visuelStyle () {
      return {
        'background-image': this.image ? `url(${this.image})` : false,
      }
    },
  },
  methods: {
    clickHandler () {
      if (this.href) {
        window.open(this.href, this.target, this.rel)
      }
      this.$emit('click')
    },
  },
}
</script>
<style lang="sass">
.large-button
  font-size: 1.075em
  &, &:hover
    background-repeat: no-repeat !important
    background-size: auto 70% !important
    background-position: 90% center !important
@media screen and (max-width: 1152px)
  .large-button
    background-image: none !important
</style>
