export const ROLES = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  CERTIFIER: 'CERTIFIER',
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
  SUPERVISOR: 'SUPERVISOR',
}

export const EVERY_ROLES_WHO_CAN_BE = {
  STUDENT: [
    ROLES.STUDENT,
    ROLES.TEACHER,
    ROLES.SUPERVISOR,
    ROLES.ADMIN,
    ROLES.OWNER,
  ],
  SUPERVISOR: [
    ROLES.SUPERVISOR,
    ROLES.ADMIN,
    ROLES.OWNER,
    ROLES.TEACHER,
  ],
  TEACHER: [
    ROLES.TEACHER,
    ROLES.ADMIN,
    ROLES.OWNER,
  ],
  CERTIFIER: [
    ROLES.CERTIFIER,
  ],
  ADMIN: [
    ROLES.ADMIN,
    ROLES.OWNER,
  ],
  OWNER: [
    ROLES.OWNER,
  ],
}

export const EVERY_ROLES = Object.values(ROLES)
