const JobsList = () => import('./List.vue')
const JobsEdit = () => import('./Edit.vue')

const routes = [
  { path: '/job', name: 'JobList', component: JobsList },
  { path: '/job/edit', name: 'JobCreate', component: JobsEdit },
  { path: '/job/edit/:id', name: 'JobEdit', component: JobsEdit, props: true },
].map(r => {
  r.meta = {
    permission: 'grains|context_CONTRIBUTOR',
    fail: '/',
  }
  return r
})

export default routes
