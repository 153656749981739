import { forOwn } from 'lodash'

const state = () => ({
  usersId: [],
  teacherIds: [],
  supervisorIds: [],
  programIds: [],
  companyId: null,
  groupId: null,
  properties: {},
})

// getters
const getters = {
  classWizardCompanyId  (state) {
    return state.companyId
  },
  classWizardProperties  (state) {
    return state.properties
  },
  classWizardGroupId  (state) {
    return state.groupId
  },
  classWizardProgramIds  (state) {
    return state.programIds
  },
  usersSelectedForClassCreation (state) {
    return state.usersId
  },
  teachersSelectedForClassCreation (state) {
    return state.teacherIds
  },
  supervisorsSelectedForClassCreation (state) {
    return state.supervisorIds
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SESSION_WIZARD_COMPANY (state, companyId) {
    state.companyId = companyId
  },
  SESSION_WIZARD_PROPERTIES (state, properties) {
    state.properties = properties
  },
  SESSION_WIZARD_HIDE (state) {
    state.usersId = []
    state.teacherIds = []
    state.supervisorIds = []
    state.programIds = []
    state.companyId = null
    state.groupId = null
    state.properties = {}
  },
  SESSION_WIZARD_CREATE (state, usersId) {
    state.usersId = usersId
  },
  SESSION_WIZARD_CREATE_WITH_ROLES (state, { studentIds = [], teacherIds = [], supervisorIds = [] }) {
    state.usersId = studentIds
    state.teacherIds = teacherIds
    if (supervisorIds.length > 0) {
      console.error('@todo la création via le wizard d\'un parcours avec des supervisor n\'est pas prise en charge')
      // state.supervisorIds = supervisorIds
    }
  },
  SESSION_WIZARD_CREATE_WITH_GROUP (state, { groupId = null }) {
    state.groupId = groupId
  },
  SESSION_WIZARD_PROGRAMS (state, programIds) {
    state.programIds = programIds
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
