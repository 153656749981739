const CompanyUserGroupList = () => import('./List.vue')
const CompanyUserGroupEdit = () => import('./Edit.vue')

const routes = [
  { path: '/company-user-groups', name: 'CompanyUserGroupList', component: CompanyUserGroupList },
  { path: '/company-user-groups/edit/:id', name: 'CompanyUserGroupEdit', component: CompanyUserGroupEdit, props: true },
].map(r => {
  r.meta = {
    permission: 'client_SUPERVISOR|client_ADMIN|client_OWNER|client_TEACHER|client_CERTIFIER|',
    fail: '/',
  }
  return r
})

const routesRestricted = [
  { path: '/company-user-groups/edit', name: 'CompanyUserGroupCreate', component: CompanyUserGroupEdit },
].map(r => {
  r.meta = {
    permission: 'client_ADMIN|client_OWNER|client_TEACHER|client_CERTIFIER|',
    fail: '/',
  }
  return r
})

export default [...routes, ...routesRestricted]
