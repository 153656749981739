const CompetenciesList = () => import('./List.vue')
const CompetenciesEdit = () => import('./Edit.vue')

const routes = [
  { path: '/competency', name: 'CompetencyList', component: CompetenciesList },
  { path: '/competency/edit', name: 'CompetencyCreate', component: CompetenciesEdit },
  { path: '/competency/edit/:id', name: 'CompetencyEdit', component: CompetenciesEdit, props: true },
].map(r => {
  r.meta = {
    permission: 'grains|context_CONTRIBUTOR',
    fail: '/',
  }
  return r
})

export default routes
