import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'
import resolveDeps from '../getDeps.js'
import { filter, get, isArray, sortBy } from 'lodash'
import router from '@/router'
import { duplicateToContext } from './Mixins/duplicateToContext.js'

const CourseSchema = new Schema({
  title: 'Coursetitle',
  description: 'Schema for Course Records.',
  type: 'object',
  properties: {
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    title: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Saisissez un titre', trigger: 'blur' },
        ],
      },
    },
    reference: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Saisissez une référence', trigger: 'blur' },
        ],
      },
    },
    description: {
      type: ['string', 'null'],
    },
    categoryId: {
      type: ['string', 'null'],
    },
    companyId: {
      type: ['string', 'null'],
    },
    typeId: {
      type: ['string', 'null'],
      form: {
        rules: [
          { required: true, message: 'Choisissez un type', trigger: 'blur' },
        ],
      },
    },
    state: {
      type: 'string',
      enum: ['DRAFT', 'PUBLISHED', 'CLOSED'],
      form: {
        rules: [
          { required: true, message: 'Choisissez un état', trigger: 'blur' },
        ],
      },
    },
    video: {
      type: ['string', 'null'],
    },
    settings: {
      type: 'object',
      properties: {
        timeLimit: {
          type: ['integer', 'null'],
        },
        credit: {
          type: ['integer', 'null'],
        },
        datasetSync: {
          type: 'boolean',
        },
        datasetSyncRange: {
          type: 'array',
          items: {
            type: 'number',
          },
          maxItems: 2,
          minItems: 2,
        },
      },
      additionalProperties: true,
    },
  },
  required: ['title', 'categoryId', 'state'],
})
export class CourseRecord extends Record {
  getEditLink ({ programId }) {
    const { edit, editFromProgram } = this.constructor.mapper.paths
    let name = edit
    const params = { id: this.id }
    if (programId) {
      name = editFromProgram
      params.programId = programId
    }
    const url = router.resolve({
      name,
      query: {
        isGeneric: Boolean(this.isGeneric),
      },
      params,
    })
    return url
  }

  static getEditPath ({ id, programId = null }) {
    let name = 'CourseEdit'
    const params = { id }
    if (programId) {
      name = 'CourseEditFromProgram'
      params.programId = programId
    }
    return { name, params }
  }

  get grainsCount () {
    return this.nodes
      .filter(el => {
        return ['container'].includes(el.parent?.type)
      })
      .length
  }

  get editPath () {
    return { name: 'CourseEdit', params: { id: this.id } }
  }

  loadTree (types = null) {
    const queryFilter = { courseId: this.id }
    if (isArray(types)) {
      queryFilter.type = { $in: types }
    }
    return store.findAll('CourseNode', { filter: queryFilter, options: { sort: 'order' }, populate: { path: 'block', select: 'competencyId title' } }, { force: true, raw: true }).then(res => {
      const records = res.data
      this.tree = sortBy(filter(records, { type: 'root' }), ['order'])
      return this
    })
  }

  get competencyStats () {
    return get(this, 'tree[0].competencyStats', {})
  }

  setTree () {
    this.tree = this.getTree
  }

  get getTree () {
    return sortBy(filter(this.nodes, { type: 'root' }), ['order'])
  }

  get firstScreen () {
    return store.findAll('CourseNode', {
      filter: {
        courseId: this.id,
        type: 'root',
      },
      options: {
        sort: 'order',
      },
      limit: 1,
    }, {
      force: true,
    }).then((rs) => store.findAll('CourseNode', { filter: { parentId: rs[0].id, type: 'container' }, options: { sort: 'order', limit: 1 } }, { force: true }),
    ).then((cs) => store.findAll('CourseNode', { filter: { parentId: cs[0].id }, options: { sort: 'order', limit: 1 } }, { force: true }),
    ).then((bs) => bs[0])
  }

  clone (data) {
    return this.constructor.mapper.cloneCourse(this.id, {
      data,
    }).then((response) => {
      return response.data
    })
  }

  duplicateToContext (contextId) {
    return duplicateToContext.bind(this)('ERROR_COURSE_DUPLICATION')(contextId)
  }

  get getDeps () {
    const promises = {
      Program: store.findAll('ProgramCourse', { filter: { courseId: this.id }, populate: { path: 'program' }, options: { skip: 0, limit: 5 } }, { force: true, raw: true }).then((response) => {
        const programIds = response.data.map(pc => pc.program?._id).filter(Boolean)
        const programs = store.filter('Program', { where: { _id: { in: programIds } } })
        return { data: programs, count: +response.headers['x-model-count'] }
      }),
    }
    return resolveDeps(promises)
  }
}
export const Course = store.defineMapper('Course', {
  schema: CourseSchema,
  endpoint: 'courses',
  paths: {
    list: 'CourseList',
    edit: 'CourseEdit',
    editFromProgram: 'CourseEditFromProgram',
  },
  relations: {
    belongsTo: {
      CourseCategory: {
        localField: 'category',
        localKey: 'categoryId',
      },
      CourseType: {
        localField: 'type',
        localKey: 'typeId',
      },
    },
    hasMany: {
      CourseNode: {
        loadAllDatasWhenEditing: false,
        localField: 'nodes',
        foreignKey: 'courseId',
      },
      ProgramCourse: {
        loadAllDatasWhenEditing: false,
        localField: 'programCourses',
        foreignKey: 'courseId',
      },
      Lesson: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'courseId',
        localField: 'lessons',
      },
    },
  },
  recordClass: CourseRecord,
})

addActions({
  cloneCourse: {
    pathname: 'clone', // en envoyant ?deep=block ça copie les blocks enfants
    method: 'POST',
  },
  cloneToContext: {
    pathname: 'duplicate-to-context',
    method: 'POST',
  },
})(Course)
