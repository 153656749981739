import { Schema } from 'js-data'
import store from '@/api'

const ClassUserSchema = new Schema({
  title: 'ClassUserSchemaTitle',
  description: 'Schema for ClassUser Records.',
  type: 'object',
  additionalProperties: true,
  properties: {
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
    role: {
      type: 'string',
    },
    classId: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
  },
})

export const ClassUser = store.defineMapper('ClassUser', {
  schema: ClassUserSchema,
  endpoint: 'class-users',
  relations: {
    belongsTo: {
      Class: {
        localField: 'class',
        foreignKey: 'classId',
      },
      AppUser: {
        localField: 'user',
        foreignKey: 'userId',
      },
    },
  },
})
