import { Schema } from 'js-data'
import store from '@/api'

const LessonPageSchema = new Schema({
  title: 'LessonPageSchemaTitle',
  description: 'Schema for LessonPage Records.',
  type: 'object',
  properties: {
  },
  additionalProperties: true,
})

export const LessonPage = store.defineMapper('LessonPage', {
  schema: LessonPageSchema,
  endpoint: 'pages',
  relations: {
    belongsTo: {
      Lesson: {
        parent: true,
        localKey: 'lessonId',
        localField: 'lesson',
      },
      CourseNode: {
        localKey: 'nodeId',
        localField: 'node',
      },
    },
  },
})
