import { Record, Schema } from 'js-data'
import store from '@/api'
import resolveDeps from '../getDeps.js'

const CourseTypeSchema = new Schema({
  title: 'CourseTypetitle',
  description: 'Schema for CourseType Records.', // optional
  type: 'object',
  properties: {
    title: {
      type: 'string',
    },
    internalPrimaryColor: {
      type: ['string', 'null'],
    },
    reference: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    settings: {
      type: 'object',
      properties: {
        hints: {
          type: 'boolean',
        },
        feedbacks: {
          type: 'boolean',
        },
        retry: {
          type: 'boolean',
        },
        competencies: {
          type: 'boolean',
        },
        categories: {
          type: 'boolean',
        },
        certificate: {
          type: 'boolean',
        },
      },
    },
  },
  additionalProperties: true,
  required: ['title'],
})
class CourseTypeRecord extends Record {
  get editPath () {
    return { name: 'TypeEdit', params: { id: this.id } }
  }

  get getDeps () {
    const promises = {
      Course: store.findAll('Course', { filter: { typeId: this.id }, projection: { title: true, reference: true }, options: { skip: 0, limit: 5 } }, { force: true, raw: true }).then((response) => {
        return { data: response.data, count: +response.headers['x-model-count'] }
      }),
    }

    return resolveDeps(promises)
  }
}
export const CourseType = store.defineMapper('CourseType', {
  schema: CourseTypeSchema,
  endpoint: 'course-types',
  relations: {
    hasMany: {
      Course: {
        loadAllDatasWhenEditing: false,
        localField: 'course',
        foreignKey: 'typeId',
      },
    },
  },
  recordClass: CourseTypeRecord,
})
