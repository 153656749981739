const CoursesList = () => import('./List.vue')
const CoursesEdit = () => import('./Edit.vue')

const routes = [
  { path: '/course', name: 'CourseList', component: CoursesList },
  { path: '/course/edit/:id', name: 'CourseEdit', component: CoursesEdit },
  { path: '/program/:programId/course/edit/:id', name: 'CourseEditFromProgram', component: CoursesEdit, props: true },
  { path: '/course/edit', name: 'CourseCreate', component: CoursesEdit },
].map(r => {
  r.meta = {
    permission: 'grains|context_CONTRIBUTOR',
    fail: '/',
  }
  return r
})

export default routes
