<template lang="pug">
  .ui-globals-actions
    portal(
      :to="portalDestination"
      :order="2"
      :disabled="!portalDestination"
      )
      .actions.display-inline-block(:class='{global : !portalDestination, "no-margin": portalDestination}' )
        //- actions
        template(
          v-for="action in actions",
          v-if="typeof action.show !== 'function' || action.show()")
          tooltip(
            :key="action.title"
            :disabled="isTabletOrSmaller || !action.title"
            :content="action.title"
            placement="top-end"
            )
            el-button.operation(
              v-if="typeof action.do === 'function' "
              @click="action.do()"
              :type="action.btn.type || 'primary'"
              :plain="action.btn.plain || false"
              :size="action.btn.size || ''"
              :icon="action.btn.icon"
              circle
              :class="action.btn.class")
            router-link(
              v-else-if="action.linkTo"
              :to="action.linkTo")
              el-button(
                :class="action.btn.class"
                :type="action.btn.type || 'primary'"
                :plain="action.btn.plain || false"
                :icon="action.btn.icon"
                circle
                :size="action.btn.size || ''")
                span(v-if="!action.btn.icon") {{ op.title }}
        //- hints
        tooltip(
          v-if="isVisible"
          :disabled="isTabletOrSmaller"
          content="Aide"
          placement="top-end"
        )
          el-button(
            @click="showIntro"
            :plain="false"
            icon="el-icon-fa-info"
            type="primary"
            circle)
</template>
<script>
import {
  cloneDeep,
  get,
  isEmpty,
  isNull,
} from 'lodash'

let introJs = null

export default {
  name: 'ui-globals-actions',
  props: {
    portalDestination: String,
    actions: Array,
    context: String,
    hints: String,
  },
  data: () => ({}),
  computed: {
    hintsDatas () {
      if (!this.hints) {
        return {}
      }
      return this.$store.getters['intro/hints'](this.hints) || {}
    },
    isVisible () {
      return !isEmpty(get(this.hintsDatas, 'steps', []))
    },
  },
  watch: {
    hints: {
      handler (key) {
        if (key) {
          this.$store.dispatch('intro/load', key)
        }
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    this.hideHints()
  },
  methods: {
    showIntro () {
      if (!this.isVisible) {
        return
      }
      const steps = cloneDeep(this.hintsDatas.steps || [])
        .filter((step) => isNull(step.element) || window.document.querySelector(step.element))
        .map(value => {
          value.intro = this.translateWithContextLabel(value.intro)
          return value
        })
      document.querySelector('body').classList.add('intro-js-is-activated')
      introJs = this.$intro()
        .setOptions({
          skipLabel: 'Je connais déjà',
          doneLabel: 'Merci !',
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          disableInteraction: true,
          showStepNumbers: false,
          exitOnEsc: true,
          exitOnOverlayClick: false,
          hidePrev: true,
          hideNext: true,
        })
        .onafterchange(el => {
          const buttons = document.querySelectorAll('.introjs-button:not(.introjs-donebutton)')
          for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].classList.value.indexOf('is-plain') === -1) {
              buttons[i].classList.value += ' is-plain'
            }
          }
          const buttonsPrimary = document.querySelectorAll('.introjs-button:not(.el-button--primary)')
          for (let i = 0; i < buttonsPrimary.length; i++) {
            buttonsPrimary[i].classList.value += ' el-button--primary'
          }
        })
        .addSteps(steps)
        .start()
        .oncomplete(() => {
          introJs.exit()
        })
        .onexit(() => {
          document.querySelector('body').classList.remove('intro-js-is-activated')
        })
    },
    hideHints () {
      if (!introJs) return
      window.removeEventListener('scroll', this.scrollView, true)
      introJs.exit(true)
      introJs = null
    },
  },
}
</script>
<style lang="sass">
.introjs-hidden
  display: none
</style>
