<template lang="pug">
  .pi-pop
    el-popover(
      ref="popover1"
      placement="top-start"
      :popper-class='popoverClass'
      :width="widthDimension"
      :title="title"
      trigger="hover"
      :content="message")

    i(:class="iconClass"
      v-popover:popover1
      @click="emitThis")

</template>
<script>
export default {
  name: 'pi-pop',
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    message: {
      type: String,
    },
    customIcon: {
      type: String,
    },
    title: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: 150,
    },
  },
  data: () => ({}),
  computed: {
    popoverClass () {
      return this.theme === 'dark' ? 'el-popover--dark' : ''
    },
    widthDimension () {
      return this.width || ''
    },
    iconClass () {
      return this.customIcon || 'el-icon-info'
    },
  },
  methods: {
    emitThis (ev) {
      this.$emit('popClick', ev)
    },
  },
}
</script>
