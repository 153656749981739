export default {
  methods: {
    contactUser (id) {
      this.$store.commit('CONTACTFORM_SET_TO', id)
    },
    contactClass (id) {
      this.$store.commit('CONTACT_CLASS_FORM_SET_TO', id)
    },
  },
}
