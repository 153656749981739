import { ROLES } from '@/api/enums/roles'

const UsersList = () => import('./List.vue')
const UsersByRoleList = () => import('./ByRoleList.vue')
const StudentList = () => import('./roles/studentList.vue')
const UsersEdit = () => import('./Edit.vue')
const ExportCsv = () => import('./ExportCsv.vue')

export default [
  {
    path: '/users',
    name: 'UserList',
    meta: { permission: 'grains&context' },
    component: UsersList,
  },
  {
    path: '/students',
    name: 'StudentList',
    meta: { permission: 'client' },
    props: {
      role: ROLES.STUDENT,
      model: 'AppUser',
    },
    component: StudentList,
  },
  {
    path: '/teachers',
    name: 'TeacherList',
    meta: { permission: 'client_TEACHER|client_ADMIN|client_OWNER|client_SUPERVISOR' },
    props: {
      role: ROLES.TEACHER,
      model: 'AppUser',
    },
    component: UsersByRoleList,
  },
  {
    path: '/supervisors',
    name: 'SupervisorList',
    meta: { permission: 'client_TEACHER|client_ADMIN|client_OWNER|client_SUPERVISOR' },
    props: {
      role: ROLES.SUPERVISOR,
      model: 'AppUser',
    },
    component: UsersByRoleList,
  },
  {
    path: '/certifiers',
    name: 'CertifierList',
    meta: { permission: 'client_CERTIFIER|client_ADMIN|client_OWNER' },
    props: {
      role: ROLES.CERTIFIER,
      model: 'AppUser',
    },
    component: UsersByRoleList,
  },
  {
    path: '/admin',
    name: 'AdminList',
    meta: { permission: 'client_ADMIN|client_OWNER' },
    props: {
      role: ROLES.ADMIN,
      model: 'AppUser',
    },
    component: UsersByRoleList,
  },
  {
    path: '/users/edit',
    name: 'UserCreate',
    meta: { permission: 'grains&context' },
    component: UsersEdit,
  },
  {
    path: '/users/edit/:id',
    name: 'UserEdit',
    meta: { permission: 'grains&context' },
    component: UsersEdit,
  },
  // { path: '/login', name: 'Login', meta: { permission: '' }, component: Login }, // Pas besoin
  {
    path: '/me',
    name: 'Account',
    meta: { permission: 'client|grains' },
    component: UsersEdit,
  },
  {
    path: '/users/export-csv',
    name: 'UserExportCSV',
    meta: { permission: 'grains|context|client' },
    component: ExportCsv,
  },
]
