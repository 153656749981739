<template lang="pug">
.pi-contact-user
  el-dialog(
    :visible.sync="isVisible"
    :title="title"
  )
    el-form(
      :ref="formName"
      :model="form"
      :rules="rules"
    )
      el-form-item.roles(
        v-if='availableRoles && availableRoles.length > 0'
        :label="$tc('attributes.role.title', 2)"
        prop="roles"
      )
        el-checkbox-group(v-model="form.roles")
          el-checkbox(
            v-for="role in availableRoles"
            :key="role"
            :label="role"
          ) {{ $tc('roles.' + role+ '.title', 1) }}

      el-form-item(:label="$tc('attributes.object.title', 1)")
        el-input(
          v-model="object"
          disabled)

      el-form-item(
        prop='message')

        pi-froala-editor(
          style="width: 100%"
          v-if="isVisible"
          ref="message"
          :conf="editorConf"
          :statement.sync="form.message"
          :direct="true"
        )
      input(
        ref='contact-file'
        type="file"
        multiple
        @change="setAttachment"
      )
      el-form-item(
        label="Recevoir une copie du message"
        prop="receiveCopy")
        el-checkbox(
          v-model="form.receiveCopy")

      p
        em Vos coordonnées seront transmises à la personne contactée

      el-button.truncate(
        @click="submit"
        :loading="isSending")
        | {{ submitLabel }}

      pi-alerts.alerts.medium-margin-top(:channel="channelContactUsers")

</template>
<script>
import promiseMap from 'promise-map'
import AlertChannelMixin from '@/mixins/AlertChannelMixin.js'

export default {
  name: 'pi-contact-user',
  components: {
    PiAlerts: () => import('@/components/Templates/PiAlerts/PiAlerts.vue'),
    PiFroalaEditor: () => import('@/components/Templates/PiFroalaEditor/PiFroalaEditor.vue'),
  },
  mixins: [
    AlertChannelMixin('channelContactUsers'), // this.?, ?_add(), ?_remove(), ?_clear()
  ],
  data () {
    const placeholders = {
      message: 'votre message',
    }
    return {
      attachments: [],
      attachment: null,
      availableRoles: null,
      formName: 'contact',
      placeholders,
      messageRule: {
        required: true,
        message: this.$t('actions.write.something', { something: placeholders.message }),
        trigger: 'blur',
      },
      form: {
        message: '',
        roles: [],
        receiveCopy: false,
      },
      isSending: false,
      editorConf: {
        toolbarButtons: [
          'undo', 'redo',
          '|', 'bold', 'italic', 'underline', '|', 'insertLink', 'formatUL', 'clearFormatting',
        ],
        quickInsertButtons: [],
        quickInsertEnabled: false,
      },
    }
  },
  computed: {
    rules () {
      return {
        message: this.messageRule,
      }
    },
    submitLabel () {
      const someone = this.$store.getters.contactUserWhom
      return this.$tc('actions.send.toSomeone', 1, { someone })
    },
    title () {
      const something = this.$store.getters.contactUserSubmitWho
      return this.$tc('actions.contact.something', 1, { something })
    },
    isVisible: {
      get () {
        const isVisible = this.recipients.length > 0
        if (isVisible) {
          this.resetDatas()
        }
        return isVisible
      },
      set (value) {
        if (!value) {
          this.$store.commit('CONTACTFORM_HIDE')
        }
      },
    },
    recipients () {
      return this.$store.getters.contactUserRecipients
    },
    object () {
      return `${this.currentUserRecord?.displayName ?? 'Un utilisateur'} vous a envoyé un message depuis ${this.contextName} `
    },
  },
  methods: {
    async setAttachment ({ target }) {
      this.$set(this, 'attachments', [])
      if (target.files && target.files.length > 0) {
        try {
          for (const file of target.files) {
            this.attachments.push({
              content: (await this.convertFileToBase64(file)).split(/[^A-Za-z0-9+/=]/).at(-1),
              filename: file.name,
              type: file.type,
              disposition: 'attachment',
            })
          }
        } catch (error) {
          window.alert('Un ou des fichiers sélectionné ont généré une erreur')
          console.log(error)
        }
      }
    },
    submit () {
      this.resetAlerts()
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          this.isSending = true
          let i = 0
          Promise.resolve(this.recipients).then(promiseMap((user) => {
            const sendCopy = i === 0 ? this.form.receiveCopy : false
            i++
            return user.contact(this.form.message, sendCopy, this.object, this.attachments)
          })).then(data => {
            this.channelContactUsers_add({
              title: 'Message envoyé',
              type: 'success',
            })
          }).catch(err => {
            const errors = this._get(err, 'response.data.errors', [])
            for (const i in errors) {
              const code = errors[i].code || errors[i].title
              this.channelContactUsers_add({
                title: this.$t(`errors.${code}.title`),
                type: 'error',
              })
            }
          }).then(() => {
            this.isSending = false
          })
        } else {
          this.channelContactUsers_add({
            title: 'Des données sont manquantes pour satisfaire votre requète',
            type: 'error',
          })
        }
      })
    },
    resetDatas () {
      if (this.$refs[this.formName]) {
        this.$refs[this.formName].clearValidate()
      }
      if (this.$refs['contact-file']) {
        this.$refs['contact-file'].value = ''
      }

      this.form.message = ''
      this.form.receiveCopy = false
      this.resetAlerts()
    },
    resetAlerts () {
      this.channelContactUsers_clear()
    },
  },
}
</script>
<style lang="sass">
.pi-contact-user
  .pi-froala-editor
    textarea + br,
    textarea + br + br
        display: none
  .roles
    label
      float: none
  .el-checkbox-group
    display: flex
    flex-wrap: wrap
    .el-checkbox
      margin: 0 1em 0 0

</style>
