import { Record, Schema } from 'js-data'
import api from '@/api'
import { LESSON_PROGRESS } from './Lesson'
import { addActions } from 'js-data-http'
import i18n from '@/i18n'

export const conditionalSchema = {
  conditions: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        progress: {
          type: 'string',
        },
        all: {
          type: 'boolean',
        },
      },
    },
  },
  oneOf: {
    type: 'boolean',
  },
}
export const OPENING_MODES = {
  NONE: 'NONE',
  DATE: 'DATE',
  COMPLETE: 'COMPLETE',
}

export class ClassProgramFactories {
  static programClassByProgramId (programId) {
    return {
      programId,
      startAt: null,
      expireAt: null,
      actions: {
        conditional: [],
      },
    }
  }

  static classProgramOpenWhenClassProgramIsCompleteCondition (classProgramId) {
    return {
      classProgramId,
      conditions: [{
        progress: LESSON_PROGRESS.COMPLETED,
        all: true,
      }],
      oneOf: true,
    }
  }
}
const ClassProgramSchema = new Schema({
  title: 'ClassProgram Schema',
  description: 'Schema for ClassProgram Records.',
  type: 'object',
  properties: {
    additionalProperties: true,
    id: {
      type: 'string',
    },
    programId: {
      type: 'string',
    },
    classId: {
      type: 'string',
    },
    order: {
      type: 'number',
    },
    startAt: {
      type: ['null', 'string'],
    },
    expireAt: {
      type: ['null', 'string'],
    },
    actions: {
      type: 'object',
      optional: true,
      additionalProperties: true,
      properties: {
        conditional: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              classProgramId: {
                type: 'string',
              },
              ...conditionalSchema,
            },
          },
        },
      },
    },
  },
})
export class ClassProgramRecord extends Record {
  get hasOpenningCondition () {
    return (this?.actions?.conditional || []).length > 0
  }

  getHumanReadableOpeningActions () {
    const openingActions = this?.actions?.conditional || []
    return openingActions.map(action => {
      if (action.classProgramId) {
        const states = action.conditions.map(condition => condition.progress)
          .filter(Boolean)
          .map(state => `<strong>${i18n.tc(`flags.progress.${state}.message`).toLowerCase()}</strong>`)

        if (states.length > 1) {
          const shortWord = action.oneOf ? ' ou ' : ' et '
          states[states.length - 1] = shortWord + states[states.length - 1]
        }
        const classProgramOpener = api.get('ClassProgram', action.classProgramId)
        const programName = classProgramOpener?.program?.name || 'un autre module'
        return `Quand <strong>${programName}</strong> est ${states.join(', ')}`
      }
      return undefined
    }).filter(Boolean)
  }

  async getStats () {
    const datas = await this.constructor.mapper.stats(this.id, {})
    return datas.data
  }

  async resetByUserIds (userIds) {
    const datas = await this.constructor.mapper.reset(this.id, {
      data: userIds,
    })
    return datas.data
  }

  async resetByUserId (userId) {
    return await this.resetByUserIds(userId)
  }
}

export const ClassProgram = api.defineMapper('ClassProgram', {
  schema: ClassProgramSchema,
  endpoint: 'class-programs',
  relations: {
    belongsTo: {
      Program: {
        loadAllDatasWhenEditing: false,
        localField: 'program',
        foreignKey: 'programId',
      },
      Class: {
        loadAllDatasWhenEditing: false,
        localField: 'class',
        foreignKey: 'classId',
      },
    },
  },
  recordClass: ClassProgramRecord,
})
addActions({
  stats: {
    pathname: 'stats',
    method: 'GET',
  },
  reset: {
    pathname: 'reset',
    method: 'POST',
  },
})(ClassProgram)
