const CourseBlocksList = () => import('./List.vue')
const CourseBlocksEdit = () => import('./Edit.vue')

const routes = [
  {
    path: '/course-blocks',
    component: CourseBlocksList,
    name: 'CourseBlockList',
  }, {
    path: '/course-blocks/edit/:id',
    component: CourseBlocksEdit,
    name: 'CourseBlockEdit',
  }, {
    path: '/course/:courseId/course-blocks/edit/:id',
    component: CourseBlocksEdit,
    name: 'CourseBlockEditFromCourse',
    props: true,
  }, {
    path: '/program/:programId/course/:courseId/course-blocks/edit/:id',
    component: CourseBlocksEdit,
    name: 'CourseBlockEditFromProgram',
    props: true,
  }, {
    path: '/course-blocks/edit',
    component: CourseBlocksEdit,
    name: 'CourseBlockCreate',
  },
].map(r => {
  r.meta = {
    permission: 'grains|context_CONTRIBUTOR',
    fail: '/',
  }
  return r
})

export default routes
