import { forOwn } from 'lodash'
const state = () => ({
  userId: null,
})

// getters
const getters = {
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  VIEWFORM_SET_USERID (state, userId) {
    state.userId = userId
  },
  VIEWFORM_HIDE (state) {
    state.userId = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
