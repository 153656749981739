import Vue from 'vue'
import {
  concat,
  forOwn,
  hasIn,
  isArray,
  isString,
} from 'lodash'
const state = () => ({
  channels: {},
})

// getters
const getters = {
  getAlertsInChannel (state, getters) {
    return channel => {
      return state.channels[channel]
    }
  },
}
// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  ADD_ALERT_IN_CHANNEL (state, { channel = 'global', alert }) {
    if (!hasIn(state.channels, channel)) {
      Vue.set(state.channels, channel, [])
    }
    const chan = state.channels[channel]
    const contentKeys = ['description', 'title', 'context']
    if (alert.mergeBy && contentKeys.includes(alert.mergeBy)) {
      const toMergeKeys = contentKeys.filter(el => el !== alert.mergeBy)
      const mergeKey = alert.mergeBy
      const existingAlert = chan.find(el => el[mergeKey] === alert[mergeKey])
      if (existingAlert) {
        toMergeKeys.forEach(key => {
          if (isString(existingAlert[key]) && isString(alert[key]) && existingAlert[key] !== alert[key]) {
            existingAlert[key] += alert[key]
          } else if (isArray(existingAlert[key]) && isArray(alert[key])) {
            existingAlert[key] = concat(existingAlert[key], alert[key])
          }
        })
      } else {
        chan.push(alert)
      }
    } else {
      chan.push(alert)
    }
  },
  REMOVE_ALERT_FROM_CHANNEL (state, { channel = 'global', index }) {
    if (isArray(state.channels[channel])) {
      state.channels[channel].splice(index, 1)
    }
  },
  CLEAR_ALERTS_FROM_CHANNEL (state, { channel = 'global' }) {
    Vue.set(state.channels, channel, [])
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
