import jsdataPlugin from './plugins/vuex-jsdata-v3-plugin'
import api from '@/api/index.js'

// C'est ici qu'on importe les models.
import '@/api/models/AppFile.js'
import '@/api/models/AppRole.js'
import '@/api/models/AppUser.js'
import '@/api/models/Class'
import '@/api/models/ClassProgram.js'
import '@/api/models/ClassUser'
import '@/api/models/Company'
import '@/api/models/CompanyInvitationCode'
import '@/api/models/CompanyUser.js'
import '@/api/models/CompanyUserGroup.js'
import '@/api/models/Context.js'
import '@/api/models/Course.js'
import '@/api/models/CourseBlock.js'
import '@/api/models/CourseCategory.js'
import '@/api/models/CourseCompetency.js'
import '@/api/models/CourseNode.js'
import '@/api/models/CourseTerm.js'
import '@/api/models/CourseType.js'
import '@/api/models/Job.js'
import '@/api/models/Lesson.js'
import '@/api/models/LessonPage'
import '@/api/models/Library.js'
import '@/api/models/LogBook.js'
import '@/api/models/Product.js'
import '@/api/models/Program.js'
import '@/api/models/ProgramCourse.js'
import '@/api/models/Record.js'
import '@/api/models/Review.js'
import '@/api/models/Role'
import '@/api/models/RoleUser.js'
import '@/api/models/Transaction.js'
import '@/api/models/Wallet.js'

export default [jsdataPlugin(api)]
