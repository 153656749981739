import { Schema } from 'js-data'
import store from '@/api'

const RoleShema = new Schema({
  title: 'RoleShemaTitle',
  description: 'Schema for Role Records.',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    scope: {
      type: 'string',
      enum: ['GLOBAL', 'COMPANY', 'CLASS'],
    },
  },
  additionalProperties: true,
})

export const Class = store.defineMapper('Role', {
  schema: RoleShema,
  endpoint: 'roles',
  relations: {
    hasMany: {
      ClassUser: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'roleId',
        localField: 'classUsers',
      },
    },
  },
})
