import { forOwn, get, isString, set } from 'lodash'
import api from '@/api'
import configuration from '@/api/configuration.js'
import i18n from '@/i18n'
import attestation from '@/locales/attestation/fr'
import certification from '@/locales/certification/fr'

const state = () => ({
  id: null,
  isGeneric: false,
})

// getters
const getters = {
  isGeneric (state) {
    return state.isGeneric === true
  },
  id (state) {
    return state.id
  },
  get (state, getters, rootState) {
    return (path) => {
      return get(rootState, ['DS', 'Context', state.id, ...path.split('.')])
    }
  },
  apiUsed (state, getters, rootState) {
    if (state.id) {
      get(rootState, ['DS', 'Context', state.id, 'hosts', 'api'])
    }
    return configuration.basePath
  },
}

// actions = action asynchrone qui pourra executer d'autres action avec dispatch et des commit
const actions = {
  useGenericContext ({ commit, getters, dispatch }, value) {
    commit('IS_GENERIC', value)
  },
  setContextId ({ commit, getters, dispatch }, id) {
    commit('IS_GENERIC', false)
    commit('SET_CONTEXT_ID', id)
    document.title = getters.get('name')
    // mmise à jour des données dans la configuration.

    if (process.env.VUE_APP_NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
      // In development, we don't change the remote API, but only the X-Origin or X-Host

      configuration['X-Host'] = getters.get('hosts.api')
      localStorage.setItem('X-Host', getters.get('hosts.api'))
      api.getAdapter().http.defaults.headers.common['X-Host'] = configuration['X-Host']

      configuration['X-Origin'] = getters.get('hosts.backoffice')
      localStorage.setItem('X-Origin', getters.get('hosts.backoffice'))
      api.getAdapter().http.defaults.headers.common['X-Origin'] = configuration['X-Origin']
    } else {
      configuration.basePath = `https://${getters.get('hosts.api')}/`
    }

    api.basePath = configuration.basePath

    Object.keys(api.getAdapters()).forEach(adapter => {
      api.getAdapter(adapter).basePath = configuration.basePath
    })

    dispatch('replaceLabels')
    dispatch('replaceCertificationOrAttestation')
    dispatch('replaceTranslations')
  },
  replaceTranslations ({ getters }) {
    const locale = i18n.getLocaleMessage(i18n.locale)
    const translations = getters.get('metas.translations') ?? []

    translations.forEach(item => {
      if (get(locale, item.path) === undefined && !isString(item.path)) return

      set(locale, item.path, item.translation)
    })

    i18n.setLocaleMessage('fr', locale)
  },

  replaceLabels ({ getters }) {
    let locale = i18n.getLocaleMessage(i18n.locale)
    locale.context.credit.title = getters.get('metas.creditLabel')
    locale.context.license.title = getters.get('metas.licenseLabel')
    i18n.setLocaleMessage('fr', locale)
    locale = i18n.getLocaleMessage(i18n.locale)
    locale.context.credit.addMany = i18n.t('actions.add.manySomething', { something: i18n.tc('context.credit.title', 2) })
    locale.context.credit.myPlural = `Mes ${i18n.tc('context.credit.title', 2)}`
    locale.context.license.addMany = i18n.t('actions.add.manySomething', { something: i18n.tc('context.license.title') })
    locale.context.license.myPlural = `Mes ${i18n.tc('context.license.title')}`
    i18n.setLocaleMessage('fr', locale)
  },
  replaceCertificationOrAttestation ({ getters }) {
    const type = getters.get('settings.certificationType')
    const locale = i18n.getLocaleMessage(i18n.locale)
    let dictionnary = certification
    if (type === 'attestation') {
      dictionnary = attestation
    }
    locale.attributes.certification = dictionnary.attributes.certification
    locale.attributes.certificate = dictionnary.attributes.certificate
    locale.models.Certification = dictionnary.models.Certification
    locale.routes.CertifierList = dictionnary.routes.CertifierList
    locale.routes.ClassCertificationList = dictionnary.routes.ClassCertificationList
    locale.routes.ProgramCertificationGrid = dictionnary.routes.ProgramCertificationGrid
    locale.roles.CERTIFIER = dictionnary.roles.CERTIFIER
    i18n.setLocaleMessage('fr', locale)
  },
}

// mutations = commit, qui modifient le STATE
const mutations = {
  IS_GENERIC (state, value) {
    state.isGeneric = value || false
    if (state.isGeneric) {
      window.sessionStorage.setItem('useGenericContext', state.isGeneric)
    } else {
      window.sessionStorage.removeItem('useGenericContext')
    }
  },
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET (state, { key, value }) {
    state[key] = value
  },
  SET_CONTEXT_ID (state, id, rootState) {
    state.id = id
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
