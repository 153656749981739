export default {
  attributes: {
    certification: {
      title: 'Site des certificats | Sites des certificats',
      defined: 'le site des certificats | les sites des certificats',
      description: 'URL du site public. Ex : https://sous-domaine.domaine.ext',
    },
    certificate: {
      title: 'Certification | Certifications',
      hint: 'Si activé, le participant obtiendra un certificat une fois sa Séquence terminée. Cette option se défini à la création et ne peux être changée.',
      score: 'Score Certification | Scores Certifications',
      undefined: 'une certification | des certifications',
    },
  },
  models: {
    Certification: {
      title: 'Certification | Certifications',
      defined: 'la certification | les certifications',
      undefined: 'une certification | des certifications',
      to: 'de cette certification | des certifications',
      this: 'de cette certification | de ces certifications',
      alias: 'Certification | Certifications',
      all: 'Toute la certification | Toutes les certifications',
    },
  },
  routes: {
    CertifierList: 'Certificateurs',
    ClassCertificationList: 'Parcours certification',
    ProgramCertificationGrid: 'Modules certification',
  },
  roles: {
    CERTIFIER: {
      icon: 'el-icon-pi-certificateurs',
      title: 'Certificateur | Certificateurs',
      this: 'ce certificateur | ces certificateurs',
      undefined: 'un certificateur | des certificateur',
      defined: 'le certificateur | les certificateurs',
      none: 'aucun certificateur',
      detail: 'Peut encadrer des certifications au nom d\'une entreprise',
    },
  },
}
