const ProductsList = () => import('./List.vue')
const ProductEdit = () => import('./Edit.vue')

export default [
  { path: '/products', name: 'ProductsList', component: ProductsList },
  { path: '/products/edit', name: 'ProductCreate', component: ProductEdit },
  { path: '/products/edit/:id', name: 'ProductEdit', component: ProductEdit, props: true },
  { path: '/products/license', name: 'LicensesProductsList', component: ProductsList, props: { isLicense: true } },
  { path: '/products/license/edit', name: 'LicensesProductCreate', component: ProductEdit, props: { isLicense: true } },
]
