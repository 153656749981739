const TermsList = () => import('./List.vue')
const TermsEdit = () => import('./Edit.vue')

const routes = [
  { path: '/course-terms', name: 'TermList', component: TermsList },
  { path: '/course-terms/edit', name: 'TermCreate', component: TermsEdit },
  { path: '/course-terms/edit/:id', name: 'TermEdit', component: TermsEdit },
].map(r => {
  r.meta = {
    permission: 'grains|context_CONTRIBUTOR',
    fail: '/',
  }
  return r
})

export default routes
