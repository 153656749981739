import configuration from '@/api/configuration.js'
import bowser from 'bowser'
import { get, isString, truncate } from 'lodash'
import { trailingSlashIt, untrailingSlashIt } from '@/helpers/slash.js'
const browser = bowser.getParser(get(window, 'navigator.userAgent', ''))
const compatiblities = {
  chrome: '>55',
  firefox: '>50',
  edge: '>50',
}
const browserStatisfaction = browser.satisfies(compatiblities)
function noAccent (query) {
  const noaccent = query.toLowerCase()
    .replace(/[eêèéë]/g, 'e')
    .replace(/[aàäâ]/g, 'a')
    .replace(/[iîï]/g, 'i')
    .replace(/[uûü]/g, 'u')
    .replace(/[oôö]/g, 'o')
    .replace(/[cç]/g, 'c')
    .replace(/[^a-zA-Z0-9\-_ ]{1}/g, '')
    .replace(/[ ]{2,}/g, ' ')
  return noaccent
}

export default {
  globals: {
    SPACE: 'backoffice',
    API: trailingSlashIt(configuration.basePath),
    ENV: process.env.VUE_APP_NODE_ENV, // development, staging, production
    WWW: process.env.VUE_APP_WWW,
    STRIPE_PK: process.env.VUE_APP_STRIPE_PK,
    LIMITGLOBAL: 50,
    LIMITCOMPETENCY: 20,
    LIMITCATEGORY: 50,
    STATSFROMLIMIT: '2018-03-14T23:59:59.999Z',
    BROWSER: {
      compatiblities,
      browserStatisfaction,
      compatibleBrowsers: [
        {
          name: 'Chrome',
          url: 'https://www.google.com/chrome/',
        }, {
          name: 'Firefox',
          url: 'https://www.mozilla.org/fr/firefox/',
        },
      ],
    },
  },
  COLORS: {
    transparent: 'rgba(0, 0, 0, 0)',
    badgePlus: '#00FF00',
    badgeMinus: '#FF0000',
    primary: '#20a0ff',
    success: '#00b550',
    warning: '#ffa800',
    danger: '#ff4949',
    info: '#50bfff',
    admin: '#e66767',
    client: '#20a0ff',
    white: '#ffffff',
    dark: '#333333',
    grey: '#666666',
    competencies: {
      pi: '#FF0000',
    },
    programPack: 'rgb(89,73,250)',
    tertiary: 'rgb(46, 56, 69)',
    colorChart: ['#950AFF', '#E8BB0C', '#0AFF00', '#5949FA', '#0CA7E8', '#5CB2AE', '#FF4C69', '#F700FF', '#00CC1B', '#AB5F00'],
  },
  truncate (txt, nbr, sep = ' ') {
    if (!isString(txt)) {
      return ''
    }
    return truncate(txt, {
      length: nbr,
      separator: sep,
    })
  },
  getFilePath (id) {
    return `${configuration.basePath}files/${id}/download`
  },
  untrailingSlashIt,
  trailingSlashIt,
  sortFilterString (a, b) {
    const aToString = noAccent(get(a, 'text', '-'))
    const bToString = noAccent(get(b, 'text', '-'))
    if (aToString === bToString) {
      return 0
    } else if (aToString < bToString) {
      return -1
    }
    return 1
  },
  isSiret (siret) {
    let estValide
    if ((siret.length !== 14) || (isNaN(siret))) {
      estValide = false
    } else {
      let somme = 0
      let tmp
      for (let cpt = 0; cpt < siret.length; cpt++) {
        if ((cpt % 2) === 0) {
          tmp = siret.charAt(cpt) * 2
          if (tmp > 9) {
            tmp -= 9
          }
        } else {
          tmp = siret.charAt(cpt)
        }
        somme += parseInt(tmp)
      }
      if ((somme % 10) === 0) {
        estValide = true
      } else {
        estValide = false
      }
    }
    return estValide
  },
  textToHtml (txt) {
    if (isString(txt)) {
      return txt.replace(/[\r\n]/g, '<br />')
    }
    return txt
  },
  capitalize (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
}
