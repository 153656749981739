<template lang="pug">
  el-dialog.pi-uploads(
    title="Bibliothèque de fichiers"
    :visible.sync="isVisible"
    custom-class="large"
    @close="close"
    @open="open"
    :append-to-body="Boolean(appendToBody)"
    )
    el-form(ref="formChoice", label-width="150px" v-show="multiple")
      el-form-item(:label="$tc('appFiles.library.title', libraries.length)", prop="libraries")
        el-select(
          v-if="multiple"
          v-model='activeLibraryId'
          :placeholder="$t('actions.select.something', {something: $tc('appFiles.library.undefined', 1)})"
          @change="getActiveLibrary"
          )
          el-option(
            v-for='(library, libraryIndex) in libraries'
            :key='libraryIndex'
            :label='getLabel(library.ownerType, library.ownerId)'
            :value='library.id'
            )

        el-input(v-else, readonly, :value="getLabel(ownerType, ownerId)")
    el-row(v-if="loadingError")
      el-alert.text-align-center.justify-content-center.large-padding-top.medium-padding-bottom(
        title='Nous sommes désolés'
        type="error"
        effect="dark"
        center
        :description="loadingError"
        @close="loadingError=false"
      )
        strong
        p {{ loadingError}}
    el-row(v-else-if='_isEmpty(activeLibrary)', :gutter="10")
      el-col.card-list-container
        | Choisissez une librairie parmi la liste
    el-row.card-selector(v-else, :gutter="10")
      el-col.card-list-container(:span='17')
        el-row.align-items-stretch(type="flex" :gutter="10")
          el-col.flex-grow-1(:span='18' v-if="shouldShowUploadFile")
            el-upload.text-align-center.el-upload-wrapper(
              drag
              ref="upload"
              :data='getFileData'
              :multiple='true'
              :action='action'
              :on-preview='handlePreview'
              :on-success='handleSuccess'
              :on-error='handleError'
              :before-upload="handleBeforeUpload"
              :auto-upload="true"
              :file-list='fileList'
              :show-file-list="true"
              :accept="allowedFileUploadFormats.join(',')")
              i.el-icon-upload.ts-3
              div.el-upload__text Glissez-déposez votre fichier ici ou
                em &nbsp;cliquez pour en choisir un.
                br
                template(v-if="allowedFileUploadFormats && getFileUploadFormats")
                  small
                    em uniquement les formats {{ getFileUploadFormats.join(', ') }}
                      br
                      | ({{ allowedFileUploadFormats.join(', ') }})
                  br
          el-col.flex-grow-1(:span='6' v-if='shouldShowRecordAudio')
            .el-upload-dragger.use-microphone.text-align-center(
              v-show="!isMicrophoneVisible"
              @click='isMicrophoneVisible=true'
            )
              i.el-icon-fa-microphone.ts-3.medium-margin-bottom
              div.el-upload__text Créer un fichier son
            .el-upload-dragger.use-microphone.text-align-center(v-show="isMicrophoneVisible")
              microphone-recorder(
                :visible.sync="isMicrophoneVisible"
                @export="sendSoundFile"
              )

        el-row.align-items-stretch.pi-upload-external__row(type="flex" :gutter="10")
          .pi-upload-external.text-align-center(
                v-show='shouldShowExternalLink'
                @click='showExternalUrlModal'
              )
            i.el-icon-fa-external-link.ts-3.medium-margin-bottom
            div.el-upload__text Ajouter un lien externe

        el-dialog(
          title='Ajouter un lien externe'
          :visible.sync='dialogVisible'
          @close='hideExternalUrlModal'
          append-to-body
          )
          el-form(
            :rules="rules",
            :model="item"
            ref="item"
            label-width="75px"
          )
            div.pi-upload-external-form
              el-form-item(
                prop="externalUrl"
                label="URL"
              )
                el-input.align-self-end(
                  v-model="item.externalUrl"
                  :placeholder="'Entrer une URL externe'"
                )
              el-form-item(
                prop="name"
                label="Nom"
              )
                el-input.align-self-end(
                  v-model="item.name"
                  :placeholder="'Entrer un nom de ressource externe'"
                )
              el-form-item.text-align-right
                el-button.save(type="primary", @click="onSubmitExternalUrl")
                  | {{$t('actions.save.action')}}
        el-alert(:title="messageType", :type="messageType", v-if="message")
          | {{ message }}
        el-row.el-upload-search
          el-col
            el-input(
              type=''
              @change='getActiveLibrary'
              v-model='search')
              template(slot="prepend")
                i.el-icon-search
              template(slot="suffix")
                i.el-icon-circle-close(
                  @click="clearSearch"
                  style="padding: 14px; position: absolute;"
                  )

        el-row.flex-wrap.little-margin-top.card-list(v-if="activeLibraryFiles" type="flex" :gutter="10")
          el-col(:span='6' v-for='(file, index) in activeLibraryFiles' :key='index')
            .card(@click="previewFile(file)")
              div.wrapper(:class='file.id === activeFile.id ? "is-active" : ""')
                pi-uploads-preview(v-model="activeLibraryFiles[index]", :ratio='.8')

              div(style='padding: 14px;', v-if="isNotEmptyArray(file.tags)")
                tag(v-for="tag in file.tags", type="primary", :key="tag", :class="noaccents(_kebabCase(tags +' '+tag))")
                  | {{ tag }}
                  .text-align-center.large-margin-top

          el-pagination(
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="currentPage"
            hide-on-single-page=true
            :total="total"
            :page-sizes="[20, 50, 100]"
            :page-size=20
            layout="prev, pager, next, sizes"
          )

      el-col.td.details.display-flex.flex-direction-column(:span='7')
        template(v-if="!_isEmpty(activeFile)")
          pi-uploads-preview(v-model="activeFile", theme='light')
          div(style='padding: 14px;')
            .text-align-center
              a.medium-margin-right(:href="activeFile.download",target="_blank", rel="noopener")
                el-button(type="success", :plain="true") {{$t('actions.render.action')}}
              el-button(v-if='!hideChoice', @click='choiceFile(activeFile)', type="primary") {{$t('actions.choose.action')}}
              el-button(@click='warnDeps() && onDelete(activeFile)', :plain="true" type="danger") {{$t('actions.delete.action')}}

            template(v-if='!hideOptions')
              h2 {{$tc('rows.exercices.option.title', 2)}}
              el-select(v-model="fileOptions.size", placeholder="Taille")
                el-option(value="small", label="small")
                el-option(value="big", label="big")
            template(v-if="!hideDetails")
              h2 Informations
              p(v-if="activeFile.id")
                strong {{ $t('attributes.id.title') }} :
                |  {{ activeFile.id }}
              p.truncate(v-if="activeFile.name")
                strong {{ $tc('attributes.name.title', 1) }} :
                |  {{ activeFile.name }}
              p(v-if="activeFile.fileType")
                strong {{ $tc('attributes.type.title', 1) }} :
                |  {{ activeFile.fileType }}
              p(v-if="activeFile.size")
                strong {{ $tc('attributes.size.title', 1) }} :
                |  {{ activeFile.size }}
              p(v-if="activeFile.type")
                strong {{ $t('attributes.type.mime') }} :
                |  {{ activeFile.type }}
              p(v-if="activeFile.link")
                strong {{ $t('attributes.link.title') }} :
                |  {{ activeFile.link }}

        template(v-else)
          h2 Choisissez un media
          p Parmi la liste ci-contre, choisissez le média qui vous convient, puis validez votre choix.
        .flex-grow-1
        el-button(type="primary" plain @click="close") {{ $t('actions.close.action')}}

    span.dialog-footer(slot='footer')

    pi-dependencies(
      :visibility="showDialogDependencies"
      :deps="deps"
      @close="showDialogDependencies = false"
      @clickToDelete="onDelete(activeFile)"
    )
</template>
<script>
import api from '@/api'
import axios from 'axios'
import moment from 'moment'
import ImageMethods from '@/helpers/image-manipulation/methods'
import promiseProps from 'promise-props'
import apiConfiguration from '@/api/configuration.js'
import reg from '@/helpers/regexp.js'
import {
  cloneDeep,
  compact,
  filter,
  get,
  hasIn,
  includes,
  isArray,
  isEmpty,
  isString,
  map,
  property,
  sortBy,
} from 'lodash'

const compression = {
  'image/jpg': 70,
  'image/jpeg': 70,
  'image/png': 100,
}
export default {
  name: 'pi-uploads',
  components: {
    PiDependencies: () => import('@/components/Templates/PiDependencies/Pi-Dependencies.vue'),
    MicrophoneRecorder: () => import('@/components/Templates/Microphone/microphone-recorder.vue'),
  },
  props: [
    'value',
    'multiple',
    'ownerType',
    'ownerId',
    'fileId',
    'formats',
    'hideChoice',
    'hideOptions',
    'hideDetails',
    'appendToBody',
    'maxSize',
  ],
  data () {
    return {
      isMicrophoneVisible: false,
      minMediaForSeachBar: 12,
      search: '',
      currentPage: 1,
      pageSize: 20,
      total: 0,
      message: null,
      messageType: 'info',
      fileList: [],
      rules: {
        externalUrl: { validator: this.validateUrl, trigger: 'change' },
        name: { validator: this.validateName, trigger: 'change' },
      },
      isVisible: false,
      item: {
        name: '',
        tags: [],
        externalUrl: '',
      },
      libraries: [],
      librariesOwners: [],
      activeLibraryId: '',
      activeLibraryFiles: '',
      activeLibrary: {},
      activeFile: {},
      fileOptions: {
        size: '',
      },
      fileSize: null,
      format: null,
      isTincan: false,
      uploadUrl: `${apiConfiguration.basePath}files/upload`,
      deps: [],
      showDialogDependencies: false,
      loadingError: false,
      dialogVisible: false,
    }
  },
  computed: {
    context () {
      const mapper = api.getMapperByName(this.ownerType)
      if (!mapper || !mapper.useContextForItsLibraries) {
        return false
      }
      if (this.is_generic) return null
      return this.contextId
    },
    filteredActiveLibraryFiles () {
      if (this.activeLibraryFiles && this.activeLibraryFiles.length > this.minMediaForSeachBar) {
        return filter(this.activeLibraryFiles, (el) => {
          if (!this.search) {
            return true
          }
          const { $regex, option } = reg.getPermissiveRegFromString(this.search)
          const searchRegexp = new RegExp($regex, option)
          return searchRegexp.test(el.name)
        })
      } else {
        return this.activeLibraryFiles
      }
    },
    token () {
      return window.localStorage.getItem('id_token')
    },
    action () {
      return `${this.uploadUrl}?access_token=${this.token}&libraryId=${this.activeLibraryId}&tincan=${this.isTincan}`
    },
    getLibraryType () {
      return this.libraryType
    },
    getFileData () {
      if (includes(['video/mp4'], this.format) && this.fileSize) {
        return {
          file_size: this.fileSize,
        }
      }
      return {}
    },
    getFormats () {
      // Si format est défini
      if (isArray(this.formats)) {
        return this.formats
      }
      // Sinon on récupère les types par défaut
      return api.getSchema('AppFile').pi.defaultTypes
    },
    allowedFormats () {
      return api.getSchema('AppFile').pi.typesToMimeTypes(this.getFormats)
    },
    getFileUploadFormats () {
      return this.getFormats.filter((format) => ['tincan', 'image', 'video', 'audio', 'pdf'].includes(format))
    },
    allowedFileUploadFormats () {
      return api.getSchema('AppFile').pi.typesToMimeTypes(this.getFileUploadFormats)
    },
    shouldShowUploadFile () {
      return this.getFileUploadFormats.length > 0
    },
    shouldShowRecordAudio () {
      return this.getFormats.includes('audio')
    },
    shouldShowExternalLink () {
      return this.getFormats.includes('external')
    },
  },
  created () {
    this.setDefaultItems()
  },
  methods: {
    hideExternalUrlModal () {
      this.dialogVisible = false
    },
    showExternalUrlModal () {
      this.dialogVisible = true
      this.setDefaultItems()
    },
    clearSearch () {
      this.search = ''
      this.getActiveLibrary()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getActiveLibrary()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getActiveLibrary()
    },
    onDelete (file) {
      const inputRegex = new RegExp(`^${this.$t('actions.delete.confirmWord')}$`)
      this.$prompt(`${this.$t('actions.confirm.byInput')} ${this.$t('actions.delete.confirmWord')}`, this.$t('actions.delete.title'), {
        confirmButtonText: this.$t('actions.confirm.action'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$t('actions.cancel.action'),
        inputPattern: inputRegex, // /^SUPPRIMER$/,
        inputErrorMessage: 'Saisie invalide',
      }).then(() => {
        this.deleteFile(file)
      })
    },
    getActiveLibrary () {
      if (this.activeLibraryId) {
        const mimeTypeFilter = {}
        if (this.allowedFormats) {
          mimeTypeFilter.type = { $in: this.allowedFormats }
        }
        if (this.getFormats.includes('external')) {
          mimeTypeFilter.type = { $in: [...(this.allowedFormats || []), 'external'] }
        }
        if (this.getFormats.includes('tincan')) {
          mimeTypeFilter.type = { $in: [...(this.allowedFormats || []), 'tincan'] }
        }
        if (this.search) {
          const { $regex, option } = reg.getPermissiveRegFromString(this.search)
          mimeTypeFilter.name = { $regex, $options: option }
        }
        this.loadingError = false
        promiseProps({
          library: api.find('Library', this.activeLibraryId, { force: true }),
          // Chargement des dépendances
          libraryFiles: api.findAll('AppFile', {
            libraryId: this.activeLibraryId,
            filter: mimeTypeFilter,
            options: {
              skip: (this.currentPage - 1) * this.pageSize,
              limit: this.pageSize,
              sort: { createdAt: -1 },
            },
          }, { force: true, raw: true }).then((data) => {
            this.total = +data.headers['x-model-count'] ?? data.data.length
            return data.data
          }),
        }).then(results => {
          this.$set(this, 'activeLibrary', results.library)
          this.$set(this, 'activeLibraryFiles', sortBy(results.libraryFiles, [(file) => +moment(file.createdAt) * -1]))
        }).catch(error => {
          this.loadingError = 'Une erreur est survenue lors de la récupération de la liste des fichiers'
          console.error(error)
        })
      } else {
        api.findAll('Library', {
          filter: {
            ownerType: this.ownerType,
            ownerId: this.ownerId,
          },
          options: { limit: 1 },
        }, { force: true }).then(records => {
          if (records.length > 0) {
            this.activeLibraryId = get(records, '0.id', false)
            this.getActiveLibrary()
          } else if (this.ownerType && this.ownerId) {
            api.create('Library', {
              ownerType: this.ownerType,
              ownerId: this.ownerId,
            }).then(l => {
              this.getActiveLibrary()
            })
          }
        }).catch(error => {
          console.error(error)
        })
      }
    },
    getLabel (type, id) {
      const typeTitle = this.$tc(`models.${type}.title`, 1)
      const owner = api.get(type, id)
      if (hasIn(owner, 'title')) {
        return owner.title
      } else if (hasIn(owner, 'name')) {
        return owner.name
      } else if (hasIn(owner, 'displayName')) {
        return owner.displayName
      }
      return `${typeTitle} - ${id}`
    },
    sendSoundFile (soundFile) {
      const e = new Event('drop')
      e.dataTransfer = { files: [soundFile] }
      this.$el.querySelector('.el-upload-dragger').dispatchEvent(e)
    },
    handleBeforeUpload (rawFile) {
      if (!includes(this.allowedFormats, rawFile.type)) {
        return true
      }
      this.format = rawFile.type
      return new Promise((resolve, reject) => {
        this.isTincan = false
        if (includes(['video/mp4'], rawFile.type)) {
          // Set the size of the file to upload a video
          this.fileSize = rawFile.size
          resolve(rawFile)
        } else if (includes(['application/zip', 'application/x-zip-compressed'], rawFile.type)) {
          this.isTincan = true
          resolve(rawFile)
        } else if (includes(['audio/mpeg', 'audio/mp3'], rawFile.type)) {
          resolve(rawFile)
        } else if (includes(['application/pdf'], rawFile.type)) {
          resolve(rawFile)
        } else {
          try {
            // JPG & PNG, les autres formats ne seront pas optimisés
            const im = window.URL.createObjectURL(rawFile)
            ImageMethods.getCanvasFromUrl(im, (canvas, file) => {
              const coef = Math.min(this.maxSize / canvas.height, this.maxSize / canvas.width, 1)
              if (coef < 1) {
                const wd = Math.floor(canvas.width * coef)
                const hd = Math.floor(canvas.height * coef)
                const to = document.createElement('canvas')
                to.width = wd
                to.height = hd
                const toCtx = to.getContext('2d')
                toCtx.mozImageSmoothingEnabled = true
                toCtx.webkitImageSmoothingEnabled = true
                toCtx.msImageSmoothingEnabled = true
                toCtx.imageSmoothingEnabled = true
                toCtx.imageSmoothingQuality = 'high'
                toCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, wd, hd)
                canvas = to
              }
              canvas.toBlob((blobImage) => {
                const file = new window.File([blobImage], rawFile.name, { type: blobImage.type })
                file.uid = rawFile.uid
                resolve(file)
              }, rawFile.type, compression[rawFile.type] || 100)
            })
          } catch (err) {
            reject(err)
          }
        }
      })
    },
    handleSuccess (response, file, fileList) {
      // all uploads are OK
      // var fileResource = api.createRecord('AppFile', response)
      if (filter(fileList, property('status')).length === fileList.length) {
        // this.previewFile(fileResource)
        this.getActiveLibrary()
        this.$refs.upload.clearFiles()
      }
    },
    handleError (error, file, fileList) {
      console.error('[ UPLOAD ] erreur lors du transfert', { error, file, fileList })
      this.messageType = 'error'
      this.message = JSON.stringify(error)
    },
    handlePreview (file) {
      console.info('[ UPLOAD ] aperçu', { file })
    },
    selectEvent (file) {
      const options = cloneDeep(this.fileOptions)
      this.$nextTick(() => {
        this.$emit('select', file.id, file, options)
      })
      this.close()
    },
    submitUpload (formName) {
      this.$refs.upload.submit()
    },
    previewFile (file) {
      this.$set(this, 'activeFile', file)
      if (this.isContextAdmin) {
        this.getDeps()
      }
    },
    getDeps () {
      this.deps = []
      const { id } = this.activeFile
      promiseProps({
        bricks: api.findAll('CourseBlock', { filter: { attachmentIds: { $in: [id] } }, options: { skip: 0, limit: 10 } }, { force: true }),
        users: api.findAll('AppUser', { filter: { avatarId: { $in: [id] } }, options: { skip: 0, limit: 10 } }, { force: true }),
      }).then(results => {
        results.bricks = results.bricks.map((brick) => ({
          text: brick.title,
          value: brick.id,
          record: brick,
        }))
        if (results.bricks.length > 0) {
          this.deps.push({
            title: 'models.CourseBlock.title',
            list: results.bricks,
            total: results.bricks.length,
          })
        }
        results.users = results.users.map((user) => ({
          text: user.email,
          value: user.id,
          record: user,
        }))
        if (results.users.length > 0) {
          this.deps.push({
            title: 'models.AppUser.title',
            list: results.users,
            total: results.users.length,
          })
        }
      }).catch(error => {
        console.error(error)
      })
    },
    warnDeps () {
      if (isArray(this.deps) && this.deps.length > 0) {
        this.showDialogDependencies = true
        return false
      }
      return true
    },
    choiceFile (file) {
      this.selectEvent(file)
    },
    deleteFile (file) {
      const { id } = file
      file.destroy()
        .then(() => {
          this.activeFile = {}
          this.getActiveLibrary()
          this.$emit('delete', id)
        })
    },
    loadLibraries () {
      if (this.multiple) {
        const filter = { ownerType: this.ownerType }
        if (this.context === null || isString(this.context)) {
          filter.contextId = this.context
        }
        api.findAll('Library', { filter }, { force: true }).then(libraries => {
          const ownerIds = compact(map(libraries, 'ownerId'))
          if (!isEmpty(ownerIds)) {
            api.findAll(this.ownerType, { filter: { _id: { $in: ownerIds } } }, { force: true }).then(records => {
              this.$set(this, 'librariesOwners', records)
              this.$set(this, 'libraries', sortBy(libraries, [(lib) => this.getLabel(lib.ownerType, lib.ownerId).toLowerCase()]))
            })
          }
        })
      } else {
        const record = api.get(this.ownerType, this.ownerId)
        if (record) {
          this.$set(this, 'librariesOwners', [record])
        } else {
          api.find(this.ownerType, this.ownerId, { force: true }).then(record => {
            this.$set(this, 'librariesOwners', [record])
          })
        }
      }
    },
    close () {
      this.isVisible = false
      Object.assign(this.$data, this.$options.data.call(this))
    },
    open () {
      window._vm_up = this
      this.loadLibraries()
      this.getActiveLibrary()
    },
    setDefaultItems () {
    },
    validateUrl (rule, value, callback) {
      if (!value) {
        return callback(new Error('Veuillez saisir une URL'))
      } else if (!value.match(/^(https):\/\/[^\s$.?#].[^\s]*$/gm)) {
        return callback(new Error('Veuillez saisir une URL valide (https://...)'))
      }
      return callback()
    },
    validateName (rule, value, callback) {
      if (!value) {
        return callback(new Error('Veuillez saisir un nom de ressource'))
      }
      return callback()
    },
    onSubmitExternalUrl () {
      this.$refs.item.validate(valid => {
        if (valid) {
          axios.post(this.uploadUrl, {
            ...this.item,
          }, {
            params: {
              libraryId: this.activeLibraryId,
              access_token: this.token,
            },
          }).then(() => {
            this.dialogVisible = false
            this.$refs.item.resetFields()
            this.getActiveLibrary()
          })
        }
      })
    },
  },
}
</script>
<style lang="sass">
.el-upload__text
  line-height: 1.2em
</style>
