import moment from 'moment'
import { concat, forOwn, isArray, isString } from 'lodash'
const state = () => ({
  alerts: [],
})

// GETTERS
const getters = {
  getAlerts: (state) => {
    if (state.alerts.length > 0) {
      state.alerts.forEach((alert) => {
        if (alert.death && moment().diff(alert.death) >= 0) {
          state.alerts = state.alerts.filter((el) => {
            return el.time !== alert.time
          })
        }
        if (alert.refresh) {
          state.alerts = state.alerts.filter((el) => {
            return el.time !== alert.time
          })
        }
        alert.printed = true
      })
    }
    return state.alerts
  },
}

// ACTIONS
const actions = {
  addAlert ({ commit, dispatch, state }, alert) {
    if (alert.noDuplicate && alert.message && state.alerts.some(a => a.message === alert.message)) {
      return
    }
    commit('addAlert', alert)
    if (alert.duration) {
      window.setTimeout(() => {
        commit('refreshAlerts')
      }, alert.duration + 1)
    }
  },
}
// MUTATIONS
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  addAlert (state, alert) {
    alert.time = moment()
    alert.printed = false
    if (!alert.onClose) alert.onClose = () => true
    if (alert.duration > 0 && alert.persist) {
      alert.death = moment(moment(alert.time).add(alert.duration, 'seconds'))
    }
    const chan = state.alerts

    const contentKeys = ['description', 'message', 'context']
    if (alert.mergeBy && contentKeys.includes(alert.mergeBy)) {
      const toMergeKeys = contentKeys.filter(el => el !== alert.mergeBy)
      const mergeKey = alert.mergeBy
      const existingAlert = chan.find(el => el[mergeKey] === alert[mergeKey])
      if (existingAlert) {
        toMergeKeys.forEach(key => {
          if (isString(existingAlert[key]) && isString(alert[key]) && existingAlert[key] !== alert[key]) {
            existingAlert[key] += ` ${alert[key]}`
          } else if (isArray(existingAlert[key]) && isArray(alert[key])) {
            existingAlert[key] = concat(existingAlert[key], alert[key])
          }
        })
      } else {
        chan.push(alert)
      }
    } else {
      chan.push(alert)
    }
  },
  addNotification (state, notification) {
    notification.customClass = 'notification-offset'
    notification.context.$notify(notification)
    state.alerts.push({ refresh: true, time: moment() })
  },
  deleteAlert (state, alert) {
    state.alerts = state.alerts.filter((el) => {
      return el.time !== alert.time
    })
  },
  removeNonPersistantAlerts (state) {
    for (let i = state.alerts.length - 1; i >= 0; i--) {
      const alert = state.alerts[i]
      if (alert.printed && alert.persist !== true) {
        state.alerts.splice(i, 1)
      }
    }
  },
  refreshAlerts (state) {
    for (let i = state.alerts.length - 1; i >= 0; i--) {
      const alert = state.alerts[i]
      if (alert.duration && alert.time + alert.duration < moment()) {
        state.alerts.splice(i, 1)
      }
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
