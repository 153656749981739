<template lang="pug">
  component.pointer-cursor.teaser.flex.back-button(
    :is="componentType"
    :href="href",
    :rel="rel"
    :target="target"
    @click='$emit("click")'
  )

    i(class="el-icon-pi-fleche-gauche")
    .text-align-left
      slot

</template>
<script>
export default {
  name: 'back-button',
  props: {
    href: {
      type: String,
    },
    target: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  computed: {
    rel () {
      return this.target ? 'noopener, noreferrer' : undefined
    },
    componentType () {
      return !this.href ? 'div' : 'a'
    },
  },
}
</script>
<style lang="sass">
.back-button
  &.teaser > div
    min-width: inherit !important
</style>
