import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'
import { uniq, without } from 'lodash'

class CompanyInvitationCodeRecord extends Record {
  print () {
    this.constructor.mapper.download(this.id, {
      params: {
        access_token: window.localStorage.getItem('id_token'),
      },
      request: ({ url, params }) => {
        const fullurl = new URL(url)
        Object.keys(params).forEach(key => {
          fullurl.searchParams.set(key, params[key])
        })
        window.open(fullurl.href, `CompanyInvitationCode_${this.id}`)
        throw (new Error('abort'))
      },
    }).catch(() => { /* silent */ })
  }

  removeFromClass (classId) {
    this.classIds = without(this.classIds || [], classId)
    if (this.classesUnlockingOptions && this.classesUnlockingOptions[classId]) {
      delete this.classesUnlockingOptions[classId]
    }
    return this
  }

  addToClass (classId) {
    const classIds = (this.classIds || [])
    classIds.push(classId)
    this.classIds = uniq(classIds)
    return this
  }

  initLesson (classId, courseId) {
    if (!classId || !courseId) {
      console.error({ classId, courseId })
    }
    this.classesUnlockingOptions = this.classesUnlockingOptions || {}
    this.classesUnlockingOptions[classId] = this.classesUnlockingOptions[classId] || {}
    this.classesUnlockingOptions[classId].coursesUnlockingOptions = this.classesUnlockingOptions[classId].coursesUnlockingOptions || {}
    this.classesUnlockingOptions[classId].coursesUnlockingOptions[courseId] = this.classesUnlockingOptions[classId].coursesUnlockingOptions[courseId] || {}
    this.classesUnlockingOptions[classId].coursesUnlockingOptions[courseId] = {
      enabledSectionIds: [],
      state: 'HIDDEN',
      usedCurrency: null,
      ...this.classesUnlockingOptions[classId].coursesUnlockingOptions[courseId],
    }
    return this
  }

  getLesson (classId, courseId) {
    this.initLesson(classId, courseId)
    return this.classesUnlockingOptions[classId].coursesUnlockingOptions[courseId]
  }
}

const CompanyInvitationCodeSchema = new Schema({
  title: 'CompanyInvitationCodeSchemaTitle',
  description: 'Schema for CompanyInvitationCode Records.',
  type: 'object',
  additionalProperties: true,
  properties: {
    code: {
      type: 'string',
    },
    status: {
      type: 'string',
      enum: [
        'AVAILABLE',
        'EXPORTED',
        'USED',
        'FAILED',
      ],
      additionalItems: true,
    },
    companyId: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
})

export const CompanyInvitationCode = store.defineMapper('CompanyInvitationCode', {
  schema: CompanyInvitationCodeSchema,
  endpoint: 'company-invitation-codes',
  paths: {
    edit: 'CompanyInvitationCodeEdit',
  },
  relations: {
    belongsTo: {
      AppUser: {
        localField: 'user',
        foreignKey: 'userId',
      },
    },
    hasMany: {
      Class: {
        loadAllDatasWhenEditing: false,
        localField: 'classes',
        localKey: 'classIds',
      },
    },
  },
  recordClass: CompanyInvitationCodeRecord,
})

addActions({
  download: {
    pathname: 'download',
    method: 'GET',
  },
})(CompanyInvitationCode)
