const Mentions = () => import('./Mentions.vue')

export default [{
  path: '/legal/mentions',
  name: 'LegalMentions',
  meta: {
    permission: '',
  },
  props: {
    legal: 'cgu',
  },
  component: Mentions,
},
{
  path: '/legal/protection-des-donnees',
  name: 'LegalDataProtection',
  meta: {
    permission: '',
  },
  props: {
    legal: 'rgpd',
  },
  component: Mentions,
},
]
