import { forOwn } from 'lodash'

const state = () => ({
  previewDialogId: 'dialogCorrectionVisible',
  visible: false,
  datas: {},
})

// getters
const getters = {
  isLessonPageCorrectionVsible (state) {
    return state.visible
  },
}

// actions
const actions = {
  SEND_DATA_TO_LESSON_PAGE_CORRECTION ({ state, rootGetters }) {
    window.document.querySelector(`#${state.previewDialogId}`).contentWindow.postMessage(state.datas, rootGetters['context/get']('hosts.frontoffice'))
  },
  SHOW_LESSON_PAGE_CORRECTION ({ dispatch, state }, datas) {
    state.visible = true
    state.datas = datas
    window.addEventListener('message', (event) => {
      dispatch('lessonPageIframeIsLoaded', event)
    })
  },
  HIDE_LESSON_PAGE_CORRECTION ({ dispatch, state }) {
    state.visible = false
    state.datas = {}
    window.removeEventListener('message', (event) => {
      dispatch('lessonPageIframeIsLoaded', event)
    })
  },
  lessonPageIframeIsLoaded ({ dispatch, rootGetters }, event) {
    if (~event.origin.indexOf(rootGetters['context/get']('hosts.frontoffice')) && event.data === 'mounted') {
      window.removeEventListener('message', event => {
        dispatch('lessonPageIframeIsLoaded', event)
      })
      dispatch('SEND_DATA_TO_LESSON_PAGE_CORRECTION')
    }
  },
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
