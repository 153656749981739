import store from '@/api'
import { isUndefined, without } from 'lodash'
import { EVERY_ROLES, ROLES } from '@/api/enums/roles'
export default {
  data: () => ({}),
  methods: {
    $_can (value) {
      return this.$store.getters.CAN(value)
    },
    $_access (value) {
      if (isUndefined(value)) {
        return this.$store.getters.acl
      }
      return this.$store.commit('ACCESS', value)
    },
  },
  computed: {
    getPermissions () {
      return this.$store.getters.getPermissions
    },
    isConnecting () {
      return this.$store.getters.isConnecting || false
    },
    currentUser () {
      return this.$store.getters.getCurrentUser
    },
    currentUserRecord () {
      const currentUserId = this.$store.getters.getCurrentUser.id
      this.$store.getters.DSAppUser[currentUserId]
      return store.get('AppUser', currentUserId)
    },
    currentDomain () {
      return this.$store.getters.getCurrentDomain
    },
    currentCompanyId () {
      return this.$store.getters.getCurrentCompanyId
    },
    currentCompany () {
      const { currentCompanyId } = this
      if (currentCompanyId) {
        this.$store.getters.DSCompany[currentCompanyId]
        return store.get('Company', currentCompanyId)
      }
      return {}
    },
    currentWallet () {
      if (this.currentCompany && !this._isEmpty(this.currentCompany) && this.currentCompany.walletInUse && this.currentCompany.walletInUse.id) {
        this.$store.getters.DSCompany[this.currentCompanyId]
        const walletId = this.currentCompany.walletInUse.id
        this.$store.getters.DSWallet[walletId]
        return store.get('Wallet', walletId)
      }
    },
    currentCurrency () {
      if (this.currentCompany && !this._isEmpty(this.currentCompany) && this.currentCompany.currencyInUse) {
        return this.currentCompany.currencyInUse
      }
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    isOnlySupervisor () {
      return !this.isContextAdmin &&
        this.isClient &&
        without(EVERY_ROLES, ROLES.STUDENT, ROLES.SUPERVISOR).every(role => !this.$_can(`client_${role}`)) &&
        this.$_can('client_SUPERVISOR')
    },
    isAdmin () {
      return this.$_can('grains')
    },
    isSuperAdmin () {
      return this.$_can('APP_superadmin')
    },
    isContextAdmin () {
      return this.$_can('APP_superadmin') || this.$_can('grains') || this.$_can('APP_contextAdmin')
    },
    isClient () {
      return this.$_can('client') && this.$store.getters.getPermissions.length > 0
      // return this.$store.getters.isClient
    },
  },
}
