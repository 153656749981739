<template lang="pug">
.pi-id-card(v-if='viewModal',)
  el-dialog(
    :visible.sync="viewModal",
    custom-class='medium')
    .el-dialog__title(slot="title")
      span Informations utilisateur
      ui-globals-actions.inline(hints="idCard")
    el-row.pi-id-card-header(:gutter="20")
      el-col(:span="18")
        el-row.civilInfo(:gutter="20")
          el-col.name(:span="12")
            template(v-if="CAN_USE_INTERNAL_LOGIN")
              strong {{ $tc('attributes.username.title', 1) }} :&nbsp;
              p.lastname(v-if="subject.username") {{subject.username}}
              p.lastname(v-else) Non renseigné
              br
            strong {{ $tc('attributes.name.title', 1) }} :&nbsp;
            p.lastname(v-if="subject.lastname") {{subject.lastname | toUpperCase}}
            p.lastname(v-else) Non renseigné
            br
            strong {{ $tc('attributes.firstname.title', 1) }} :&nbsp;
            p.firstname(v-if="subject.firstname") {{subject.firstname | capitalize}}
            p.firstname(v-else) Non renseigné

          el-col(:span="12")
            template(v-if="subject.email && CAN_USE_INTERNAL_LOGIN")
              strong {{ $tc('attributes.email.title', 1) }} :&nbsp;
              p.email {{subject.email}}
              br
            template(v-if="subject.birthday")
              strong {{ $t('attributes.birthday.title') }} :&nbsp;
              p.birthday {{ subject.birthday | normalizedDate }}

        el-row.medium-margin-top(v-if="_get(subject, 'licenseExpiredAt', false)")
          el-col.inline-info.licence-expired-date
            strong
              i.el-icon-pi-LICENSES.little-margin-right
              | Date d'expiration de la licence :
            p {{ subject.licenseExpiredAt | momentFormat('L') }}
        el-row.medium-margin-top(v-if="_isNumber(totalLessonTimeAlive)")
          el-col.inline-info.time-spent
            strong Temps passé sur les Séquences :&nbsp;
            p {{ totalLessonTimeAlive | hourAndMinutes('s') }}

          el-col.inline-info.print-attestation
            p
              a.hover(
                style="color:#333;"
                @click="printAttestation"
              )
                strong Attestation de participation &nbsp;
                i.el-icon-fa-print

      el-col.badge(:span="6")
        PiBadge(v-if="piScoreBadge"
          :conf='piScoreBadge')

    el-tabs(v-if="!_isEmpty(tabs)"
      :value="tabs[0].name", @tab-click="clickHandler")
      el-tab-pane(
        v-for="(tab, index) in tabs"
        :key="index"
        :name="tab.name"
        :class="tab.name"
        :label="tab.label")

        user-log-book(
          :userId="subject.id"
          v-if="tab.name === 'logbook'"
        )

        pi-user-levels(
          :id="subject.id"
          v-if="tab.name === 'level'")

        template(v-if="seeStatistics")
          user-stats(
            :subject="subject"
            :competencies="competencies"
            v-if="tab.name === 'statistics'")

        template(v-if="seeSessions && tab.name === 'sessions'")

          div(v-if="sessionsIsLoading" v-loading="sessionsIsLoading" style="min-height:200px")

          template(v-for="(userClassFormation, cindex) in userClassesFormation")
            el-row(
              v-for='(classProgram, classProgramIndex) in userClassFormation.getClassPrograms()'
              :class='{ session: classProgramIndex === 0 }'
              :key="userClassFormation.id + '-' + classProgram.id"
            )
              el-col
                el-row.class(:gutter="20")
                  el-col(:span="1")
                    i(
                      v-if="_get(classProgram, 'program.iconId')",
                      :class="`el-icon-${_get(classProgram, 'program.iconId')}`"
                    )
                  el-col(:span="8")
                    strong {{ _get(classProgram, 'program.name', '-') }}

                  el-col(:span="10")
                    strong(v-show="classProgramIndex === 0") {{ userClassFormation.name }}

                  el-col(:span="3")
                    .text-align-right(v-show="classProgramIndex === 0")
                      | {{ userClassFormation.createdAt | momentFormat('MMMM YYYY') | capitalize }}

                  el-col(:span="2")
                    template(v-if="canEditClass(userClassFormation) && classProgramIndex === 0")
                      el-button(
                        style="float: right;"
                        @click='goToEditPath(userClassFormation)'
                        size="mini"
                        plain
                      ) {{ $t('actions.edit.action') }}
                el-row.lesson(
                  :gutter="20"
                  v-for="(lesson, lessonIndex) in getLessonForProgram(userClassFormation.lessons, classProgram.programId)"
                  v-if="lesson.userId === subject.id"
                  :key='lesson.id')
                  el-col.text-align-right(:span="9")
                    template(v-if="lesson.state === 'HIDDEN'")
                      span
                        small
                          em Verrouillé
                      i.little-margin-left.el-icon-fa-lock(
                        style="font-size: 11px;"
                      )
                    template(
                        v-else-if="lesson.scoring.global && (lesson.scoring.global.max > 0)"
                    )
                      span.score(
                        v-if="lesson.progress !== 'NONE'"
                        :class='scoreColor(getRatio(lesson.scoring.global), "tc-" )'
                        style="line-height: 0; vertical-align: -2px;"
                      )
                        | {{  Math.round(getRatio(lesson.scoring.global) * 100) }} / 100
                      i.tc-primary.little-margin-left(
                        style="font-size: 11px;"
                        :class="getLessonProgressIcon(lesson)"
                      )
                    template(v-else)
                      lesson-no-score.tc-primary
                      i.tc-primary.little-margin-left(
                        style="font-size: 11px;"
                        :class="getLessonProgressIcon(lesson)"
                        )
                  el-col(v-if="_hasIn(lesson, 'course.title')" :span="10") {{ lesson.course.title }}
                  el-col(v-else :span="10")
                    em Cette Séquence n'existe plus

        template(v-if="seeCertifications && tab.name === 'certifications'")
          div(v-if="sessionsIsLoading", v-loading="sessionsIsLoading", style="min-height:200px")
          template(v-for="(userClassCertification, cindex) in userClassesCertifications")
            el-row.session
              el-col
                el-row.class(:gutter="20")
                  el-col(:span="1")
                    i(
                      v-if="_get(userClassCertification, 'program.iconId')",
                      :class="`el-icon-${_get(userClassCertification, 'program.iconId')}`"
                    )
                  el-col(:span="8")
                    strong {{ _get(userClassCertification, 'program.name', '-') }}

                  el-col(:span="11")
                    strong {{ userClassCertification.name }}

                  el-col(:span="4")
                    .text-align-right
                      | {{ userClassCertification.createdAt | momentFormat('MMMM YYYY') | capitalize }}

                el-row.lesson(
                  :gutter="20"
                  v-for="(lesson, lessonIndex) in userClassCertification.lessons"
                  v-if="lesson.userId === subject.id"
                  :key='lesson.id')
                  el-col(:span="9")
                    .score.text-align-right(:class='scoreColor(getRatio(lesson.scoring.global), "tc-" )')
                      | {{  Math.round(getRatio(lesson.scoring.global) * 1000) }} / 1000
                  el-col(v-if="_hasIn(lesson, 'course.title')" :span="10") {{ lesson.course.title }}
                  el-col(v-else :span="10")
                    em Cette Séquence n'existe plus

        template(v-if="seeCategories && tab.name === 'jobs'")

          .categories
            .category.large-margin-bottom(v-for="(category, cindex) in categoriesWithScores")
              i(:class="`el-icon-${category.icon}`")
              h2.text-align-center {{category.title}}
              h3.score.text-align-right(:class="scoreColor(_get(subject, ['contextStats', 'lastRecord', 'computedScoring', 'categories', category.id], 0), 'tc-' )")
                | {{  Math.round(_get(subject, ['contextStats', 'lastRecord', 'computedScoring', 'categories', category.id], 0) * 100) }} / 100

</template>
<script>
import moment from 'moment'
import axios from 'axios'
import api from '@/api'
import utils from '@/helpers/utils.js'
import LessonNoScore from '@/components/Classes/Edit/Lesson/NoScore.vue'

import scoreMixin from '@/mixins/Score.js'
import { compact, filter, get, includes, isEmpty, isNull, isNumber, map, orderBy, template, uniqBy, without } from 'lodash'
import { EVERY_ROLES, ROLES } from '@/api/enums/roles'
import GetCourseCompetenciesMixin from '@/mixins/GetCourseCompetencies.js'

const webfonts = []

const embedStyles = document.querySelectorAll('style[type="text/css"]')
for (const u in embedStyles) {
  if (embedStyles[u].textContent && (embedStyles[u].textContent.indexOf('font-family: "pi') > -1 || embedStyles[u].textContent.indexOf('font-family: "Fa"') > -1)) {
    webfonts.push(embedStyles[u].textContent)
  }
}
export default {
  name: 'pi-id-card',
  components: {
    PiUserLevels: () => import('./PiUserLevels'),
    UserStats: () => import('./UserStats'),
    UserLogBook: () => import('./LogBook/UserLogBook.vue'),
    PiBadge: () => import('@/components/Templates/PiBadge/PiBadge.vue'),
    LessonNoScore,
  },
  filters: {
    normalizedDate (value) {
      return moment(value).format('Do MMMM YYYY')
    },
  },
  mixins: [
    scoreMixin,
    GetCourseCompetenciesMixin,
  ],
  props: [],
  data () {
    return {
      seeStatistics: false,
      seeSessions: true,
      seeCertifications: true,
      seeCategories: false,
      sessionsIsLoading: false,
    }
  },
  asyncComputed: {
    exercicesDone: {
      lazy: true,
      get () {
        if (!this.subject || !this.competencies || !this.viewModal) {
          return null
        }
        return get(this.subject, 'donePageCount.global', null)
      },
      default: null,
    },
    classes: {
      lazy: true,
      get () {
        let classes = []
        if (!this.viewModal || !this.subject.id) return []
        this.sessionsIsLoading = true
        return api.findAll('ClassUser',
          {
            filter: {
              userId: this.subject.id,
              role: ROLES.STUDENT,
            },
            order: 'createdAt',
            populate: {
              path: 'class',
              match: {
                contextId: this.contextId,
              },
              populate: [
                {
                  path: 'classUsers',
                  match: {
                    role: { $ne: ROLES.STUDENT },
                  },
                },
                {
                  path: 'classPrograms',
                  populate: {
                    path: 'program',
                    populate: { path: 'programCourses' },
                  },
                },
              ],
            },
          }, {
            force: true,
            raw: true,
          })
          .then(response => {
            const classuser = response.data
            classes = uniqBy(compact(map(classuser, 'class')), 'id')
            return api.findAll('Lesson', {
              filter: {
                contextId: this.contextId,
                userId: this.subject.id,
                companyId: this.currentCompanyId,
              },
              populate: { path: 'course' },
            }, { raw: true, force: true })
          }).then(() => {
            this.sessionsIsLoading = false
            return classes
          })
      },
      default: [],
    },
  },
  computed: {
    isStudent () {
      this.$store.getters.DSCompanyUser
      const where = {
        userId: this.userId,
        role: ROLES.STUDENT,
      }
      if (this.currentCompanyId) {
        where.companyId = this.currentCompanyId
      }
      const cu = api.filter('CompanyUser', {
        where,
      })
      return cu.length > 0
    },
    piScoreRatio () {
      return get(this.subject, 'contextStats.lastRecord.computedScoring.pi.competencies', 0)
    },
    piScoreBadge () {
      const scoreRatio = this.piScoreRatio
      return {
        level: this.getMedal(scoreRatio), // or, argent, bronze
        type: 'PI',
        done: this.getMedalAccomplished(scoreRatio),
        img: this.contextLogoBadge,
        size: 'xl',
      }
    },
    tabs () {
      const tabs = []

      tabs.push({
        name: 'level',
        label: this.$tc('words.level.title', 2),
      })
      if (this.hasUserStatsCategories) {
        tabs.push({
          name: 'jobs',
          label: `${this.$tc('models.CourseCategory.title', 2)}`,
        })
      }
      tabs.push({
        name: 'statistics',
        label: this.$tc('words.statistics.title', 1),
      })
      if (this.userClasses) {
        tabs.push({
          name: 'sessions',
          label: `${this.$tc('models.Program.alias', 2)}`,
        })
      }
      if (this.userClasses && this.contextAllowAccessToCertificationFeature) {
        tabs.push({
          name: 'certifications',
          label: `${this.$tc('models.Certification.alias', 2)}`,
        })
      }

      if (this.isStudent) {
        tabs.push({
          name: 'logbook',
          label: this.$tc('models.LogBook.title', 1),
        })
      }

      this.clickHandler(get(tabs, '0', {}))
      return tabs
    },
    hasUserStatisticsActivity () {
      const actions = get(this.subject, 'contextStats.lastRecord.dailyCount.global', 0)
      return actions > 0
    },
    hasUserStatisticsCategories () {
      const categories = get(this.subject, 'contextStats.lastRecord.computedScoring.categories', {})
      return categories && !isEmpty(categories)
    },
    categoriesWithScores () {
      const categoriesWithScores = filter(this.$store.getters.rootsCategories, category => {
        return isNumber(get(this.subject, ['contextStats', 'lastRecord', 'computedScoring', 'categories', category.id]))
      })
      return categoriesWithScores
    },
    categories () {
      return this.$store.getters.rootsCategories
    },
    competencies () {
      return this.getVisibleRootCompetencies
    },
    canPrintAttestation () {
      return true
    },
    lessonAccomplished () {
      this.$store.getters.DSLesson
      const lessons = api
        .filter('Lesson', {
          where: {
            userId: this.subject.id,
          },
          orderBy: [['startedAt', 'DESC']],
        })
        .filter(lesson => {
          return Boolean(lesson.class)
        })
        .filter(lesson => {
          return !(!isEmpty(lesson.certificationRef) || get(lesson, 'course.type.settings.certificate') === true)
        })
        .filter(lesson => {
          return lesson.state === 'SKIPPED' || lesson.progress === 'COMPLETED'
        })

      return lessons
        .map(lesson => ({
          icon: get(lesson, 'class.program.iconId', false),
          label: get(lesson, 'course.title') || lesson.courseId,
          date: moment(lesson.startedAt).format('MMMM YYYY'),
        }))
    },
    totalLessonTimeAlive () {
      return get(this.subject, 'timeAlive.global', null)
    },
    userClassesFormation () {
      return this.userClasses.filter(classe => !classe.setttings?.certification)
    },
    userClassesCertifications () {
      return this.userClasses.filter(classe => Boolean(classe.setttings?.certification))
    },
    userClasses () {
      const classes = (this.classes || []).map((o) => {
        o.lessons = orderBy(o.lessons, 'startedAt')
        return o
      })
      return orderBy(classes, 'createdAt', 'desc')
    },
    viewModal: {
      get () {
        return !isNull(this.userId)
      },
      set (value) {
        if (!value) {
          this.seeStatistics = false
          this.seeSessions = true
          this.seeCertifications = true
          this.seeCategories = false
          Object.assign(this.$data, this.$options.data.call(this))
          this.$store.commit('VIEWFORM_HIDE')
        }
      },
    },
    userId () {
      return this.$store.state.view.userId
    },
    subject () {
      this.$store.getters.DSAppUser[this.userId]
      const user = api.get('AppUser', this.userId) || {}
      return user
    },
  },
  watch: {
    userId: {
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.reloadSubjectDatas()
        }
      },
      immediate: true,
    },
  },
  mounted () {
    window._idCard = this
  },
  methods: {
    getLessonForProgram (lessons, programId) {
      return orderBy(lessons.filter(lesson => {
        const pc = lesson.getProgramCourse()
        if (!pc) return false
        return pc.programId === programId
      }), (lesson) => lesson.getProgramCourse()?.order)
    },
    getLessonProgressIcon (lesson) {
      let out = 'el-icon-'
      const { progress, state } = lesson
      switch (progress) {
        case 'NONE':
          out += 'fa-circle-o'
          break
        case 'ONGOING':
          out += 'fa-adjust'
          break
        case 'COMPLETED':
          out += 'fa-circle'
          break
      }
      if (state === 'LOCKED' || state === 'HIDDEN') {
        out += ' state-locked'
      }
      return `${out} progress-lesson-icon`
    },
    reloadSubjectDatas () {
      api.find('AppUser', this.userId, { force: true })
      const filter = {
        userId: this.userId,
        role: ROLES.STUDENT,
      }
      if (this.currentCompanyId) {
        filter.companyId = this.currentCompanyId
      }
      api.findAll('CompanyUser', {
        filter,
      }, { force: true })
    },
    printAttestation () {
      if (!this.canPrintAttestation) {
        window.alert('Il manque des informations pour imprimer l\'attestation')
        return
      }
      // la popin s'affiche au click, puis on modifie le contenu plus tard
      const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
      axios.get('/static/template/AttestationPresence.html').then(({ data }) => {
        const compiled = template(data, { imports: { moment } })
        return compiled({
          user: this.subject.displayName,
          birthday: get(this.subject, 'birthday') ? moment(this.subject.birthday).format('Do MMMM YYYY') : false,
          company: get(this.currentCompany, 'name') ? this.currentCompany.name : false,
          exercicesDone: this.exercicesDone,
          connexionTime: this.hourAndMinutes(this.totalLessonTimeAlive, 's'),
          now: moment().format('Do MMMM YYYY'),
          modules: this.lessonAccomplished,
          backofficeUrl: this.contextBackoffice,
          contextLogo: utils.getFilePath(this.$store.getters['context/get']('assets.logoId')),
          contextName: this.contextName,
          webfonts: webfonts.join('\n'),
        })
      }).then(html => {
        if (isNull(winPrint)) {
          throw new Error('Popup pas ouverte')
        }
        winPrint.document.write(html)
        window.setTimeout(function () {
          winPrint.focus()
          winPrint.print()
        }, 1000)
      }).catch(error => {
        winPrint.close()
        window.alert(`Erreur lors de la tentative d'impression : ${error}`)
      })
    },
    goToEditPath (userClass) {
      this.$router.push(userClass.editPath) // force Reload
      this.viewModal = false
    },
    canEditClass (userClass) {
      if (this.$_can('grains')) return true

      // CLIENTS
      if (this.currentCompany.id && get(userClass, 'companyId') !== this.currentCompany.id) return false
      // PROPRIETAIRE ET ADMINS
      if (this.$_can('client_OWNER')) return true
      if (this.$_can('client_ADMIN')) return true
      // TEACHERS
      const canEditThisLesson = filter(userClass.classUsers, (o) => {
        return o.userId === this.currentUser.id && includes(without(EVERY_ROLES, ROLES.STUDENT, ROLES.SUPERVISOR), o.role)
      }).length > 0
      return canEditThisLesson
    },
    getScoreForUserClass (userClass) {
      return Math.round(this.getRatio(userClass.getUserGlobalScore(this.subject.id)) * 100)
    },
    clickHandler (value) {
      if (value.name === 'statistics') {
        this.seeStatistics = true
      }
      if (value.name === 'sessions') {
        this.seeSessions = true
      }
      if (value.name === 'certifications') {
        this.seeCertifications = true
      }
      if (value.name === 'jobs') {
        this.seeCategories = true
      }
    },
  },
}
</script>
<style lang="sass">
.pi-id-card
  &-header
    .inline-info
      strong, p
        display: inline

  .el-dialog__title
    & > span
      display: inline-block
      line-height: 2rem
    .inline
      display: inline-block
      vertical-align: bottom
      .actions
        margin: 0 0 0 20px

  .el-col
    min-height: 1px
  .session
    i
      font-size: 15px
    padding-top: 5px
    margin-top: 10px
    padding-bottom: 2px
    .el-button
      margin-top: -3px

  .session:not(:first-child)
    border-top: 1px solid rgba(0, 0, 0, .1)
  h2
    margin-top: 1em
    margin-bottom: .5em
  .pi-level
    div
      text-align: center
  p
    -webkit-margin-before: 0
    -webkit-margin-after: 0
    &.firstname, &.lastname, &.email, &.birthday
      display: inline-block

  .pi-line-chart, .pi-bar-chart .user-stats
    position: relative
    .guide
      margin-left: 7px
      font-size: 14px
    .infoTotal
      font-size: 14px
    .emptyDate
      position: absolute
      top: 0
      right: 0
      width: 100%
      height: 100%
      background-color: rgba(0,0,0,.5)
      color: white
      display: flex
      justify-content: center
      align-items: center
      font-size: 30px
      border-radius: 4px
    .infoLimit
      text-align: center

  .categories
    display: flex
    justify-content: space-around
    flex-wrap: wrap
    .category
      display: flex
      flex-direction: column
      align-items: center
      width: 200px
      i
        font-size: 5em
</style>
