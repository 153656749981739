import { Record, Schema } from 'js-data'
import { addAction } from 'js-data-http'
import store from '@/api'
import { EVERY_ROLES } from '../enums/roles'

const CompanyUserSchema = new Schema({
  title: 'CompanyUserSchemaTitle',
  description: 'Schema for CompanyUser Records.',
  type: 'object',
  properties: {
    companyId: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Vous devez choisir une entreprise', trigger: 'blur' },
        ],
      },
    },
    userId: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Vous devez choisir un utilisateur', trigger: 'blur' },
        ],
      },
    },
    role: {
      type: 'string',
      enum: [...EVERY_ROLES],
      additionalItems: true,
      form: {
        rules: [
          { required: true, message: 'Vous devez choisir un rôle', trigger: 'blur' },
        ],
      },
    },
  },
  additionalProperties: true,
})

class CompanyUserRecord extends Record {
  async getResetPasswordUrl () {
    const token = window.localStorage.getItem('id_token')
    const response = await this.constructor.mapper.resetPassword(this.id, {
      params: {
        access_token: token,
      },
    })
    return response.data
  }
}

export const CompanyUser = store.defineMapper('CompanyUser', {
  schema: CompanyUserSchema,
  endpoint: 'company-users',
  relations: {
    belongsTo: {
      Company: {
        localField: 'company',
        localKey: 'companyId',
      },
      AppUser: {
        localField: 'user',
        localKey: 'userId',
      },
    },
  },
  recordClass: CompanyUserRecord,
})

addAction('resetPassword', {
  pathname: 'reset-password',
  method: 'POST',
})(CompanyUser)
