<template lang="pug">
  .pi-uploads-preview(:class="theme" :title="title")
    template(v-if="hasValue")
      img.d-none(v-if="isImage" :src="url" @error="onImageLoadError" @load="onImageLoad")
      template(v-if="isImage && isImagePreviewLoaded && !isImagePreviewFailed")
        //- AVEC PREVISU
        template(v-if="hasRatio")
          //- AVEC RATIO
          div.ratio.image(:style="imageWithRatioStyle", @click="emitClick")
            //- i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            //- i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")
        template(v-else)
          //- SANS RATIO
          div.image(:style="imageStyle", @click="emitClick")
            img.preview(:src="url")
        .pi-uploads-preview-actions
          el-button(
            v-if="canBeDeleted",
            @click="delEmit",
            type="danger",
            plain,
            size="mini",
            icon="el-icon-delete")
          el-button(
            v-if="canBeModified",
            @click="modifEmit",
            type="primary",
            plain,
            size="mini",
            icon="el-icon-edit")

      template(v-else)
        //- ICONES
        template(v-if="hasRatio")
          div.ratio.bg-colored(:style="noImageWithRatioStyle")
            div.pi-uploads-preview-wrapper
              div.preview
                img.preview(v-if="thumbnail" :src="thumbnail")
                i.preview-icon(v-if="thumbnail" :class="icon")
                i(v-if="!thumbnail" :class="icon")
                p.truncate {{ title }}
                i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")
        template(v-else)
          div.preview.bg-colored
            iframe.preview(
              v-if="htmlPlayer"
              :src="htmlPlayer")
            i(v-if="!htmlPlayer" :class="icon")
            p.truncate {{ title }}

            i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")

</template>
<script>
import store from '@/api'
import { includes } from 'lodash'

export default {
  name: 'pi-uploads-preview',
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      },
    },
    ratio: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String,
      default: 'light',
    },
    canBeDeleted: {
      type: Boolean,
      default: false,
    },
    canBeModified: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      thumbnail: null,
      htmlPlayer: null,
      isImagePreviewFailed: false,
      isImagePreviewLoaded: false,
    }
  },
  computed: {
    title () {
      const title = this.value?.title || this.value?.name
      if (!title) {
        return null
      }
      return this.fileToReadableString(title)
    },
    icon () {
      return `el-icon-${this.value?.icon}`
    },
    url () {
      return this.getFilePath(this.value?.id)
    },
    noImageWithRatioStyle () {
      return {
        'padding-bottom': `${this.ratioPadding}%`,
      }
    },
    imageWithRatioStyle () {
      return {
        'padding-bottom': `${this.ratioPadding}%`,
        'background-image': `url(${this.url})`,
      }
    },
    imageStyle () {
      return {
        position: 'relative',
      }
    },
    hasValue () {
      return !this._isEmpty(this.value)
    },
    isImage () {
      return includes(['image', 'external'], this._get(store.getSchema('AppFile').pi.mimeTypeToFileType, this.value?.type, 'file'))
    },
    isVideo () {
      return this._get(store.getSchema('AppFile').pi.mimeTypeToFileType, this.value?.type, 'file') === 'video'
    },
    hasRatio () {
      return Boolean(this.ratio)
    },
    ratioPadding () {
      return 100 * this.ratio
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.isImagePreviewLoaded = false
        this.isImagePreviewFailed = false
        this.loadThumbnail()
      },
    },
  },
  created () {
    window._vm_preview = this
  },
  methods: {
    delEmit () {
      this.$emit('delete')
    },
    modifEmit () {
      this.$emit('modif')
    },
    emitClick () {
      this.$emit('click', this.value)
    },
    onImageLoad () {
      this.isImagePreviewLoaded = true
    },
    onImageLoadError () {
      this.isImagePreviewFailed = true
    },
    async loadThumbnail () {
      this.htmlPlayer = null
      this.thumbnail = null
      if (!this.value || !this.value.id || !this.isVideo) {
        return
      }
      try {
        const { thumbnail, htmlPlayer } = await store.get('AppFile', this.value.id).infos()
        this.thumbnail = thumbnail
        this.htmlPlayer = htmlPlayer
      } catch (err) {
        this.htmlPlayer = null
        this.thumbnail = null
      }
    },
  },
}
</script>
