export default {
  getMatches: function (input, regex, index) {
    index || (index = 1)
    const matches = []
    let match = regex.exec(input)
    let limit = 100
    while (match != null && limit > 0) {
      matches.push(match[index])
      input = input.substring(match.index + match[index].length)
      match = regex.exec(input)
      limit--
      if (limit <= 0) {
        console.error('[ REGEXP ] boucle infinie dans getMatches')
      }
    }
    return matches
  },
  regexpFromString (query) {
    const flags = query.replace(/.*\/([gimy]*)$/, '$1')
    const pattern = query.replace(new RegExp(`^/(.*?)/${flags}$`), '$1')
    return new RegExp(pattern, flags)
  },
  getPermissiveRegFromString (query, sourceOnly = false) {
    let $regex = query.toLowerCase()
      .replace(/[[]]/g, '.')
      .replace(/b/g, '[bB]')
      .replace(/d/g, '[dD]')
      .replace(/f/g, '[fF]')
      .replace(/g/g, '[gG]')
      .replace(/h/g, '[hH]')
      .replace(/j/g, '[jJ]')
      .replace(/k/g, '[kK]')
      .replace(/l/g, '[lL]')
      .replace(/m/g, '[mM]')
      .replace(/n/g, '[nN]')
      .replace(/p/g, '[pP]')
      .replace(/q/g, '[qQ]')
      .replace(/r/g, '[rR]')
      .replace(/s/g, '[sS]')
      .replace(/t/g, '[tT]')
      .replace(/v/g, '[vV]')
      .replace(/w/g, '[wW]')
      .replace(/x/g, '[xX]')
      .replace(/y/g, '[yY]')
      .replace(/z/g, '[zZ]')
      .replace(/[eêèéë]/g, '[eêèéëEÊÉÈË]')
      .replace(/[aàäâ]/g, '[aàäâAÂÄÀ]')
      .replace(/[iîï]/g, '[iîïIÎÏ]')
      .replace(/[uûü]/g, '[uûüUÛÜ]')
      .replace(/[oôö]/g, '[oôöOÔÖ]')
      .replace(/[cç]/g, '[cçCÇ]')
      .replace(/[^a-zA-Z0-9\-_ eêèéëEÊÉÈËaàäâAÂÄÀiîïIÎÏuûüUÛÜoôöOÔÖcçCÇ[\]]{1}/g, '.')
      .replace(/[ ]{2,}/g, '.*')
      .replace(/[ ]/g, '.')
    $regex = sourceOnly ? { $regex } : { $regex: `${$regex}`, $options: 'gi' }
    return $regex
  },
}
