import { isString } from 'lodash'

export function duplicateToContext (code) {
  return (contextId, model = null) => {
    return this.constructor.mapper
      .cloneToContext(this.id, {
        data: { contextId },
        timeout: 60 * 60 * 1000, // 1h = 60 * 60 * 1000
      })
      .then((response) => {
        if (model === 'Program') return 'DONE'
        const msg = response.data
        if (!isString(msg) || !msg.includes('DONE')) {
          const error = new Error('An error occured during copy')
          error.response = {
            data: {
              errors: [
                { code },
              ],
            },
          }
          throw error
        }
        return msg
      })
  }
}
