const state = () => ({
  visibility: false,
})

// getters
const getters = {
  visibility (state) {
    return state.visibility
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  SET_VISIBILITY (state, value) {
    state.visibility = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
