<script>
import Tag from 'element-ui/packages/tag/src/tag.vue'
export default {
  name: 'tag',
  extends: Tag,
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },
}
</script>
