import axios from 'axios'
import { untrailingSlashIt } from '@/helpers/slash.js'
import store from '@/store/'
import api from '@/api'
import configuration from '@/api/configuration.js'
import { getQueryStringAsObject } from '@/helpers/params.js'
const query = getQueryStringAsObject()
const headers = {}

if (query.isGeneric === 'true') {
  window.sessionStorage.setItem('useGenericContext', true)
}

if (process.env.VUE_APP_NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
  if (configuration['X-Host']) {
    headers['X-Host'] = configuration['X-Host']
  }

  if (configuration['X-Origin']) {
    headers['X-Origin'] = configuration['X-Origin']
  }
}

const isGenericSessionStorage = (window.sessionStorage.getItem('useGenericContext') === 'true')
const request = axios.get(`${untrailingSlashIt(process.env.VUE_APP_API_GENERIC)}/contexts/current`, { headers })
  .then(({ data }) => {
    api.add('Context', data)
    return store.dispatch('context/setContextId', data.id)
  })
  .then(() => store.dispatch('context/useGenericContext', isGenericSessionStorage))
  .then(() => store.dispatch('currentUser'))

export default request
