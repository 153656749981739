
      import API from "!../../node_modules/.pnpm/style-loader@3.3.4_webpack@5.96.1/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/.pnpm/style-loader@3.3.4_webpack@5.96.1/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../node_modules/.pnpm/style-loader@3.3.4_webpack@5.96.1/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../node_modules/.pnpm/style-loader@3.3.4_webpack@5.96.1/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../node_modules/.pnpm/style-loader@3.3.4_webpack@5.96.1/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../node_modules/.pnpm/style-loader@3.3.4_webpack@5.96.1/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.0.14_@swc+helpers@0.5.13__webpack@5.96.1/node_modules/css-loader/dist/cjs.js??clonedRuleSet-45.use[1]!../../node_modules/.pnpm/webfonts-loader@7.5.2/node_modules/webfonts-loader/index.js??clonedRuleSet-45.use[2]!../../node_modules/.pnpm/babel-loader@8.4.1_@babel+core@7.26.0_webpack@5.96.1/node_modules/babel-loader/lib/index.js??clonedRuleSet-48.use!./PiCompetencies.font.js";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.0.14_@swc+helpers@0.5.13__webpack@5.96.1/node_modules/css-loader/dist/cjs.js??clonedRuleSet-45.use[1]!../../node_modules/.pnpm/webfonts-loader@7.5.2/node_modules/webfonts-loader/index.js??clonedRuleSet-45.use[2]!../../node_modules/.pnpm/babel-loader@8.4.1_@babel+core@7.26.0_webpack@5.96.1/node_modules/babel-loader/lib/index.js??clonedRuleSet-48.use!./PiCompetencies.font.js";
       export default content && content.locals ? content.locals : undefined;
