const routes = [
  {
    path: '/statistics/',
    name: 'Statistics',
    component: () => import('./Index.vue'),
  }, {
    path: '/statistics/programs/reviews',
    name: 'StatisticsProgramReviews',
    component: () => import('./ProgramsReviews.vue'),
  },
].map(r => {
  if (typeof r.meta === 'undefined') {
    r.meta = {
      permission: 'grains|context_CONTRIBUTOR',
      fail: '/',
    }
  }
  return r
})

export default routes
