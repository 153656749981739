import { Record, Schema } from 'js-data'
import store from '@/api'
import { get, includes, isArray, uniq } from 'lodash'
export const conditionalSchema = {
  conditions: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        ratio: {
          type: ['null', 'number'],
        },
        operator: {
          type: ['null', 'string'],
        },
      },
    },
  },
  oneOf: {
    type: 'boolean',
  },
}
export class ProgramCourseFactories {
  static get condition () {
    return {
      ratio: null,
      operator: null,
    }
  }

  static get emptyConditions () {
    return {
      conditions: [],
      oneOf: false,
    }
  }

  static get actionConditionalRange () {
    return {
      programCourseId: null,
      ...this.emptyConditions,
      conditions: [
        { ratio: 0.3333, operator: 'ge' },
        { ratio: 0.6666, operator: 'lt' },
      ],
    }
  }

  static get actionConditionalAutomatic () {
    return {
      programCourseId: null,
      ...this.emptyConditions,
      conditions: [
        { ratio: 0, operator: 'ge' },
      ],
    }
  }

  static get actionInteractive () {
    return {
      label: '',
      chosen: null,
      choices: [this.actionInteractiveResponse],
      ...this.emptyConditions,
    }
  }

  static get actionInteractiveResponse () {
    return {
      action: {
        target: null,
        ...this.emptyConditions,
      },
      response: '',
    }
  }
}

const ProgramCourseSchema = new Schema({
  title: 'ProgramCourseSchemaTitle',
  description: 'Schema for ProgramCourse Records.',
  type: 'object',
  properties: {
    contextId: {
      type: ['null', 'string'],
    },
    programId: {
      type: 'string',
    },
    courseId: {
      type: 'string',
    },
    order: {
      type: 'integer',
    },
    settings: {
      type: 'object',
    },
    isEndOfProgram: {
      type: 'boolean',
    },
    actions: {
      type: 'object',
      properties: {
        conditional: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              programCourseId: {
                type: 'string',
              },
              ...conditionalSchema,
            },
          },
        },
        interactive: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              label: {
                type: 'string',
              },
              chosen: {
              },
              choices: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    action: {
                      type: 'object',
                      properties: {
                        target: {
                          type: ['string', 'null'],
                        },
                        ...conditionalSchema,
                      },
                    },
                    response: {
                      type: ['string', 'null'],
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})

export class ProgramCourseRecord extends Record {
  static isAutomaticCondition (condition) {
    const conditions = condition?.conditions || []
    if (isArray(conditions) && conditions.length !== 1) {
      return false
    }
    const isGreaterOrEqual = conditions[0].operator === 'ge'
    const isRatioSetToZero = conditions[0].ratio === 0
    return isGreaterOrEqual && isRatioSetToZero
  }

  static isRangeCondition (condition) {
    const conditions = condition?.conditions || []
    if (isArray(conditions) && conditions.length !== 2) {
      return false
    }
    const includesAGreater = ['ge', 'gt'].includes(conditions[0].operator)
    const includesALower = ['le', 'lt'].includes(conditions[1].operator)
    return includesAGreater && includesALower
  }

  get editCoursePath () {
    return this.course.getEditLink({ programId: this.programId }).href
  }

  get interactiveItems () {
    return get(this.actions, 'interactive', [])
  }

  get previousOpeningConditions () {
    return this.getOpeningConditions(true)
  }

  get openingConditions () {
    return this.getOpeningConditions(false)
  }

  get previousOpeningInteractiveActions () {
    return this.getOpeningInteractiveActions(true)
  }

  get openingInteractiveActions () {
    return this.getOpeningInteractiveActions(false)
  }

  get seemsDeleted () {
    return typeof this.course === 'undefined'
  }

  get courseCompanyId () {
    return this.course?.companyId || null
  }

  get courseCompany () {
    return this.course?.company || null
  }

  get hasOpenerByScore () {
    return isArray(this.actions?.conditional) && this.actions.conditional.length > 0
  }

  get hasOpener () {
    return this.hasOpenerByScore || this.openingInteractiveActions.length > 0
  }

  get hasError () {
    return [
      this.seemsDeleted,
    ].some(Boolean)
  }

  get errorTranslations () {
    const errors = []
    if (this.seemsDeleted) {
      errors.push({
        path: 'models.ProgramCourse.errors.seemsDeleted',
        qty: 1,
        args: {},
      })
    }
    return errors
  }

  getOpeningConditions (usePrevious = false) {
    const openingConditions = []
    const programCourses = store.filter('ProgramCourse', { programId: this.programId })
    for (let programCourse of programCourses) {
      if (usePrevious) {
        programCourse = programCourse.previous()
      }
      if (!programCourse?.actions?.conditional) {
        continue
      }
      for (const conditional of programCourse.actions.conditional) {
        if (conditional.programCourseId === this.id) {
          openingConditions.push(conditional)
        }
      }
    }
    return openingConditions
  }

  get openingConditionsRefs () {
    const openingConditions = []
    const programCourses = store.filter('ProgramCourse', { programId: this.programId })
    for (const from of programCourses) {
      if (!from.actions?.conditional) {
        continue
      }
      for (const condition of from.actions.conditional) {
        if (condition.programCourseId === this.id) {
          openingConditions.push({ condition, from })
        }
      }
    }
    return openingConditions
  }

  getOpeningInteractiveActions (usePrevious = false) {
    const openingInteractiveActions = []
    const programCourses = store.filter('ProgramCourse', { programId: this.programId })
    for (let programCourse of programCourses) {
      if (usePrevious) {
        programCourse = programCourse.previous()
      }
      if (!programCourse.actions || !programCourse.actions.interactive) {
        continue
      }
      const interactives = programCourse.actions.interactive
      for (const interactiveItem of interactives) {
        if (interactiveItem.choices && interactiveItem.choices.length) {
          for (const choice of interactiveItem.choices) {
            if (choice?.action?.target === this.id) {
              openingInteractiveActions.push({
                interactiveLabel: interactiveItem.label,
                choiceResponse: choice.response,
                from: programCourse,
              })
            }
          }
        }
      }
    }
    return openingInteractiveActions
  }

  depthPath (filter = []) {
    let path = [this.id]
    const programCourses = store.filter('ProgramCourse', { programId: this.programId })
    for (const programCourse of programCourses) {
      if (includes(filter, programCourse.id)) {
        continue
      }
      if (!programCourse.actions || !programCourse.actions.interactive) {
        continue
      }
      let found = false
      const interactives = programCourse.actions.interactive
      for (const interactiveItem of interactives) {
        if (interactiveItem.choices && interactiveItem.choices.length) {
          for (const choice of interactiveItem.choices) {
            if (choice.target === this.id) {
              found = true
              break
            }
          }
        }
        if (found) break
      }
      if (found) {
        path = uniq([...path, ...programCourse.depthPath([...path])])
      }
    }
    return path
  }
}
export const ProgramCourse = store.defineMapper('ProgramCourse', {
  schema: ProgramCourseSchema,
  endpoint: 'program-courses',
  relations: {
    belongsTo: {
      Program: {
        loadAllDatasWhenEditing: false,
        localField: 'program',
        foreignKey: 'programId',
      },
      Course: {
        loadAllDatasWhenEditing: false,
        localField: 'course',
        foreignKey: 'courseId',
      },
    },
  },
  recordClass: ProgramCourseRecord,
})
