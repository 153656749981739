<template lang="pug">
  el-dialog.pi-see-lesson-page-correction.dialogPreview(
    :visible="dialogCorrectionVisible"
    @close="close",
    fullscreen
  )
    iframe(
      v-if="correctionUrl"
      :id="dialogId",
      :src="correctionUrl"
      allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    )
</template>
<script>
const storage = window.localStorage
export default {
  name: 'pi-see-lesson-page-correction',
  data: () => ({}),
  computed: {
    dialogId () {
      return this.$store.state.seeCorrectionLessonPage.previewDialogId
    },
    dialogCorrectionVisible () {
      return this.$store.getters.isLessonPageCorrectionVsible
    },
    correctionUrl () {
      if (this.dialogCorrectionVisible && storage) {
        const token = storage.getItem('id_token')
        const user = storage.getItem('id_user')
        return `${this.contextFrontoffice}/correction?idT=${token}&idU=${user}`
      }
      return false
    },
  },
  methods: {
    close () {
      this.$store.dispatch('HIDE_LESSON_PAGE_CORRECTION')
    },
  },
}
</script>
