import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'

export const CURRENCIES = {
  CREDIT: 'credit',
  LICENSE: 'license',
}
class WalletRecord extends Record {
  order (token, order, billing, paymentMode) {
    return this.constructor.mapper.order(this.id, {
      data: {
        data: {
          id: this.id,
          type: 'Wallet',
          attributes: {
            paymentMode,
            sourceToken: token,
            paymentType: 'card',
            order,
            billing,
          },
        },
      },
    }).then(response => {
      return store.add('Transaction', response.data)
    })
  }

  verifyTransaction (transactionId) {
    return this.constructor.mapper.verifyTransaction(this.id, {
      params: {
        transactionId,
      },
    }).then(response => {
      return store.add('Transaction', response.data)
    })
  }

  setOverdraft (value) {
    console.log('[ OVERDRAFT ]')
    return this.constructor.mapper.overdraft(this.id, {
      data: {
        overdraft: value,
      },
    }).then(response => {
      return response.data
    })
  }

  get canUseOverdraft () {
    return this.currency === CURRENCIES.CREDIT
  }
}

const WalletSchema = new Schema({
  title: 'WalletSchemaTitle',
  description: 'Schema for Wallet Records.',
  type: 'object',
  properties: {
    balance: {
      type: ['number', 'null'],
    },
    overdraft: {
      type: ['number', 'null'],
    },
    fund: {
      type: ['number', 'null'],
    },
    currency: {
      type: 'string',
    },
    ownerId: {
      type: 'string',
    },
    ownerType: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
    pendingTransactionIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  additionalProperties: true,
})

export const Wallet = store.defineMapper('Wallet', {
  schema: WalletSchema,
  endpoint: 'wallets',
  relations: {
    hasMany: {
      Transaction: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'walletId',
        localField: 'transactions',
      },
    },
    belongsTo: {
      Company: {
        loadAllDatasWhenEditing: false,
        localField: 'company',
        localKey: 'ownerId',
      },
    },
  },
  recordClass: WalletRecord,
})

addActions({
  verifyTransaction: {
    pathname: 'verify',
    method: 'POST',
  },
  overdraft: {
    pathname: 'overdraft',
    method: 'PATCH',
  },
  order: {
    pathname: 'order',
    method: 'POST',
  },
})(store.getMapper('Wallet'))
