import { omit } from 'lodash'

export const WIDGETS = {
  COURSE_TITLE: 'widget-course-title',
  SECTION_TITLE: 'widget-section-title',
  COURSE_ICON: 'widget-course-icon',
  COURSE_COMPETENCY_LIST: 'widget-course-competency-list',
  XP_EARNED: 'widget-xp-earned',
  PI_XP_EARNED: 'widget-pi-xp-earned',
  JOB_XP_EARNED: 'widget-job-xp-earned',
  COMPETENCY_XP_EARNED: 'widget-competency-xp-earned',
}

export const ACTIVE_WIDGETS = omit(WIDGETS, ['COURSE_COMPETENCY_LIST'])
