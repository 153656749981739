import { Record, Schema } from 'js-data'
import store from '@/api'
import { add, filter, flatten, get, includes, isArray, isNumber, isUndefined, map, orderBy, reduce } from 'lodash'

const tree = {
  // parent : child
  root: 'container',
  container: ['block', 'multiblock'],
  multiblock: ['block'],
  block: 'resource',
  resource: null,
}

const CourseNodeSchema = new Schema({
  title: 'CourseNodeShemaTitle',
  description: 'Schema for CourseNode Records.', // optional
  type: 'object',
  properties: {
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    title: {
      type: ['string', 'null'],
    },
    description: {
      type: ['string', 'null'],
    },
    content: {
      type: ['object'],
      additionalProperties: true,
    },
    tools: {
      type: ['object'],
      additionalProperties: true,
    },
    metas: {
      type: ['object'],
      properties: {
        estimatedDifficulty: {
          type: ['integer', 'null'],
        },
        estimatedTime: {
          type: ['integer', 'null'],
        },
      },
    },
    settings: {
      type: ['object'],
      properties: {
        timeLimit: {
          type: ['integer', 'null'],
        },
        points: {
          type: ['integer', 'null'],
        },
      },
      additionalProperties: true,
    },
    condition: {
      type: ['object', 'null'],
      additionalProperties: true,
      properties: {
        operator: {
          type: 'string',
        },
        ratio: {
          type: 'number',
        },
        active: {
          type: 'boolean',
          default: true,
        },
      },
    },
    blockId: {
      type: ['string', 'null'],
    },
    order: {
      type: 'integer',
    },
    parentId: {
      type: ['string', 'null'],
    },
    courseId: {
      type: ['string', 'null'],
    },
    type: {
      type: ['string'],
    },
  },
})

class CourseNodeRecord extends Record {
  destroyItAndChildren () {
    const p = Promise.resolve()
    this.childrenList.forEach(children => {
      p.then(() => children.destroyItAndChildren())
    })
    p.then(() => {
      this.destroy()
    })
    return p
  }

  get competencyStats () {
    const all = this.competenciesRepartition
    const out = {}
    const total = reduce(map(all, 'weight'), add)
    all
      .forEach(({ competencyId, weight = 0 }) => {
        if (isUndefined(out[competencyId])) {
          out[competencyId] = { percent: 0, count: 0, competency: store.get('CourseCompetency', competencyId) }
        }
        out[competencyId].count += weight
        out[competencyId].percent += weight / total
      })
    return out
  }

  get competenciesRepartition () {
    if (this.type === 'block') {
      return [{ competencyId: get(this, 'block.competency.pathId[0]'), weight: 1 }]
    }
    return flatten(
      this.childrenList.map(child => child.competenciesRepartition),
    )
      .filter(competenciesRepartition => Boolean(competenciesRepartition.competencyId))
      .map(competenciesRepartition => {
        if (this.type === 'multiblock') {
          competenciesRepartition.weight = competenciesRepartition.weight / this.childrenList.length
        }
        return competenciesRepartition
      })
  }

  get childrenList () {
    if (this.children.length === 0) { return [] }
    const types = isArray(tree[this.type]) ? tree[this.type] : [tree[this.type]]
    return orderBy(filter(this.children, child => includes(types, child.type)), ['order'], ['asc'])
  }
}

export const CourseNode = store.defineMapper('CourseNode', {
  beforeUpdate (id, item) {
    if (item.metas && !isUndefined(item.metas.estimatedTime) && !isNumber(item.metas.estimatedTime)) {
      item.metas.estimatedTime = null
    }
  },
  schema: CourseNodeSchema,
  recordClass: CourseNodeRecord,
  endpoint: 'course-nodes',
  relations: {
    belongsTo: {
      CourseBlock: {
        localField: 'block',
        foreignKey: 'blockId',
      },
      CourseNode: {
        localField: 'parent',
        localKey: 'parentId',
      },
      Course: {
        localField: 'course',
        localKey: 'courseId',
      },
    },
    hasMany: {
      CourseNode: {
        localField: 'children',
        foreignKey: 'parentId',
      },
    },
  },
})
