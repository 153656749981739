import promiseProps from 'promise-props'
import { get } from 'lodash'

const resolveDeps = function (promises) {
  return promiseProps(promises).then((obj) => {
    const out = []
    for (const model in obj) {
      const { data: dep, count: total } = obj[model]
      const list = dep.map(function (dep) {
        return {
          text: get(dep, 'title') || get(dep, 'name') || dep.displayName,
          value: dep.id,
          record: dep,
        }
      })
      out.push({
        title: `models.${model}.title`,
        list,
        total,
      })
    }
    return out
  })
}

export default resolveDeps
