import { Record, Schema } from 'js-data'
import store from '@/api'
import resolveDeps from '../getDeps.js'

const CourseTermSchema = new Schema({
  title: 'CourseTermtitle',
  description: 'Schema for CourseTerm Records.', // optional
  type: 'object',
  properties: {
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    title: {
      type: 'string',
    },
    words: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    definition: {
      type: 'string',
    },
  },
  required: ['title', 'words', 'definition'],
})
class TermRecord extends Record {
  get editPath () {
    return { name: 'TermEdit', params: { id: this.id } }
  }

  get getDeps () {
    const promises = {
      CourseBlock: store.findAll('CourseBlock', { filter: { termIds: this.id }, projection: { title: true, reference: true }, options: { skip: 0, limit: 10 } }, { force: true, raw: true }).then((response) => {
        return { data: response.data, count: +response.headers['x-model-count'] }
      }),
    }

    return resolveDeps(promises)
  }
}
export const CourseTerm = store.defineMapper('CourseTerm', {
  schema: CourseTermSchema,
  endpoint: 'course-terms',
  relations: {
    belongsTo: {
      CourseBlock: {
        localField: 'block',
        foreignKey: 'termIds',
      },
    },
  },
  recordClass: TermRecord,
})
