import Navigation from '@/config/navigation.yml'
import Vue from 'vue'
import { filter, get, isArray, isEmpty, isFunction, kebabCase, map, pick, reverse } from 'lodash'
import i18n from '@/i18n'
const state = () => ({
  currentRoute: {},
  previousRoute: null,
  treeMenu: Navigation.treeMenu || {},
  customBread: {},
})
function deepFind (a, route) {
  if (!isArray(a)) {
    return false
  }
  for (let i = 0; i < a.length; i++) {
    const tab = a[i]
    if (tab.index === route.fullPath || get(tab, 'route.name', false) === route.name) {
      return [tab]
    } else {
      const search = deepFind(tab.children, route)
      if (search) {
        search.push(tab)
        return search
      }
    }
  }
  return false
}
// getters
const getters = {
  getBreadcrumb: (state, getters, rootState, rootGetters) => {
    let node = deepFind(state.treeMenu, state.currentRoute)
    if (!node) {
      node = []
    }
    node = reverse(map(node, n => {
      return pick(n, [
        'icon',
        'index',
        'label',
        'permissions',
        'inBreadcrumb',
        'inSidebar',
        'class',
      ])
    }))
    if (isEmpty(node) && state.currentRoute.meta.breadcrumb) {
      const metaBreadcrumb = isFunction(state.currentRoute.meta.breadcrumb) ? state.currentRoute.meta.breadcrumb(state.currentRoute) : state.currentRoute.meta.breadcrumb
      let breadCrumbLabel = metaBreadcrumb
      if (i18n.te(metaBreadcrumb)) {
        breadCrumbLabel = i18n.t(metaBreadcrumb)
      }
      const breadcrumbs = [].concat(breadCrumbLabel)
      node = [].concat(
        node,
        map(breadcrumbs, breadcrumb => {
          return {
            icon: undefined,
            label: breadcrumb === '{{ contextName }}' ? rootGetters['context/get']('name') : breadcrumb,
            index: breadcrumb,
            path: undefined,
            permission: state.currentRoute.meta.permission,
          }
        }),
      )
    }
    if (!isEmpty(state.customBread)) {
      node.push(state.customBread)
    }
    return filter(node, 'label')
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    // const initialState = state()
    // forOwn(_state, (value, key) => {
    //   _state[key] = initialState[key]
    // })
  },
  SET_CURRENT_ROUTE (state, route) {
    Vue.set(state, 'currentRoute', route)
    document.documentElement.classList = kebabCase(`route ${route.name}`)
  },
  SET_PREVIOUS_ROUTE (state, route) {
    Vue.set(state, 'previousRoute', route)
  },
  SET_CUSTOM_BREAD (state, label) {
    Vue.set(state, 'customBread', { label, class: ['currentEdition', 'truncate'] })
  },
  CLEAR_CUSTOM_BREAD (state) {
    Vue.set(state, 'customBread', {})
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
