import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import api from '@/api'

export const LESSON_PROGRESS = {
  NONE: 'NONE',
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED',
}

export const LESSON_STATE = {
  LOCKED: 'LOCKED',
  AVAILABLE: 'AVAILABLE',
  HIDDEN: 'HIDDEN',
  DONE: 'DONE',
  SKIPPED: 'SKIPPED',
  EXPIRED: 'EXPIRED',
}

const LessonSchema = new Schema({
  title: 'Lessontitle',
  description: 'Schema for Lesson Records.', // optional
  type: 'object',
  additionalProperties: true,
  properties: {
    id: {
      track: false,
    },
    canBeOpenedManualy: {
      track: false,
    },
    startAt: {
      type: 'string',
      track: false,
    },
    userId: {
      type: 'string',
      track: false,
    },
    classId: {
      type: 'string',
      track: false,
    },
    courseId: {
      type: 'string',
      track: false,
    },
    currentNodeId: {
      type: ['string', 'null'],
      track: false,
    },
    categoryId: {
      type: ['null', 'string'],
      track: false,
    },
    enabledSectionIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    programCourseId: {
      type: 'string',
      track: false,
    },
    state: {
      type: 'string',
      enum: Object.values(LESSON_STATE),
    },
    progress: {
      type: 'string',
      enum: ['NONE', 'ONGOING', 'COMPLETED'],
      track: false,
    },
    sections: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: true,
        properties: {},
      },
      track: false,
    },
    tree: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: true,
        properties: {},
      },
      track: false,
    },
    actions: {
      track: false,
    },
    scoring: {
      track: false,
    },
    configuration: {
      track: false,
    },
    datasetPointer: {
      track: false,
    },
    classProgramId: {
      track: false,
    },
    createdAt: {
      track: false,
    },
    updatedAt: {
      track: false,
    },
    timeLimit: {
      track: false,
    },
    certificationRef: {
      type: 'string',
      track: false,
    },
    isEndOfProgram: {
      type: 'boolean',
      track: false,
    },
  },
})
export class LessonRecord extends Record {
  get hasConditionnalOpeningFromAnotherLesson () {
    return this.conditionnalLessonToFinishFirst.length > 0
  }

  getProgramCourse () {
    return api.get('ProgramCourse', this.programCourseId)
  }

  get conditionnalLessonToFinishFirst () {
    const { classId, userId, id } = this
    const openAutomaticalyAfterAnother = api.filter('Lesson', { where: { classId, userId, _id: { '!=': id } } })
      .filter(lesson => {
        const conditionnals = lesson?.actions?.conditional || []
        return conditionnals.some(conditional => conditional.target === id)
      })
    return openAutomaticalyAfterAnother
  }
}

export class LessonForInvitationRecord extends Record {
  static hasConditionnalOpeningFromAnotherLesson ({ classId, courseId, classProgramId }) {
    return LessonForInvitationRecord.conditionnalLessonToFinishFirst({ classId, courseId, classProgramId }).length > 0
  }

  get hasConditionnalOpeningFromAnotherLesson () {
    const { conditionnalLessonToFinishFirst } = this
    return conditionnalLessonToFinishFirst.length > 0
  }

  static conditionnalLessonToFinishFirst ({ classId, courseId, classProgramId }) {
    const classe = api.get('Class', classId)
    const classProgram = classe.getClassPrograms().find(el => el.id === classProgramId)
    if (!classProgram) {
      return []
    }
    const programCourses = (classProgram.program?.programCourses || [])
    const programCourse = programCourses.find(programCourse => programCourse.courseId === courseId)
    if (!programCourse) {
      return []
    }
    return (programCourse?.actions?.conditional || []).map(condition => condition.programCourseId)
  }

  get conditionnalLessonToFinishFirst () {
    return LessonForInvitationRecord.conditionnalLessonToFinishFirst({
      courseId: this.courseId,
      classId: this.classId,
      classProgramId: this.classProgramId,
    })
  }
}
export const Lesson = api.defineMapper('Lesson', {
  schema: LessonSchema,
  endpoint: 'lessons',
  relations: {
    hasMany: {
      LessonPage: {
        foreignKey: 'lessonId',
        localField: 'pages',
      },
    },
    belongsTo: {
      Course: {
        localKey: 'courseId',
        localField: 'course',
      },
      AppUser: {
        localKey: 'userId',
        localField: 'appUser',
      },
      Class: {
        localKey: 'classId',
        localField: 'class',
      },
    },
  },
  recordClass: LessonRecord,
})

addActions({
  updateLessons: {
    pathname: 'update',
    method: 'POST',
  },
})(Lesson)
