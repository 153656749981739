import store from '@/api'
const state = {
  // rootsCourseTypesLoaded: false
}

// getters
const getters = {
  rootsCompetencies (state, getters) {
    getters.DSCourseCompetency
    const rootsCompetencies = store.filter('CourseCompetency', { where: { parentId: { '==': null } }, orderBy: ['order', 'title'] })
    return rootsCompetencies
  },
  rootsCategories (state, getters) {
    getters.DSCourseCategory
    const rootsCategories = store.filter('CourseCategory', { where: {}, orderBy: ['title'] })
    return rootsCategories
  },
  rootsCourseTypes (state, getters) {
    getters.DSCourseType
    const rootsCourseTypes = store.filter('CourseType', { where: {}, orderBy: ['title'] })
    return rootsCourseTypes
  },
}

// actions
const actions = {
  async LOAD_REQUIRED_DATA ({ dispatch, commit, state, rootGetters }) {
    if (rootGetters['context/isGeneric']) {
      await store.findAll('CourseCompetency', {}, { force: true, adapter: 'jsonApiGeneric' })
    }
    await store.findAll('CourseCategory', {}, { force: true })
    return await store.findAll('CourseCompetency', { filter: {}, options: { sort: 'order' } }, { force: true })
  },
  LOAD_COURSESTYPES ({ dispatch, commit, state }) {
    return store.findAll('CourseType', {}, { force: true })
  },
  loadUserPermissions ({ dispatch, commit, state }) {
    return dispatch('LOAD_REQUIRED_DATA')
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
