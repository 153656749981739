import store from '@/api'
import { compact, every, find, findIndex, forOwn, get, isEqual, map, sum } from 'lodash'

const state = () => ({
  // cgv,
  items: [],
  ownerId: undefined,
  productType: undefined,
  billing: {
    house: undefined,
    postalNumber: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    cgv: false,
  },
})

// getters
const getters = {
  paymentMode (state, getters, rootState, rootGetters) {
    return every(getters.cart, produit => get(produit, 'product.subscription.plan')) ? 'subscription' : 'invoice'
  },
  productCount (state, getters, rootState, rootGetters) {
    return sum(map(getters.cart, el => {
      return el.quantity
    }))
  },
  productType (state, getters, rootState, rootGetters) {
    return state.productType
  },
  sumTVA (state, getters, rootState, rootGetters) {
    return getters.sumTTC - getters.sumHT
  },
  sumTTC (state, getters, rootState, rootGetters) {
    return sum(map(getters.cart, el => {
      return el.quantity * el.product.displayPriceTTC
    }))
  },
  sumHT (state, getters, rootState, rootGetters) {
    return sum(map(getters.cart, el => {
      return el.quantity * el.product.displayPrice
    }))
  },
  isEmpty (state, getters, rootState, rootGetters) {
    return sum(map(getters.cart, el => {
      return el.quantity
    })) === 0
  },
  sum (state, getters, rootState, rootGetters) {
    return sum(map(getters.cart, el => {
      return el.quantity * el.product.quantity
    }))
  },
  getCurrentCgv: (state, getters, rootState, rootGetters) => {
    // console.error('@todo CGV et non CGU!! ')
    return rootGetters['context/get']('settings.legal.cgu')
  },
  owner: (state) => {
    if (state.ownerId) {
      return store.get('AppUser', state.ownerId)
    }
  },
  getQuantityForItem: (state) => (id) => {
    return get(find(state.items, { id }), 'quantity', 0)
  },
  cart (state, getters, rootState, rootGetters) {
    return map(getters.order, item => {
      const product = store.get('Product', item.id)
      if (product && rootGetters.DSProduct[item.id] && item.quantity > 0) {
        const amount = product.price * item.quantity
        const displayAmount = product.price * item.quantity / 100
        return Object.assign({}, item, { product, amount, displayAmount })
      }
    })
  },
  order (state, getters, rootState, rootGetters) {
    return compact(map(state.items, item => {
      const product = store.get('Product', item.id)
      if (product && rootGetters.DSProduct[item.id] && item.quantity > 0) {
        return Object.assign({}, item)
      }
    }))
  },
  catalog (state, getters, rootState, rootGetters) {
    rootGetters.DSProduct
    const products = store.filter('Product', { where: { type: state.productType, tva: 20 }, options: { sort: [['totalPaymentPrice', 'ASC']] } })
    let lowerUnitProductPrice
    if (products.length > 0) {
      lowerUnitProductPrice = (products[0].totalPaymentPrice || 0) / (products[0].quantity || 1)
    }
    return map(products, product => {
      const unitProductPrice = (product.totalPaymentPrice || 0) / (product.quantity || 1)
      product.reduction = (1 - unitProductPrice / (lowerUnitProductPrice || 1)) * 100
      return product
    })
  },
  billing (state, getters, rootState, rootGetters) {
    return state.billing
  },
}

// actions
const actions = {
  clear ({ dispatch, commit, state, getters, rootGetters }) {
    commit('clear')
    dispatch('init')
  },
  async init ({ dispatch, commit, state, getters, rootGetters }) {
    const currentCompany = store.get('Company', rootGetters.getCurrentCompanyId)
    if (currentCompany) {
      currentCompany.getOwners.then(owners => {
        commit('setOwnerId', get(owners, [0, 'id']))
      })
      commit('setBillingFromCompany', currentCompany)
    }
    await store.findAll('Product', { filter: { type: state.productType, tva: 20 } }, { force: true })
  },
  setCatalogueProductsType ({ dispatch, commit, state, getters, rootGetters, rootState }, productType) {
    // on supprime tous les modules qui ne sont pas du type demandé.
    for (let index = state.items.length - 1; index >= 0; index--) {
      if (get(rootState, `DS.Product.${state.items[index].id}.type`) !== productType) {
        state.items.splice(index, 1)
      }
    }
    return commit('setCatalogueProductsType', productType)
  },
  setQuantityForItem ({ state, rootState }, { id, quantity }) {
    const inState = find(state.items, { id })
    const index = findIndex(state.items, { id })
    const productToAdd = get(rootState, `DS.Product.${id}`)
    for (let index = state.items.length - 1; index >= 0; index--) {
      const productToCheck = get(rootState, `DS.Product.${state.items[index].id}`)
      if (!productToCheck) {
        // on supprime tous éventuels modules invalides
        state.items.splice(index, 1)
        continue
      }
      if (productToCheck.type !== state.productType) {
        // on supprime tous les modules qui ne sont pas du type demandé.
        state.items.splice(index, 1)
        continue
      }
      if (!isEqual(get(productToCheck, 'subscription.plan.interval'), get(productToAdd, 'subscription.plan.interval')) ||
        !isEqual(get(productToCheck, 'subscription.duration'), get(productToAdd, 'subscription.duration'))
      ) {
        // on supprime tous les modules qui n'ont pas les mêmes conditions de paiement que celui qu'on veut mettre en place.
        state.items.splice(index, 1)
        continue
      }
    }
    if (inState && quantity) {
      inState.quantity = quantity
    } else if (inState && !quantity) {
      state.items.splice(index, 1)
    } else {
      state.items.push({ id, quantity })
    }
  },
}

// mutations
const mutations = {
  clear (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  setOwnerId (state, id) {
    state.ownerId = id
  },
  setBillingFromCompany (state, company) {
    state.billing.house = get(company, 'address.house')
    state.billing.city = get(company, 'address.city')
    state.billing.postalNumber = get(company, 'address.postalNumber')
  },

  setCatalogueProductsType (state, currency) {
    state.productType = currency
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
