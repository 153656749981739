import { DataStore, utils } from 'js-data'
import { HttpAdapter } from 'js-data-http'
import { isArray, isPlainObject, set } from 'lodash'
class PlateformDataStore extends DataStore {
  findMissing (model, ids = []) {
    const existingIds = this.filter(model, { where: { _id: { in: ids } } }).map(el => el.id)
    const missingIds = ids.filter(id => !existingIds.includes(id))
    if (missingIds.length > 0) {
      return this.findAll(model, { filter: { _id: { $in: missingIds } } })
    }
    return Promise.resolve(() => [])
  }
}
// Adapter & Data Storage
const config = {
  debug: true,
  usePendingFindAll: true,
  usePendingFind: true,
}

function encode (val) {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']')
}

class ApiAdapter extends HttpAdapter {
  responseError (err, config, opts) {
    if (err.response.status === 401 && err.response.config.pathname !== 'me') {
      document.location.href = '/login'
      return
    }
    return HttpAdapter.prototype.responseError.call(this, err, config, opts)
  }

  findAll (mapper, query, opts) {
    return HttpAdapter.prototype.findAll.call(this, mapper, query, opts)
  }

  update (mapper, id, props, opts) {
    if (!opts.forceReplace) {
      // opts has the finaly say over the method used
      opts.method = opts.method || 'patch'

      // We need the record to get changes
      const record = mapper.datastore.get(mapper.name, id)
      if (record) {
        // Now we have two possible cases :
        // * either props contain parameters the user want to update, and only
        //   those should be updated
        // * or when record is saved, props contains all data from the record
        //   even unchanged data
        // So we can't use record.changes(), because if first case it will be
        // empty or with not the data we want to send. Thus we can do the same
        // things the changes method do, but with props rather than with
        // current attributes.

        // opts.changes = record.changes();
        const changes = utils.diffObjects(props, record._get('previous'), opts)
        props = utils.deepMixIn(
          utils.deepMixIn({}, changes.changed),
          changes.added,
        )
      }
    }

    return HttpAdapter.prototype.update.call(this, mapper, id, props, opts)
  }

  deserialize (mapper, response, opts) {
    if (opts.import === false) {
      return HttpAdapter.prototype.deserialize.call(this, mapper, response, opts)
    }
    if (isArray(response?.data)) {
      response.data.forEach(el => {
        addToStoreWithRelation(opts.name, el)
      })
    } else if (opts.method === 'patch' && isPlainObject(response.data)) {
      addToStoreWithRelation(opts.name, response.data)
    }
    return HttpAdapter.prototype.deserialize.call(this, mapper, response, opts)
  }

  serialize (mapper, data, opts) {
    if (data && isPlainObject(data)) {
      const el = data
      for (const key in el) {
        // suppression des clefs privées
        if (key.indexOf('_') === 0) {
          delete el[key]
        }
        // suppression des clefs avec track === false
        if (mapper.schema?.properties?.[key]?.track === false) {
          delete el[key]
        }
      }
    }
    return HttpAdapter.prototype.serialize.call(this, mapper, data, opts)
  }
}
function addToStoreWithRelation (model, el) {
  const mapper = store.getMapperByName(model)
  Object.keys(mapper.relations).forEach(relationType => {
    Object.keys(mapper.relations[relationType]).forEach(relationTypeModel => {
      const definition = mapper.relations[relationType][relationTypeModel]
      if (definition.localField && el[definition.localField]) {
        const fieldData = el[definition.localField]
        if (isArray(fieldData)) {
          fieldData.forEach(entry => {
            store.add(relationTypeModel, entry)
          })
        } else {
          store.add(relationTypeModel, fieldData)
        }
      }
    })
  })
  store.add(model, el)
}
const storage = window.localStorage
const store = new PlateformDataStore(config)

function paramsSerializer (params) {
  const parts = []
  utils.forOwn(params, function (val, key) {
    if (val === null || typeof val === 'undefined') {
      return
    }
    if (!utils.isArray(val)) {
      val = [val]
    }
    val.forEach(function (v) {
      if (toString.call(v) === '[object Date]') {
        v = v.toISOString()
      } else if (utils.isObject(v)) {
        v = utils.toJson(v)
      }
      parts.push(`${encode(key)}=${encode(v)}`)
    })
  })
  return parts.join('&')
}

const jsonApiAdapter = new ApiAdapter({
  store,
  httpConfig: {
    paramsSerializer,
  },
})
const jsonApiGeneric = new ApiAdapter({
  store,
  httpConfig: {
    paramsSerializer,
  },
  beforeHTTP: function (config, opts) {
    config.headers || (config.headers = {})
    config.headers['X-ContextId'] = 'none'

    return HttpAdapter.prototype.beforeHTTP.call(this, config, opts)
  },
})

store.registerAdapter('jsonApi', jsonApiAdapter, { default: true })
store.registerAdapter('jsonApiGeneric', jsonApiGeneric)

if (storage.getItem('id_token')) {
  set(store.getAdapter().http.defaults.headers, 'common.Authorization', `Bearer ${storage.getItem('id_token')}`)
}
window.store = store
export default store
