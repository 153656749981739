import { Record, Schema } from 'js-data'
import store from '@/api'
import { isEmpty, isUndefined, map } from 'lodash'
import resolveDeps from '../getDeps.js'
import { INTERNAL_GENERIC_CONTEXT_ID } from './Context.js'

class CourseCompetencyRecord extends Record {
  get path () {
    const output = [this]
    if (!isUndefined(this.parent)) {
      output.unshift(...this.parent.path)
    } else if (!isEmpty(this.parentId)) {
      output.unshift({ title: this.parentId, id: this.parentId })
    }
    return output
  }

  get pathTitle () {
    const { path } = this
    return map(path, (o) => o.title).join(' / ')
  }

  get contextualizedPathId () {
    return [this.contextId || INTERNAL_GENERIC_CONTEXT_ID, ...this.pathId]
  }

  get pathId () {
    const { path } = this
    return map(path, (o) => o.id)
  }

  get iconFallBack () {
    const { path } = this
    for (let i = 0; i < path.length; i++) {
      if (path[i].icon) {
        return path[i].icon
      }
    }
    return undefined
  }

  get editPath () {
    return { name: 'CompetencyEdit', params: { id: this.id } }
  }

  get getDeps () {
    const promises = {
      CourseBlock: store.findAll('CourseBlock', { filter: { competencyId: this.id }, projection: { title: true, reference: true }, options: { skip: 0, limit: 10 } }, { force: true, raw: true }).then((response) => {
        return { data: response.data, count: +response.headers['x-model-count'] }
      }),
    }

    return resolveDeps(promises)
  }

  async hasChildren () {
    if (!this.id) return false
    if (this.children.length > 0) return true
    return store.findAll('CourseCompetency', { filter: { parentId: this.id }, page: { offset: 0, size: 1 } }, { force: true }).then((response) => response.length > 0)
  }

  async canBeDeletedFor () {
    return [
      (await this.hasChildren() ? 'Cette compétence possède des compétences enfantes qu\'il faut préalablement supprimer ou réaffecter.' : ''),
    ].filter(Boolean)
  }

  async canBeDeleted () {
    if (!this.id) return true
    const canBeDeletedFor = await this.canBeDeletedFor()
    return canBeDeletedFor.length === 0
  }
}

const CourseCompetencySchema = new Schema({
  title: 'CourseCompetencyShemaTitle',
  description: 'Schema for CourseCompetency Records.', // optional
  type: 'object',
  properties: {
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    title: {
      type: 'string',
    },
    code: {
      type: 'string',
    },
    order: {
      type: ['number', 'null'],
    },
    icon: {
      type: ['string', 'null'],
    },
    parentId: {
      type: ['string', 'null'],
    },
    color: {
      type: ['string', 'null'],
    },
  },
})
export const CourseCompetency = store.defineMapper('CourseCompetency', {
  schema: CourseCompetencySchema,
  endpoint: 'course-competencies',
  relations: {
    belongsTo: {
      CourseCompetency: {
        loadAllDatasWhenEditing: false,
        localField: 'parent',
        localKey: 'parentId',
      },
    },
    hasMany: {
      CourseBlock: {
        loadAllDatasWhenEditing: false,
        localField: 'blocks',
        foreignKey: 'competencyId',
      },
      CourseCompetency: {
        loadAllDatasWhenEditing: false,
        localField: 'children',
        foreignKey: 'parentId',
      },
    },
  },
  recordClass: CourseCompetencyRecord,
})
