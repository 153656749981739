import { Record, Schema } from 'js-data'
import { addAction } from 'js-data-http'
import moment from 'moment'
import store from '@/api'
import { get, isArray, map, orderBy, sum } from 'lodash'
import { ROLES } from '../enums/roles'
import { STATUS } from '../enums/Class'

const ClassSchema = new Schema({
  title: 'ClassSchemaTitle',
  description: 'Schema for Class Records.',
  type: 'object',
  additionalProperties: true,
  properties: {
    name: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Entrez un nom pour ce parcours', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
      },
    },
    draftContent: {
      type: ['null', 'object'],
      additionalProperties: true,
      properties: {},
    },
    startAt: {
      type: 'string',
    },
    status: {
      type: ['null', 'string'],
    },
    expireAt: {
      type: ['null', 'string'],
    },
    companyId: {
      type: 'string',
    },
    companyUserGroupIds: {
      type: 'array',
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    programIds: {
      track: false,
      type: 'array',
      items: {
        type: 'string',
      },
    },
    settings: {
      type: 'object',
      additionalProperties: true,
      properties: {
        certification: {
          type: ['null', 'boolean'],
        },
        mobile: {
          type: 'object',
          additionalProperties: true,
          properties: {
            compatible: {
              type: 'boolean',
            },
            allowed: {
              type: 'boolean',
            },
          },
        },
        notification: {
          type: 'object',
          additionalProperties: true,
          enabled: {
            type: 'boolean',
          },
          inactivityDayPeriod: {
            type: 'number',
          },
        },
      },
    },
    note: {
      type: 'object',
      properties: {
        html: {
          type: 'string',
        },
        settings: {
          type: 'object',
          additionalProperties: true,
        },
        additionalProperties: true,
      },
    },
  },
})

export class ClassRecord extends Record {
  get state () {
    if (this.status === STATUS.DRAFT) {
      return STATUS.DRAFT
    }
    if (this.expired) {
      return 'EXPIRED'
    }
    if (moment(this.startAt) < moment()) {
      return 'OPEN'
    }
    return 'PENDING'
  }

  getUserGlobalScore (userId) {
    const lessons = store.filter('Lesson', {
      userId,
      classId: this.id,
    })
    return {
      actual: sum(map(lessons, (o) => get(o, 'scoring.global.actual', 0))),
      max: sum(map(lessons, (o) => get(o, 'scoring.global.max', 0))),
      available: sum(map(lessons, (o) => get(o, 'scoring.global.available', 0))),
    }
  }

  getClassPrograms () {
    return orderBy(this.classPrograms, 'order')
  }

  get editPath () {
    return { name: 'ClassEdit', params: { id: this.id } }
  }

  get studentCount () {
    if (typeof this.userCountByRole === 'object') {
      return this.userCountByRole.STUDENT || 0
    }
    return 0
  }

  get teachers () {
    const users = map(store.filter('ClassUser', { classId: this.id, role: ROLES.TEACHER }), 'user.displayName')
    return users
  }

  loadStudentsData () {
    if (this.id) {
      return this.constructor.mapper.usersData(this.id, {
        params: {
          role: ROLES.STUDENT,
          options: {
            sort: ['lastname', 'firstname'],
            collation: {
              locale: 'fr',
            },
          },
        },
      }).then(response => {
        response.data = response.data.map((el) => {
          el.id = el._id
          return store.add('AppUser', el)
        })
        return response.data
      })
    }
  }

  invite (login, role) {
    const logins = isArray(login) ? login : [login]
    const data = logins.map(e => ({
      [/\S+@\S+\.\S+/.test(e) ? 'email' : 'username']: e,
      role,
    }))
    return this.constructor.mapper.invite(this.id, {
      data,
    }).then((response) => {
      const users = get(response, 'data.data', []).map(user => store.add('AppUser', user))
      if (get(response, 'data.errors', []).length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw ({
          data: users,
          errors: get(response, 'data.errors', []),
        })
      }
      return { data: users }
    })
  }

  async resetByUserIds (userIds) {
    const datas = await this.constructor.mapper.reset(this.id, {
      data: userIds,
    })
    return datas.data
  }

  async resetByUserId (userId) {
    return await this.resetByUserIds(userId)
  }
}

export const Class = store.defineMapper('Class', {
  schema: ClassSchema,
  endpoint: 'classes',
  relations: {
    hasMany: {
      ClassUser: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'classId',
        localField: 'classUsers',
      },
      Lesson: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'classId',
        localField: 'lessons',
      },
      ClassProgram: {
        loadAllDatasWhenEditing: false,
        localField: 'classPrograms',
        foreignKey: 'classId',
      },
    },
    belongsTo: {
      Company: {
        foreignKey: 'companyId',
        localField: 'company',
      },
      CompanyInvitationCode: {
        loadAllDatasWhenEditing: false,
        localField: 'companyInvitationCode',
        foreignKey: 'classIds',
      },
    },
  },
  recordClass: ClassRecord,
})

addAction('invite', {
  pathname: 'invitation',
  method: 'POST',
})(store.getMapper('Class'))

addAction('usersData', {
  pathname: 'users/data',
  method: 'GET',
})(Class)

addAction('reset', {
  pathname: 'reset',
  method: 'POST',
})(Class)
