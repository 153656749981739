import { Record, Schema } from 'js-data'
import store from '@/api'
import resolveDeps from '../getDeps.js'

const CourseCategorySchema = new Schema({
  title: 'CourseCategorytitle',
  description: 'Schema for CourseCategory Records.', // optional
  type: 'object',
  properties: {
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    title: {
      type: 'string',
    },
    icon: {
      type: ['string', 'null'],
    },
    backgroundImageId: {
      type: ['string', 'null'],
    },
  },
  additionalProperties: true,
  required: ['title'],
})
class CourseCategoryRecord extends Record {
  get editPath () {
    return { name: 'JobEdit', params: { id: this.id } }
  }

  get getDeps () {
    const promises = {
      CourseBlock: store.findAll('CourseBlock', { filter: { categoryId: this.id }, projection: { title: true, reference: true }, options: { skip: 0, limit: 5 } }, { force: true, raw: true }).then((response) => {
        return { data: response.data, count: +response.headers['x-model-count'] }
      }),
      Course: store.findAll('Course', { filter: { categoryId: this.id }, projection: { title: true, reference: true }, options: { skip: 0, limit: 5 } }, { force: true, raw: true }).then((response) => {
        return { data: response.data, count: +response.headers['x-model-count'] }
      }),
    }

    return resolveDeps(promises)
  }
}
export const CourseCategory = store.defineMapper('CourseCategory', {
  schema: CourseCategorySchema,
  endpoint: 'course-categories',
  relations: {
    hasMany: {
      Course: {
        loadAllDatasWhenEditing: false,
        localField: 'courses',
        foreignKey: 'categoryId',
      },
      CourseBlock: {
        loadAllDatasWhenEditing: false,
        localField: 'blocks',
        foreignKey: 'categoryId',
      },
    },
  },
  recordClass: CourseCategoryRecord,
  useContextForItsLibraries: true,
})
