import { Record, Schema } from 'js-data'
import store from '@/api'
import { get } from 'lodash'

const timeMap = {
  year: 12,
  month: 1,
}
class ProductRecord extends Record {
  get totalPaymentCount () {
    const durationInMonth = get(this, 'subscription.duration.count', 1) * timeMap[get(this, 'subscription.duration.type', 'month')]
    const intervalInMonth = get(this, 'subscription.plan.interval.count', 1) * timeMap[get(this, 'subscription.plan.interval.type', 'month')]
    return Math.max(Math.floor(durationInMonth / intervalInMonth), 1)
  }

  get totalPaymentPrice () {
    return this.price * this.totalPaymentCount
  }

  get totalPaymentdisplayPrice () {
    return this.totalPaymentPrice / 100
  }

  get totalPaymentdisplayPriceTTC () {
    return (this.totalPaymentPrice + this.totalPaymentPrice * (this.tva || 0) / 100) / 100
  }

  get displayPrice () {
    return this.price / 100
  }

  get displayPriceTTC () {
    return (this.price + this.price * (this.tva || 0) / 100) / 100
  }
}

const ProductSchema = new Schema({
  title: 'ProductSchemaTitle',
  description: 'Schema for Product Records.',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    invoicingLabel: {
      type: 'string',
    },
    price: {
      type: 'number',
    },
    quantity: {
      type: 'number',
    },
    tva: {
      type: 'number',
    },
    active: {
      type: 'boolean',
    },
    currency: {
      type: 'string',
    },
    type: {
      type: 'string',
    },
    subscription: {
      type: ['null', 'object'],
      properties: {
        plan: {
          type: 'object',
          properties: {
            interval: {
              type: 'object',
              properties: {
                type: {
                  type: 'string',
                },
                count: {
                  type: 'number',
                },
              },
            },
          },
        },
        duration: {
          type: 'object',
          properties: {
            type: {
              type: 'string',
            },
            count: {
              type: 'number',
            },
          },
        },
      },
    },
  },
  additionalProperties: true,
})

export const Product = store.defineMapper('Product', {
  schema: ProductSchema,
  endpoint: 'products',
  relations: {
  },
  recordClass: ProductRecord,
})
