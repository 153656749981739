import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'
import resolveDeps from '../getDeps.js'
import { compact, get, isEmpty, isString, map, sortBy, uniq } from 'lodash'
import utils from '@/helpers/utils.js'
import { duplicateToContext } from './Mixins/duplicateToContext.js'

const pattern = {
  iconId: null,
  public: false,
  previewIds: [],
  referralResourceIds: [],
  bannerId: '',
  settings: {
    showOnlyAvailableLesson: true,
    reset: false,
    certification: false,
    mobile: {
      compatible: false,
    },
    credit: 0,
  },
  metas: {
    estimatedTime: null,
    tags: {
      courseCategoryId: '',
      courseCompetencyIds: [],
    },
    prerequisites: '',
    pack: false,
  },
}

const ProgramSchema = new Schema({
  title: 'ProgramschemaTitle',
  description: 'Schema for Programs Records.',
  type: 'object',
  properties: {
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    shortDescription: {
      type: ['null', 'string'],
    },
    iconId: {
      type: ['null', 'string'],
    },
    public: {
      type: 'boolean',
    },
    settings: {
      type: 'object',
      additionalProperties: true,
      properties: {
        reset: {
          type: 'boolean',
        },
        showOnlyAvailableLesson: {
          type: 'boolean',
        },
        credit: {
          type: 'number',
        },
        mobile: {
          type: 'object',
          additionalProperties: true,
          properties: {
            compatible: {
              type: 'boolean',
            },
          },
        },
      },
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    previewIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    referralResourceIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    contexts: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          contextId: {
            type: 'string',
          },
          attributedAt: {
            type: 'string',
          },
          attributedByContextId: {
            type: ['string', 'null'],
          },
          rights: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          companyIds: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
    bannerId: {
      type: ['null', 'string'],
    },
    metas: {
      type: 'object',
      properties: {
        estimatedTime: {
          type: ['integer', 'null'],
        },
        prerequisites: {
          type: 'string',
        },
        pack: {
          type: 'boolean',
        },
        tags: {
          type: 'object',
          properties: {
            courseCategoryId: {
              type: 'string',
            },
            courseCompetencyIds: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    state: {
      type: 'string',
      enum: ['DRAFT', 'PUBLISHED', 'CLOSED'],
    },
  },
  additionalProperties: true,
  required: ['name', 'state'],
})
export class ProgramRecord extends Record {
  get displayName () {
    return this.name
  }

  get companyIds () {
    const ids = []
    this.contexts.forEach((context) => {
      ids.push(...context.companyIds)
    })
    return uniq(ids)
  }

  get sortedProgramCourses () {
    return sortBy(this.programCourses, pc => pc.order || 0)
  }

  get loadedCourses () {
    return map(sortBy(this.programCourses, 'order'), 'course')
  }

  static getEditPath ({ id }) {
    return { name: 'ProgramEdit', params: { id } }
  }

  get editPath () {
    return ProgramRecord.getEditPath({ id: this.id })
  }

  get getDeps () {
    let contextId
    if (!this.isGeneric) {
      // eslint-disable-next-line prefer-destructuring
      contextId = this.contextId
    }
    const promises = {
      Class: store.findAll('Class', { filter: { programIds: { $in: [this.id] }, contextId }, projection: { name: true, reference: true }, options: { skip: 0, limit: 5 } }, { force: true, raw: true }).then((response) => {
        return { data: response.data, count: +response.headers['x-model-count'] }
      }),
    }

    return resolveDeps(promises)
  }

  get types () {
    const types = uniq(compact(map(this.loadedCourses, 'typeId')))
    return compact(map(types, id => store.get('CourseType', id)))
  }

  get internalPrimaryColor () {
    const colors = uniq(map(this.programCourses, (pc) => {
      return get(pc.course, 'type.internalPrimaryColor', '')
    }))
    if (colors.length === 1 && isString(colors[0])) {
      return colors[0]
    } else if (colors.length > 1) {
      return utils.COLORS.programPack
    } else if (isEmpty(colors) || !isString(colors[0])) {
      return utils.COLORS.tertiary
    }
  }

  duplicateToContext (contextId) {
    return duplicateToContext.bind(this)('ERROR_PROGRAM_DUPLICATION')(contextId, 'Program')
  }
}
export const Program = store.defineMapper('Program', {
  schema: ProgramSchema,
  endpoint: 'programs',
  paths: {
    list: 'ProgramList',
    edit: 'ProgramEdit',
  },
  relations: {
    hasMany: {
      ProgramCourse: {
        loadAllDatasWhenEditing: false,
        localField: 'programCourses',
        foreignKey: 'programId',
      },
      ClassProgram: {
        loadAllDatasWhenEditing: false,
        localField: 'classPrograms',
        foreignKey: 'programId',
      },
    },
  },
  recordClass: ProgramRecord,
  pattern,
})

addActions({
  cloneToContext: {
    pathname: 'duplicate-to-context',
    method: 'POST',
  },
  duplicateToContexts: {
    pathname: 'duplicate-to-contexts',
    method: 'POST',
  },
  shareToContexts: {
    pathname: 'share-to-contexts',
    method: 'POST',
  },
  addProgramContext: {
    pathname: 'program-context',
    method: 'POST',
  },
  deleteProgramContext: {
    pathname: 'program-context',
    method: 'DELETE',
  },
  addProgramCompany: {
    pathname: 'program-company',
    method: 'POST',
  },
  deleteProgramCompany: {
    pathname: 'program-company',
    method: 'DELETE',
  },
})(Program)
