export function getResponseAsFile (response, _options) {
  const options = Object.assign({
    filename: 'export.csv',
  }, _options)
  const link = document.createElement('a')
  document.body.appendChild(link)
  const blob = new Blob([`\uFEFF${response.data}`], { encoding: 'UTF-8', type: 'octet/stream;charset=UTF-8' })
  const url = window.URL.createObjectURL(blob)
  link.href = url
  link.download = options.filename
  link.click()
  window.URL.revokeObjectURL(url)
  link.remove()
}
