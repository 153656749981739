import { Record, Schema } from 'js-data'

import store from '@/api'

export class LogBookRecord extends Record {
}

const LogBookSchema = new Schema({
  title: 'LogBookSchemaTitle',
  description: 'Schema for LogBook Records.',
  type: 'object',
  properties: {
    title: {
      type: 'string',
    },
    message: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
    lastInputAt: {
      type: 'string',
    },
    studentId: {
      type: 'string',
    },
    attachmentIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    parentId: {
      type: ['null', 'string'],
    },
    userId: {
      type: 'string',
    },
    classId: {
      type: ['null', 'string'],
    },
    companyId: {
      type: 'string',
    },
  },
  additionalProperties: true,
})

export const LogBook = store.defineMapper('LogBookRecord', {
  schema: LogBookSchema,
  endpoint: 'log-book-records',
  relations: {
    belongsTo: {
      AppUser: {
        localKey: 'userId',
        localField: 'user',
      },
    },
  },
  recordClass: LogBookRecord,
})
