import { Record, Schema } from 'js-data'
import store from '@/api'
import { uniq, without } from 'lodash'

class CompanyUserGroupRecord extends Record {
  removeFromClass (classId) {
    this.classIds = without(this.classIds || [], classId)
    return this
  }

  addToClass (classId) {
    const classIds = (this.classIds || [])
    classIds.push(classId)
    this.classIds = uniq(classIds)
    return this
  }
}
const CompanyUserGroupSchema = new Schema({
  title: 'CompanyUserGroupSchemaTitle',
  description: 'Schema for CompanyUserGroup Records.',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    companyId: {
      type: 'string',
    },
    studentIds: {
      type: 'array',
      items: {
        type: ['string', 'null'],
      },
    },
    referrerIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    contextId: {
      type: 'string',
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    referrerCount: {
      type: 'number',
      track: false,
    },
    studentCount: {
      type: 'number',
      track: false,
    },
  },
  additionalProperties: true,
})

export const CompanyUserGroup = store.defineMapper('CompanyUserGroup', {
  schema: CompanyUserGroupSchema,
  endpoint: 'company-user-groups',
  relations: {
  },
  recordClass: CompanyUserGroupRecord,
})
