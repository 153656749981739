<script>
import Tooltip from 'element-ui/packages/tooltip/src/main.js'
export default {
  name: 'tooltip',
  extends: Tooltip,
  props: {
    placement: {
      type: String,
      default: 'top',
    },
    enterable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
