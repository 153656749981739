const CompanyList = () => import('./List.vue')
const CompanyEdit = () => import('./Edit.vue')
const MyCompanyEdit = () => import('./MyCompanyEdit.vue')

export default [
  {
    path: '/company',
    name: 'CompanyList',
    meta: { permission: 'grains&context' },
    component: CompanyList,
  },
  {
    path: '/company/current',
    name: 'MyCompany',
    component: MyCompanyEdit,
    meta: {
      permission: 'client',
    },
  },
  {
    path: '/company/edit',
    name: 'CompanyCreate',
    meta: { permission: 'grains&context' },
    component: CompanyEdit,
  },
  {
    path: '/company/edit/:id',
    name: 'CompanyEdit',
    meta: { permission: 'grains&context' },
    component: CompanyEdit,
    props: true,
  },
]
