<template lang="pug">
  .pi-credits(v-if="loaded")
    el-dialog(
      :title="title"
      :visible.sync="isVisible"
      custom-class="small"
      append-to-body
      )
      el-tabs(v-if='wallet')
        el-tab-pane(:label="$tc(`attributes.${this.walletCurrency}Label.title`, 2)", v-if="$_can('grains')")
          el-row.header
            el-col(:span="8")
              .label Solde
            el-col(:span="6")
              p.label
                | {{ wallet.balance }}
                i(:class="currencyIcon")
          template(v-if="wallet.canUseOverdraft")
            hr
            el-row.overdraft
              el-col(:span="8")
                .label Découvert autorisé
              template(v-if="!editOverdraft")
                el-col(:span="6")
                  p() {{ wallet.overdraft || 0 }}
                    i.el-icon-pi-CREDITS.little-margin-left
                el-col(:span="10")
                  el-button(size="mini" type="primary" @click="editOverdraftPanel" plain)
                    | {{$t('actions.edit.action')}}
              template(v-else)
                el-col(:span="6")
                  el-input(v-model.number="newOverdraft" icon="el-icon-pi-CREDITS" size="mini")
                el-col(:span="10")
                  el-button(size="mini" type="success" @click="saveOverdraft")
                    | {{$t('actions.save.action')}}
                  el-button(size="mini" type="danger" @click="resetOverdraft" plain)
                    | {{$t('actions.cancel.action')}}
          hr
          h3 {{ $t('actions.addOrRemove.something', {something: $tc(`context.${this.walletCurrency}.undefined`, 2)}) }}
          el-form(:model="form" :rules="rules" ref="sendTransaction" label-width="100px")
            el-form-item(label="Intitulé" prop="label")
              el-input(v-model="form.label")
            el-form-item(label="Montant" prop="amount")
              el-input-number(v-model="form.amount" :min="minAmount")
            el-button(@click="sendTransaction" :loading='isSending') Effectuer l'opération
          pi-alerts.alerts.medium-margin-top(:channel="channelCredits")

        el-tab-pane(label="Transactions")
          .large-margin-y
            el-form.filters(label-width="75px")
              el-row()
                el-col(:span="12")
                  el-form-item(:label="`${$tc('attributes.state.title', 1)} :`")
                    el-select.stateFilter(v-model="filters.state", clearable)
                      el-option(v-for="(state, sIndex) in states", :key="sIndex", :value="state.value", :label="state.label")
                el-col(:span="12")
                  el-form-item(:label="`${$tc('attributes.type.title', 1)} :`")
                    el-select.stateFilter(v-model="filters.type", clearable)
                      el-option(v-for="(type, tIndex) in types", :key="tIndex", :value="type.value", :label="type.label")
              el-row()
                el-col(:span="12")
                  el-form-item(:label="`${$tc('attributes.amount.title', 1)} :`")
                    el-select.stateFilter(v-model="filters.amount", clearable)
                      el-option(v-for="(amount, aIndex) in amounts", :key="aIndex", :value="amount", :label="amount")
                el-col(:span="12")
                  el-form-item(label="Date :")
                    el-date-picker(
                      placehorlder="Choisir",
                      :picker-options="datetimerangeOptions",
                      type="datetimerange",
                      v-model.lazy="filters.date")

          el-pagination(
            small,
            layout="prev, pager, next",
            :current-page.sync="page"
            :total="total",
            :page-size="pageSize"
            )

          .medium-margin-y
            .emptyList.text-align-center(v-if="_isEmpty(transactions)")
              p() Aucune transaction trouvée
            div(v-for="transaction in transactions", :key="transaction.id", :data-transaction-id="transaction.id")
              el-row
                el-col
                  h3 {{ transaction.label }}
              el-row
                el-col(:span='12')
                  p
                    strong {{ $tc('attributes.amount.title', 1) }} :
                    tag(:type="transaction.amount > 0 ? 'success' : 'danger'")
                      template(v-if='transaction.amount > 0')
                        | +
                      | {{ transaction.amount }}
                el-col(:span='12')
                  p(:class='getStateClass(transaction.state)')
                    strong {{ $tc('attributes.state.title', 1) }} :
                    | {{ $t(`transaction.state.${transaction.state}`) }}
              el-row
                el-col(:span='12')
                  p
                    strong {{ $tc('attributes.type.title', 1) }} :
                    | {{ $t(`transaction.type.${transaction.type}`) }}
                el-col(:span='12')
                  p
                    strong Date :
                    | {{ getDate(transaction.createdAt) }}
              el-row(v-if="transaction.targetId && globals.ENV === 'development'")
                el-col
                  p
                    strong Contexte (debug) :
                    | {{ transaction.targetId }}

              hr.little-margin-y
          el-pagination(
            small
            layout="prev, pager, next"
            :current-page.sync="page"
            :total="total"
            :page-size="pageSize"
            )
</template>
<script>
import store from '@/api'
import moment from 'moment'
import AlertChannelMixin from '@/mixins/AlertChannelMixin.js'
import { CURRENCIES } from '@/api/models/Wallet'

export default {
  name: 'pi-credits',
  components: {
    PiAlerts: () => import('@/components/Templates/PiAlerts/PiAlerts.vue'),
  },
  mixins: [
    AlertChannelMixin('channelCredits'), // this.?, ?_add(), ?_remove(), ?_clear()
  ],
  data () {
    return {
      loaded: false,
      isSending: false,
      page: 1,
      total: 1,
      pageSize: 10,
      formName: 'sendTransaction',
      rules: {
        label: [
          { required: true, message: 'Un descriptif est requis' },
          { min: 10, message: 'La description doit faire au moins 10 caractères' },
        ],
        amount: [
          { required: true, message: 'Un montant est requis' },
          { type: 'integer', message: 'Le montant doit être un nombre entier' },
          {
            validator: (rule, value, callback) => {
              if (value === 0) {
                return callback(new Error('Le montant ne doit pas être nul'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
      editOverdraft: false,
      form: {
        label: null,
        amount: 0,
      },
      filters: {
        state: null,
        type: null,
        amount: null,
        date: ['', ''],
      },
      states: [{
        label: 'Créée',
        value: 'initial',
      }, {
        label: 'En cours',
        value: 'pending',
      }, {
        label: 'Appliquée',
        value: 'applied',
      }, {
        label: 'Réussie',
        value: 'done',
      }, {
        label: 'En cours d\'annulation',
        value: 'canceling',
      }, {
        label: 'Annulée',
        value: 'canceled',
      }],
      types: [{
        label: 'Transaction de l\'administrateur PI',
        value: 'manual',
      }, {
        label: 'Achat dans l\'administration',
        value: 'paid',
      }, {
        label: 'Remboursement dans l\'administration',
        value: 'refund',
      }, {
        label: 'Parcours',
        value: 'consumed',
      }],
      amounts: ['Crédit', 'Débit'],
      datetimerangeOptions: {
        shortcuts: [{
          text: 'La semaine dernière',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: 'Le mois dernier',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: 'Les trois derniers mois',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          },
        }],
      },
      newOverdraft: null,
    }
  },
  computed: {
    minAmount () {
      if (this.wallet.balance) {
        return Math.min(0, -1 * ((this.wallet.balance || 0) + (this.wallet.overdraft || 0)))
      }
      return 0
    },
    owner () {
      if (this.wallet && this.wallet.ownerType && this.wallet.ownerId) {
        this.$store.getters[`DS${this.wallet.ownerType}`]
        return store.get(this.wallet.ownerType, this.wallet.ownerId)
      }
      return {}
    },
    title () {
      return `${this.$options.filters.capitalize(this.$tc(`context.${this.walletCurrency}.title`, 2))} de ${this.owner.name}`
    },
    id () {
      return this.$store.state.credits.walletId
    },
    isVisible: {
      set (value) {
        if (!value) {
          this.loaded = false
          this.$store.commit('CREDITFORM_HIDE')
          if (this.$refs[this.formName]) {
            this.$refs[this.formName].resetFields()
          }
          this.channelCredits_clear()
        }
      },
      get () {
        this.total = 0
        return !this._isNull(this.id)
      },
    },
    wallet: {
      get () {
        if (!this.id) {
          return {}
        }
        this.$store.getters.DSWallet[this.id]
        return store.get('Wallet', this.id)
      },
    },
    walletCurrency () {
      return this.wallet.currency
    },
    isLicenseWallet () {
      return this.walletCurrency === CURRENCIES.LICENSE
    },
    isCreditWallet () {
      return this.walletCurrency === CURRENCIES.CREDIT
    },
    currencyIcon () {
      return this.isLicenseWallet ? 'el-icon-pi-LICENSES' : 'el-icon-pi-CREDITS'
    },
  },
  mounted () {
    window._credits = this
  },
  methods: {
    editOverdraftPanel () {
      this.newOverdraft = this.wallet.overdraft || 0
      this.editOverdraft = true
    },
    resetOverdraft () {
      this.editOverdraft = false
    },
    saveOverdraft () {
      this.channelCredits_clear()
      this.wallet.setOverdraft(this.newOverdraft)
        .then((data) => {
          this.channelCredits_add({
            type: 'success',
            title: 'Succés',
            description: 'Mise à jour des données réussie',
          })
          this.wallet.overdraft = data.overdraft
          this.editOverdraft = false
        }).catch(e => {
          this.channelCredits_add({
            type: 'error',
            title: 'Erreur',
            description: `Les données n'ont pas pu se mettre à jour${e.message}`,
          })
          this.editOverdraft = false
        })
    },
    getStateClass (state) {
      if (state === 'canceled') {
        return 'tc-danger'
      } else if (state === 'done') {
        return 'tc-success'
      }
    },
    getDate (date) {
      return moment(date).format('LLL')
    },
    sendTransaction () {
      this.channelCredits_clear()
      if (!this.id) {
        return
      }
      this.$refs[this.formName].validate((valid) => {
        if (valid) {
          this.$confirm(
            `Êtes vous sûr de vouloir effectuer cette transaction de "${this.form.amount}" ${this.$tc(`attributes.${this.walletCurrency}.nocaptitle`, this.form.amount)}`,
            'Attention',
            {
              confirmButtonText: 'Confirmer',
              cancelButtonText: 'Abandonner',
              type: 'error',
            })
            .then(() => {
              this.isSending = true
              return store.create('Transaction',
                {
                  walletId: this.id,
                  amount: this.form.amount,
                  label: this.form.label,
                })
                .then(transaction => {
                  this.channelCredits_add({
                    title: 'Transaction effectuée',
                    type: 'success',
                  })
                  this.$refs[this.formName].resetFields()
                })
                .catch(err => {
                  const errors = this._get(err, 'response.data.errors', [])
                  for (const i in errors) {
                    this.channelCredits_add({
                      title: this.$t(`errors.${errors[i].code}.title`),
                      type: 'error',
                    })
                  }
                }).then(() => {
                  this.isSending = false
                })
            }).catch(() => {
            })
        } else {
          return false
        }
      })
    },
  },
  asyncComputed: {
    loadwallet () {
      this.loaded = false
      if (!this.id) {
        return {}
      }
      return store.find('Wallet', this.id, { force: true }).then((wallet) => {
        this.loaded = true
        return wallet
      })
    },
    transactions: {
      lazy: true,
      get () {
        if (!this.id) {
          return []
        }
        // @info, le problème est le nombre de requête que ça génère.
        // Idée de solution : Un bouton pour validé les filtres,
        // il n'y aurait plus une requête par select mais l'utilisateur aurait une action de plus à faire.
        const filter = {
          walletId: this.id,
        }
        if (this.filters.state) {
          filter.state = this.filters.state
        }
        if (this.filters.type) {
          filter.type = this.filters.type
        }
        if (this.filters.amount === 'Crédit') {
          filter.amount = { $gte: 0 }
        }
        if (this.filters.amount === 'Débit') {
          filter.amount = { $lt: 0 }
        }
        if (this.filters.date[0]) {
          filter.createdAt = { $gt: this.filters.date[0], lt: this.filters.date[1] }
        }
        this.$store.getters.DSTransaction
        // var _transactions = []
        return store.findAll('Transaction',
          {
            // walletId: this.id,
            filter,
            options: {
              skip: (this.page - 1) * this.pageSize,
              limit: this.pageSize,
              sort: '-createdAt',
            },
          }, {
            force: true,
            raw: true,
          })
          .then(res => {
            this.total = +res.headers['x-model-count']
            return res.data
          })
      },
      default: [],
    },
  },
}
</script>
<style lang="sass">
.pi-credits
  .el-pagination
    text-align: center
  .header
    height: inherit !important
    .label
      font-size: 1.8em
  .overdraft
    .label
      font-size: 1.3em
    .el-input
      width: 60%
      &__icon
        height: 75%
        width: 25px
  .filters
    .el-col
      text-align: center
      .el-date-editor--datetimerange.el-input
        width: 175px
</style>
