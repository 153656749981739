import { get, isArray, isString, isUndefined, kebabcase, startsWith, toNumber, toSafeInteger } from 'lodash'

export default (localKeys, prefix = 'interface') => ({
  data: () => ({
    sessionStorageLoaded: false,
  }),
  props: {
    useLocalStorage: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    useSessionStorage: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
  watch: {
    computedSessionStorageDatas: {
      handler (value) {
        if (!this.hasStorageKey) {
          return
        }
        this.windowStorageMethod.setItem(this.getStorageKey, btoa(value))
        // this.windowStorageMethod.setItem(this.getStorageKey, value)
      },
      deep: true,
    },
  },
  methods: {
    postLoadSessionStorageDatasAssignation () {},
    loadSessionStorageDatas () {
      let obj = {}
      try {
        obj = JSON.parse(atob(this.windowStorageMethod.getItem(this.getStorageKey)))
        // obj = JSON.parse(this.windowStorageMethod.getItem(this.getStorageKey))
      } catch (error) {
      }
      return obj
    },
    assignLoadSessionStorageDataToComponent () {
      if (this.hasStorageKey) {
        const localDatas = this.loadSessionStorageDatas()
        this.localKeys.forEach(key => {
          if (localDatas && typeof localDatas[key] !== 'undefined') {
            let parent = this
            const allKeys = key.split('.')
            for (let index = 0; index < allKeys.length; index++) {
              const step = allKeys[index]
              const nextStep = allKeys[index + 1]
              if (index === 0 && isUndefined(parent[step])) {
                console.error('wrong session property:', key)
                break
              }
              if (toSafeInteger(nextStep) === toNumber(nextStep)) {
                if (!parent[step]) {
                  this.$set(parent, step, [])
                }
              } else if (isString(nextStep)) {
                if (!parent[step]) {
                  this.$set(parent, step, {})
                }
              } else {
                this.$set(parent, step, localDatas[key])
              }
              parent = parent[step]
            }
          } else {
            console.info('missing property:', key)
          }
        })
      }
      this.sessionStorageLoaded = true
      this.postLoadSessionStorageDatasAssignation()
    },
  },
  computed: {
    localKeys () {
      if (isArray(this.dataPathToStore)) {
        return [].concat([], this.dataPathToStore, localKeys)
      }
      return localKeys
    },
    storageKey () {
      if (this.useLocalStorage !== false) {
        return this.useLocalStorage
      }
      return this.useSessionStorage
    },
    windowStorageMethod () {
      if (this.useLocalStorage !== false) {
        return window.localStorage
      }
      return window.sessionStorage
    },
    computedSessionStorageDatas () {
      if (!this.hasStorageKey) {
        return
      }
      const obj = {}
      this.localKeys.forEach(key => {
        obj[key] = get(this, key)
      })
      return JSON.stringify(obj, null, 2)
    },
    getStorageKey () {
      if (this.storageKey === true || this.storageKey === '') {
        return [prefix, this.$options.name, window.location.pathname].map(kebabcase).join('.')
      } else if (isString(this.storageKey)) {
        return [prefix, this.storageKey].map(kebabcase).join('.')
      }
      return null
    },
    hasStorageKey () {
      return Boolean(this.getStorageKey)
    },

  },
  created () {
    this.assignLoadSessionStorageDataToComponent()
  },
})

export function deleteStorageDatasByKey (needle) {
  // suppression des données liées à l'interface
  const storages = [window.sessionStorage, window.localStorage]
  storages.forEach(storage => {
    Object.keys(storage)
      .filter(key => startsWith(key, needle))
      .forEach((key) => {
        storage.removeItem(key)
      })
  })
}
