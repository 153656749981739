import api from '@/api'
import { forOwn } from 'lodash'

const state = () => ({
  walletId: null,
})

// getters
const getters = {
}

// actions
const actions = {
  updateCompanyWallet ({ dispatch, commit, state, getters }) {
    if (getters.getCurrentCompanyId) {
      const company = api.get('Company', getters.getCurrentCompanyId)
      if (company) {
        return api.findAll('Wallet', { filter: { ownerId: company.id, ownerType: 'Company' } }, { force: true })
      }
    }
  },
  BOUGHT ({ dispatch, commit, state, getters }) {
    dispatch('updateCompanyWallet')
  },
  LESSONS_UPDATED ({ dispatch, commit, state, getters }) {
    dispatch('updateCompanyWallet')
  },
  updateWallet ({ dispatch, commit, state }) {
    if (!state.walletId) {
      return
    }
    api.findAll('Wallet', state.walletId, { force: true })
  },
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  CREDITFORM_SET_ID (state, id) {
    state.walletId = id
  },
  CREDITFORM_HIDE (state) {
    state.walletId = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
